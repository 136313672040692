var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CardTableRowItem } from "../card-table-row-item";
import { DisplaySizeService } from "../../../../services/display-size/display-size.service";
import { XdsApiService } from "../../../../services/xds-api/xds-api.service";
import { InformationService } from "../../../../services/information/information.service";
import { TranslateService } from "@ngx-translate/core";
import { PermissionService } from "../../../../services/permission/permission.service";
import { takeUntil } from "rxjs/operators";
import { XdsResourceView } from "../../../../classes/xds-models/xds-resource-view";
import { AuthService } from "../../../../services/auth/auth.service";
var CardTableRowInventoryItemComponent = /** @class */ (function (_super) {
    __extends(CardTableRowInventoryItemComponent, _super);
    function CardTableRowInventoryItemComponent(displaySize, api, infoService, translateService, permissionService, auth) {
        var _this = _super.call(this, displaySize, api, infoService, translateService, permissionService) || this;
        _this.permissionService = permissionService;
        _this.auth = auth;
        _this.limitedContentView = false;
        return _this;
    }
    CardTableRowInventoryItemComponent.prototype.loadData = function () {
        var _this = this;
        this.auth.network.subscribe(function (network) {
            _this.limitedContentView = network.limitedContentView;
        });
        this.api.PlayerInventories.getItem(this.playerInventory.xdsResource)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (item) {
            _this.item = new XdsResourceView(item, _this.permissionService);
        }, function (err) {
            console.warn('Unable to load item for InventoryItem: ' + _this.playerInventory.xdsResource.id, err);
            _this.infoService.error(err, _this.translateService.instant('ERROR.LOADINGITEMS'));
        });
    };
    return CardTableRowInventoryItemComponent;
}(CardTableRowItem));
export { CardTableRowInventoryItemComponent };
