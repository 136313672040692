import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Media} from '../../../classes/xds-models/media';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {DomainService} from '../../../services/domain/domain.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {InformationService} from '../../../services/information/information.service';
import {DateService} from '../../../services/date/date.service';
import {XdsApiUtilService} from '../../../services/xds-api/xds-api-util.service';
import {SelectionManager} from '../../../classes/selection-manager';
import {PermissionService} from '../../../services/permission/permission.service';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-media-to-media-groups-dialog',
  templateUrl: './add-media-to-media-groups-dialog.component.html',
  styleUrls: ['./add-media-to-media-groups-dialog.component.sass']
})
export class AddMediaToMediaGroupsDialogComponent implements OnInit, OnDestroy {
  public mediasToAdd: Observable<XdsResourceView<Media>[]>;
  public mediaGroups: XdsResourceView<MediaGroup>[] = [];
  public validFromDate: Date = null;
  public validToDate: Date = null;

  public mediaGroupSelectionManager = new SelectionManager<XdsResourceView<MediaGroup>>();
  public mediaItemSelectionManager = new SelectionManager<XdsResourceView<Media>>();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddMediaToMediaGroupsDialogComponent>,
    private api: XdsApiService,
    private domainService: DomainService,
    private permissionService: PermissionService,
    private infoService: InformationService,
    private dateService: DateService,
    private translateService: TranslateService,
  ) {
    this.mediasToAdd = data.items;
  }

  public ngOnInit() {
    // ToDo: use normal ngOnInit. This is fix is used to avoid flickering while dialog is opening. See permissions.service.ts (L:64)
    setTimeout(this.delayedNgOnInit.bind(this), 200);
  }

  public delayedNgOnInit() {
    this.mediaItemSelectionManager.selectUnselectAll(this.mediasToAdd);

    this.permissionService.getRWMediaGroups()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(
        mediaGroups => {
            const xdsMediaGroups: XdsResourceView<MediaGroup>[] = [];
            mediaGroups.forEach(group => xdsMediaGroups.push(new XdsResourceView<MediaGroup>(group, this.permissionService)));
          this.mediaGroups = xdsMediaGroups;
          },
        err => {
          this.infoService.error(err, this.translateService.instant('ERROR.LOADINGITEMS'));
        }
      );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {
    const validFrom = this.dateService.shortDate(this.validFromDate);
    const validTo = this.dateService.shortDate(this.validToDate);
    const playoutParams = XdsApiUtilService.createPlayoutParams(validFrom, validTo);

    combineLatest(
      this.mediaItemSelectionManager.getItems(),
      this.mediaGroupSelectionManager.getItems(),
      this.domainService.getCurrentDomain()
    ).pipe(
      take(1),
    ).subscribe(data => {
      const selectedMedias = data[0].map(o => o.xdsResource) as  Media[];
      const selectedMediaGroups = data[1].map(o => o.xdsResource) as MediaGroup[];
      const domains = [data[2]];

      this.api.Medias
        .addTo(selectedMedias, selectedMediaGroups, domains, playoutParams)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          () => () => {
          },
          err => {
            this.infoService.error(err, this.translateService.instant('ERROR.ADDINGITEMS'));
          },
          () => this.infoService.success(this.translateService.instant('DIALOG.ADDMEDIATOGROUP.SUCCESS'))
        );

      this.dialogRef.close({dataChanged: true});
    });
  }
}
