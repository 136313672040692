import {Component, Input} from '@angular/core';
import {CardTableRowItem} from "../card-table-row-item";
import {DisplaySizeService} from "../../../../services/display-size/display-size.service";
import {XdsApiService} from "../../../../services/xds-api/xds-api.service";
import {InformationService} from "../../../../services/information/information.service";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../../services/permission/permission.service";
import {Media} from "../../../../classes/xds-models/media";
import {takeUntil} from "rxjs/operators";
import {XdsResourceView} from "../../../../classes/xds-models/xds-resource-view";
import {PlayerInventory} from "../../../../classes/xds-models/player-inventory";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'app-card-table-row-inventory-item',
  templateUrl: './card-table-row-inventory-item.component.html',
  styleUrls: ['./card-table-row-inventory-item.component.sass']
})
export class CardTableRowInventoryItemComponent extends CardTableRowItem {
  @Input()
  public playerInventory: XdsResourceView<PlayerInventory>;

  public limitedContentView = false;

  constructor(
    displaySize: DisplaySizeService,
    api: XdsApiService,
    infoService: InformationService,
    translateService: TranslateService,
    protected permissionService: PermissionService,
    readonly auth: AuthService
  ) {
    super(displaySize, api, infoService, translateService, permissionService);
  }

  protected loadData(): void {
    this.auth.network.subscribe(network => {
        this.limitedContentView = network.limitedContentView;
      }
    );
    this.api.PlayerInventories.getItem<Media>(this.playerInventory.xdsResource)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        item => {
          this.item = new XdsResourceView(item, this.permissionService);
        },
        err => {
          console.warn('Unable to load item for InventoryItem: ' + this.playerInventory.xdsResource.id, err);
          this.infoService.error(err, this.translateService.instant('ERROR.LOADINGITEMS'));
        }
      );
  }

}
