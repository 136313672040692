/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-upload-progress.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./notification-upload-progress.component";
import * as i8 from "@angular/material/snack-bar";
var styles_NotificationUploadProgressComponent = [i0.styles];
var RenderType_NotificationUploadProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationUploadProgressComponent, data: {} });
export { RenderType_NotificationUploadProgressComponent as RenderType_NotificationUploadProgressComponent };
export function View_NotificationUploadProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { current: 0, total: 1 }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(6, 49152, null, 0, i5.MatProgressBar, [i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.data.progress)); var currVal_5 = "determinate"; _ck(_v, 6, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 4).transform("DIALOG.UPLOADMEDIA.PROGRESS", _ck(_v, 3, 0, i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.data.current)), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform(_co.data.total))))); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).value; var currVal_2 = i1.ɵnov(_v, 6).mode; var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_NotificationUploadProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-upload-progress", [], null, null, null, View_NotificationUploadProgressComponent_0, RenderType_NotificationUploadProgressComponent)), i1.ɵdid(1, 114688, null, 0, i7.NotificationUploadProgressComponent, [i8.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationUploadProgressComponentNgFactory = i1.ɵccf("app-notification-upload-progress", i7.NotificationUploadProgressComponent, View_NotificationUploadProgressComponent_Host_0, {}, {}, []);
export { NotificationUploadProgressComponentNgFactory as NotificationUploadProgressComponentNgFactory };
