<app-responsive-toolbar>
  <button mat-button
          class="button-with-close-badget"
          color="accent"
          [matMenuTriggerFor]="menu"
          [disabled]="selectionManager.count === 0 && !(displaySize.isHandset | async)"
          [matBadge]="selectionManager.count"
          [matBadgeHidden]="!selectionManager.count || !(displaySize.isHandset | async)"
          [matBadgePosition]="'after above'">
    {{ 'COMMON.ACTIONS' | translate}}
  </button>

  <button mat-button color="accent" (click)="openUploadMediaDialog()" *ngIf="!(displaySize.isHandset | async)">
    {{'MEDIA.UPLOAD' | translate}}
  </button>

  <span class="selection-counter mat-caption" *ngIf="!(displaySize.isHandset | async)">
    {{'COMMON.SELECTIONINFO' | translate: {
            selected: selectionManager.count,
            count: paginatorParams.items
          } }}
  </span>

  <mat-paginator #paginator [hidePageSize]="paginatorParams.hidePageSize"
                 [length]="paginatorParams.items"
                 [pageSize]="paginatorParams.pageSize"
                 [pageSizeOptions]="paginatorParams.allowedPageSizes"
                 [pageIndex]="paginatorParams.page"
                 (page)="goToPage($event.pageIndex, $event.pageSize)"
                 class="toolbar-pagination">
  </mat-paginator>
</app-responsive-toolbar>

<app-content>
  <app-page-error-viewer [errorComponent]="viewError"></app-page-error-viewer>

  <app-card-table *ngIf="loaded && items.length !== 0">
    <app-card-table-header>
      <app-card-table-row>
        <app-card-table-cell>
          <mat-checkbox name="all_top"
                        [checked]="selectionManager.isAllSelected(this.items)"
                        (change)="selectionManager.selectUnselectAll(this.items)"></mat-checkbox>
        </app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.PREVIEW' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.NAME' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.WRITEABLE' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DOMAIN' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DETAILS' | translate }}</app-card-table-cell>
      </app-card-table-row>
    </app-card-table-header>

    <app-card-table-body>
      <app-card-table-row-mediaitem *ngFor="let media of items"
                                    class="pointer"
                                    [media]="media"
                                    [selectionManager]="selectionManager"
                                    (click)="selectionManager.change(media)"
                                    [class.selected]="selectionManager.isSelected(media)">
      </app-card-table-row-mediaitem>
    </app-card-table-body>
  </app-card-table>
</app-content>

<mat-menu #menu="matMenu">

  <button mat-menu-item (click)="selectionManager.selectUnselectAll(this.items)"
          *ngIf="(displaySize.isHandset | async)">
    <mat-icon>select_all</mat-icon>
    {{(selectionManager.isAllSelected(items) ? 'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate}}
  </button>

  <mat-divider *ngIf="(displaySize.isHandset | async)"></mat-divider>

  <button mat-menu-item (click)="openUploadMediaDialog()"
          *ngIf="(displaySize.isHandset | async)">
    <mat-icon>cloud_upload</mat-icon>
    {{'MEDIA.UPLOAD' | translate}}
  </button>

  <mat-divider *ngIf="(displaySize.isHandset | async)"></mat-divider>

  <button mat-menu-item [disabled]="selectionManager.isNoneSelected()" (click)="openAddMediaDialog()">
    <mat-icon>add</mat-icon>
    {{ 'MEDIA.ADDTOMEDIAGROUP' | translate }}
  </button>

  <button mat-menu-item [disabled]="selectionManager.isNoneSelected()" (click)="openRemoveMediaDialog()">
    <mat-icon>remove</mat-icon>
    {{ 'MEDIA.REMOVEFROMMEDIAGROUP' | translate }}
  </button>

  <mat-divider></mat-divider>

  <button mat-menu-item [disabled]="selectionManager.isNoneSelected()" (click)="openDeleteMediaDialog()">
    <mat-icon>delete</mat-icon>
    {{ 'MEDIA.DELETEMEDIA' | translate }}
  </button>

</mat-menu>
