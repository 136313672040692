/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-table-body.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-table-body.component";
var styles_CardTableBodyComponent = [i0.styles];
var RenderType_CardTableBodyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardTableBodyComponent, data: {} });
export { RenderType_CardTableBodyComponent as RenderType_CardTableBodyComponent };
export function View_CardTableBodyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_CardTableBodyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table-body", [], null, null, null, View_CardTableBodyComponent_0, RenderType_CardTableBodyComponent)), i1.ɵdid(1, 114688, null, 0, i2.CardTableBodyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardTableBodyComponentNgFactory = i1.ɵccf("app-card-table-body", i2.CardTableBodyComponent, View_CardTableBodyComponent_Host_0, {}, {}, ["*"]);
export { CardTableBodyComponentNgFactory as CardTableBodyComponentNgFactory };
