import {MEDIA_TYPES} from '../../const/media-types.enum';
import {DateUtil} from "../date-util";
import {Moment} from "moment";

export class InventoryItem  {

  private readonly _validFrom: Moment | null;
  private readonly _validTo: Moment | null;

  constructor(
    private _itemId: number,
    private _itemName: string,
    private _mediaType: MEDIA_TYPES,
    validFrom: string | Date | null,
    validTo: string | Date | null,
  ) {
    this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
    this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
  }

  get itemId(): number {
    return this._itemId;
  }

  get itemName(): string {
    return this._itemName;
  }

  get mediaType(): MEDIA_TYPES {
    return this._mediaType;
  }

  get validFrom(): Moment | null {
    return this._validFrom;
  }

  get validTo(): Moment | null {
    return this._validTo;
  }
}
