import {Injectable} from '@angular/core';
import {DomainService} from '../domain/domain.service';
import {MediaGroup} from '../../classes/xds-models/media-group';
import {ContainerItem} from '../../classes/xds-models/container-item';
import {Media} from '../../classes/xds-models/media';
import {DOMAIN_PERMISSIONS} from '../../const/permissions.enum';
import {map, switchMap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {XdsApiService} from '../xds-api/xds-api.service';
import {Domain} from '../../classes/xds-models/domain';
import 'rxjs-compat/add/observable/from';
import {PlayerInventory} from "../../classes/xds-models/player-inventory";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private api: XdsApiService,
    private domainService: DomainService
  ) {
  }

  public getRWMediaGroups(): Observable<MediaGroup[]> {
    return this.domainService.getCurrentDomain()
      .pipe(
        switchMap(currentDomain => this.api.MediaGroups.getAllForDomain(currentDomain)),
        switchMap(mediaGroups => this.filterItemsByPermission<MediaGroup>(mediaGroups, [DOMAIN_PERMISSIONS.READ_WRITE]))
      );
  }

  public isWritable(item: Media | MediaGroup | ContainerItem | PlayerInventory): Observable<boolean> {
    return this.getPermissionForItem(item)
      .pipe(map(permission => permission === DOMAIN_PERMISSIONS.READ_WRITE));
  }

  public isDeletable(item: Media | MediaGroup | ContainerItem): Observable<boolean> {
    return this.isWritable(item);
  }

  private getPermissionForItem(item: Media | MediaGroup | ContainerItem | PlayerInventory): Observable<DOMAIN_PERMISSIONS> {
    if (item instanceof Media) {
      return this.getPermissionForMedia(item);
    } else if (item instanceof MediaGroup) {
      return this.getPermissionForMediaGroup(item);
    } else if (item instanceof ContainerItem) {
      return this.getPermissionForContainerItem(item);
    } else if (item instanceof PlayerInventory) {
      return of(DOMAIN_PERMISSIONS.READ_WRITE);
    }
  }

  private getPermissionForMedia(item: Media): Observable<DOMAIN_PERMISSIONS> {
    return this.evalPermissionForItem(item, (itemDomain, userDomain) => {
      const itemDomainIsSameOrLower: boolean = this.itemDomainIsSameOrLower(itemDomain, userDomain);
      const domainsAreInSameLine = this.domainsAreInSameLine(itemDomain, userDomain);

      if (itemDomainIsSameOrLower) {
        return DOMAIN_PERMISSIONS.READ_WRITE;
      } else if (domainsAreInSameLine || !itemDomain) {
        return DOMAIN_PERMISSIONS.READ;
      } else {
        return DOMAIN_PERMISSIONS.NONE;
      }
    });
  }

  private getPermissionForMediaGroup(item: MediaGroup): Observable<DOMAIN_PERMISSIONS> {
    return this.evalPermissionForItem(item, (itemDomain, userDomain) => {
      const domainsAreInSameLine: boolean = this.domainsAreInSameLine(itemDomain, userDomain);

      if (!itemDomain) {
        return DOMAIN_PERMISSIONS.READ;
      } else if (domainsAreInSameLine) {
        return DOMAIN_PERMISSIONS.READ_WRITE;
      } else {
        return DOMAIN_PERMISSIONS.NONE;
      }
    });
  }

  private getPermissionForContainerItem(item: ContainerItem): Observable<DOMAIN_PERMISSIONS> {
    return this.evalPermissionForItem(item, (itemDomain, userDomain) => {
      const itemDomainIsSameOrLower: boolean = this.itemDomainIsSameOrLower(itemDomain, userDomain);
      const domainsAreInSameLine: boolean = this.domainsAreInSameLine(itemDomain, userDomain);

      if (itemDomainIsSameOrLower) {
        return DOMAIN_PERMISSIONS.READ_WRITE;
      } else if (domainsAreInSameLine || !itemDomain) {
        return DOMAIN_PERMISSIONS.READ;
      } else {
        return DOMAIN_PERMISSIONS.NONE;
      }
    });
  }

  private getDomainNamesForItemAndCurrentDomainName(item: Media | MediaGroup | ContainerItem): Observable<[string[], string]> {
    return combineLatest(this.getDomainNamesForItem(item), this.domainService.getCurrentDomainName());
  }

  private evalPermissionForItem(
    item: Media | MediaGroup | ContainerItem,
    evalPermissionFn: (itemDomain: string, userDomain: string) => DOMAIN_PERMISSIONS
  ): Observable<DOMAIN_PERMISSIONS> {
    return this.getDomainNamesForItemAndCurrentDomainName(item)
      .pipe(map(domains => {
        const itemDomains: string[] = domains[0];
        const userDomain: string = domains[1];
        const permissions: DOMAIN_PERMISSIONS[] = [];

        itemDomains.forEach(itemDomain => {
          const permission: DOMAIN_PERMISSIONS = evalPermissionFn(itemDomain, userDomain);
          permissions.push(permission);
        });

        if (permissions.indexOf(DOMAIN_PERMISSIONS.READ) > -1) {
          return DOMAIN_PERMISSIONS.READ;
        } else if (permissions.indexOf(DOMAIN_PERMISSIONS.READ_WRITE) > -1) {
          return DOMAIN_PERMISSIONS.READ_WRITE;
        } else {
          return DOMAIN_PERMISSIONS.NONE;
        }
      }));
  }

  // todo for many Ts this is a huge performance issue
  private getPermissionForItems(
    items: (Media | MediaGroup | ContainerItem)[]
  ): Observable<[Media | MediaGroup | ContainerItem, DOMAIN_PERMISSIONS][]> {
    const collected: Observable<[Media | MediaGroup | ContainerItem, DOMAIN_PERMISSIONS]>[] = [];

    items.forEach(item => {
      const permission$: Observable<DOMAIN_PERMISSIONS> = this.getPermissionForItem(item);
      const item$: Observable<Media | MediaGroup | ContainerItem> = Observable.from([item]);
      const itemAndPermission$ = combineLatest(item$, permission$);
      collected.push(itemAndPermission$);
    });

    return combineLatest(collected);
  }

  private getDomainNamesForItem(item: Media | MediaGroup | ContainerItem): Observable<string[]> {
    if (item instanceof Media || item instanceof MediaGroup) {
      return this.api.Domains.getDomainsForItem(item)
        .pipe(map(domains => {
          const domainNames: string[] = [];

          domains.forEach(domain => domainNames.push(domain.fullyQualifiedName));

          return domainNames;
        }));
    } else {
      // else: item instance of ContainerItem
      return Observable.from([[item.fullyQualifiedDomainName]]);
    }
  }

  private filterItemsByPermission<T extends Media | MediaGroup | ContainerItem>(
    items: T[], targetPermissions: DOMAIN_PERMISSIONS[]
  ): Observable<T[]> {
    return this.getPermissionForItems(items)
      .pipe(
        map(itemAndPermissionList => {
          const filteredList: T[] = [];

          itemAndPermissionList.forEach(itemAndPermission => {
            const item = itemAndPermission[0];
            const perm = itemAndPermission[1];

            if (targetPermissions.indexOf(perm) > -1) {
              filteredList.push(item as T);
            }
          });

          return filteredList;
        })
      );
  }

  private domainsAreInSameLine(fullItemDomainName: string, fullUserDomainName: string): boolean {
    return fullItemDomainName.indexOf(fullUserDomainName) === 0 || fullUserDomainName.indexOf(fullItemDomainName) === 0;
  }

  private itemDomainIsSameOrLower(fullItemDomainName: string, fullUserDomainName: string): boolean {
    return fullItemDomainName.indexOf(fullUserDomainName) === 0;
  }

  private getItemDomainName(itemDomain: Domain | ContainerItem): string {
    if (itemDomain instanceof ContainerItem) {
      return itemDomain.fullyQualifiedDomainName;
    } else {
      return itemDomain.fullyQualifiedName;
    }
  }
}
