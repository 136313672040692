var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResourceApiService } from '../resource/xds-resource-api.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { concatAll, map, switchMap, toArray } from 'rxjs/operators';
import { XdsApiModelMapperService } from '../xds-api-model-mapper.service';
import { Player } from '../../../classes/xds-models/player';
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
var XdsDomainsApiService = /** @class */ (function (_super) {
    __extends(XdsDomainsApiService, _super);
    function XdsDomainsApiService(mapper, http) {
        return _super.call(this, http, mapper, XdsDomainsApiService.configOptions) || this;
    }
    XdsDomainsApiService.prototype.getChildren = function (domain) {
        var _this = this;
        return this.http.request('GET', domain.links.children.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapResource(x); }); }));
    };
    XdsDomainsApiService.prototype.getChildrenOnlyDomains = function (domain) {
        var _this = this;
        return this.http.request('GET', domain.links.children.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapResource(x); }, function (r, resKey) {
            return resKey === 'domains';
        }); }));
    };
    XdsDomainsApiService.prototype.getChildrenOnlyPlayers = function (domain) {
        var _this = this;
        return this.http.request('GET', domain.links.children.href).pipe(switchMap(function (res) { return of(_this.mapResourceList(res, function (x) { return _this.mapResource(x); }, function (r, resKey) {
            return resKey === 'domains' || resKey === 'players';
        })); }), switchMap(function (children) {
            var observables = [];
            children.forEach(function (child) {
                if (child.hasChildren()) {
                    observables.push(_this.getChildrenOnlyPlayers(child));
                }
                else if (child instanceof Player) {
                    observables.push(of([child]));
                }
            });
            return observables;
        }), concatAll(), switchMap(function (players) {
            return [].concat.apply([], players); // flatten array of arrays
        }), toArray());
    };
    XdsDomainsApiService.prototype.getDomainForItem = function (item) {
        var _this = this;
        return this.http.request('GET', item.links.domain.href).pipe(map(function (res) { return _this.mapResource(res); }));
    };
    XdsDomainsApiService.prototype.getDomainsForItem = function (item) {
        var _this = this;
        return this.http.request('GET', item.links.domains.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapResource(x); }); }));
    };
    XdsDomainsApiService.prototype.mapResource = function (res) {
        return this.mapper.mapDomain(res);
    };
    XdsDomainsApiService.configOptions = {
        endpoint: 'domains'
    };
    XdsDomainsApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsDomainsApiService_Factory() { return new XdsDomainsApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient)); }, token: XdsDomainsApiService, providedIn: "root" });
    return XdsDomainsApiService;
}(XdsResourceApiService));
export { XdsDomainsApiService };
