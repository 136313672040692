export const PAGINATOR_PARAMS: MatPaginatorConst = {
  hidePageSize: true,
  allowedPageSizes: [5, 10, 20, 50],
  page: 0,
  pages: 0,
  pageSize: 20,
  items: 0
};

interface MatPaginatorConst {
  hidePageSize: boolean;
  allowedPageSizes: number[];
  page: number;
  pages: number;
  pageSize: number;
  items: number;
}
