import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Media} from '../../../classes/xds-models/media';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {Observable, Subject, combineLatest} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {SelectionManager} from '../../../classes/selection-manager';
import {InformationService} from '../../../services/information/information.service';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-media-delete-dialog',
  templateUrl: './media-delete-dialog.component.html',
  styleUrls: ['./media-delete-dialog.component.sass']
})
export class MediaDeleteDialogComponent implements OnInit, OnDestroy {
  public mediasToRemove: Observable<XdsResourceView<Media>[]>;
  public mediaItemSelectionManager = new SelectionManager<XdsResourceView<Media>>();
  public containsUsed = false;
  public containsBlocked = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private itemsInUseCount = -1;
  private canWriteItems = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MediaDeleteDialogComponent>,
    private api: XdsApiService,
    private infoService: InformationService,
    private translateService: TranslateService,
  ) {
    this.mediasToRemove = data.items;
  }

  public ngOnInit() {
    this.mediasToRemove.subscribe(medias => {
      this.containsBlocked = medias.filter(item => !item.writeable()).length > 0;
      this.containsUsed = medias.filter(item => item.xdsResource.usedInContainerItemsCount > 0).length > 0;
    });
    this.mediaItemSelectionManager.select(this.mediasToRemove);

    this.mediaItemSelectionManager.getItems()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(selectedMedias => {
        this.itemsInUseCount = selectedMedias.filter(item => item.xdsResource.usedInContainerItemsCount > 0).length;

        this.checkIfOnSelectedIsNotWritable(selectedMedias)
          .pipe(take(1))
          .subscribe(onIsBad => this.canWriteItems = !onIsBad);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {
    this.mediaItemSelectionManager.getItems()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(medias => {
        this.api.Medias.deleteMany(medias.map(o => o.xdsResource))
          .subscribe(
            () => {
              this.infoService.info(this.translateService.instant('DIALOG.DELETEMEDIA.SUCCESS'));
            },
            err => {
              this.infoService.error(err, this.translateService.instant('ERROR.DELETINGITEMS'));
            }
          );
        this.dialogRef.close({dataChanged: true});
      });
  }

  public isAlldeletable(): boolean {
    return this.mediaItemSelectionManager.isAnySelected() && this.canWriteItems && this.itemsInUseCount === 0;
  }

  private checkIfOnSelectedIsNotWritable(medias: XdsResourceView<Media>[]): Observable<boolean> {
    const writables: Observable<boolean>[] = [];

    medias.forEach(media => writables.push(media.isWritable()));

    return combineLatest(writables)
      .pipe(map(areWritables => {
        let onIsBad = false;

        for (let i = 0; i < areWritables.length; i++) {
          if (!areWritables[i]) {
            onIsBad = true;
            break;
          }
        }

        return onIsBad;
      }));
  }
}
