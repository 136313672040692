<div class="spacer"></div>

<div class="page-links">
  <a class="pointer" target="_blank"
     [href]="'../../../assets/themes/' + userManualVariant + '/' + userManualName_EN"
     *ngIf="userManualName_EN">
    <mat-card>
      <mat-card-content>
        <mat-icon>library_books</mat-icon>
        <div>
          {{'COMMON.MANUAL' | translate }} (English, PDF)
        </div>
      </mat-card-content>
    </mat-card>
  </a>
  <a class="pointer" target="_blank"
     [href]="'../../../assets/themes/' + userManualVariant + '/' + userManualName_DE"
     *ngIf="userManualName_DE">
    <mat-card>
      <mat-card-content>
        <mat-icon>library_books</mat-icon>
        <div>
          {{'COMMON.MANUAL' | translate }} (Deutsch, PDF)
        </div>
      </mat-card-content>
    </mat-card>
  </a>
</div>
