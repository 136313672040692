import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Media} from '../../../classes/xds-models/media';
import {SelectionManager} from '../../../classes/selection-manager';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {DateUtil} from '../../../classes/date-util';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Domain} from '../../../classes/xds-models/domain';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-card-table-row-mediaitem',
  templateUrl: './card-table-row-mediaitem.component.html',
  styleUrls: ['./card-table-row-mediaitem.component.sass']
})
export class CardTableRowMediaitemComponent implements OnInit, OnDestroy {
  @Input()
  public media: XdsResourceView<Media>;
  @Input()
  public selectionManager: SelectionManager<XdsResourceView<Media>>;

  public dateUtil = DateUtil;

  private domainList$: BehaviorSubject<Domain[]> = new BehaviorSubject([]);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public displaySize: DisplaySizeService,
    public api: XdsApiService,
  ) {
  }


  public ngOnInit() {
    this.api.Domains.getDomainsForItem(this.media.xdsResource)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.domainList$.next(data);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }

  public get mediaDomains(): Observable<Domain[]> {
    return this.domainList$.asObservable();
    // .pipe(map(domains => {
    //   return domains.map(domain => {
    //     return domain.name;
    //   });
    // }));
  }
}
