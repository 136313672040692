import {OnDestroy, OnInit} from '@angular/core';
import {DisplaySizeService} from '../services/display-size/display-size.service';
import {XdsApiService} from '../services/xds-api/xds-api.service';
import {TitleBarService} from '../services/title-bar/title-bar.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DomainService} from '../services/domain/domain.service';
import {InformationService} from '../services/information/information.service';
import {PAGINATOR_PARAMS} from '../const/mat-paginator.const';
import {SelectionManager} from './selection-manager';
import {combineLatest, Subject} from 'rxjs';
import {PagedSearchParams} from '../interfaces/search/paged-search-params';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {XdsResourcePageConfig} from '../interfaces/xds-resource-page-config';
import {XdsResourceView} from './xds-models/xds-resource-view';
import {PermissionService} from '../services/permission/permission.service';
import {Media} from './xds-models/media';
import {MediaGroup} from './xds-models/media-group';
import {ContainerItem} from './xds-models/container-item';
import {PageErrorUtil} from './page-error/page-error-util';
import {ComponentType} from '@angular/cdk/portal';
import {PageErrorComponent} from './page-error/page-error-component';
import {TranslateService} from '@ngx-translate/core';
import {PageErrorEmptyListComponent} from '../components/shared/errors/page-errors/page-error-empty-list/page-error-empty-list.component';
import {BroadcastService, BroadcastTypes} from '../services/broadcast/broadcast.service';
import {SearchParamsData} from '../interfaces/search-params-data';
import {PlayerInventory} from "./xds-models/player-inventory";

export abstract class XdsResourcePage<R extends MediaGroup | ContainerItem | Media | PlayerInventory, S extends PagedSearchParams>
  implements OnInit, OnDestroy {

  public items: XdsResourceView<R>[] = [];
  public paginatorParams = Object.assign({}, PAGINATOR_PARAMS);
  public selectionManager = new SelectionManager<XdsResourceView<R>>();
  public loaded = false;
  public viewError: ComponentType<PageErrorComponent> = null;

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  private reloadPage$: Subject<boolean> = new Subject();

  protected constructor(
    public displaySize: DisplaySizeService,
    protected api: XdsApiService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected domainService: DomainService,
    protected infoService: InformationService,
    protected titleBarService: TitleBarService,
    protected permissionService: PermissionService,
    protected translateService: TranslateService,
    protected broadcaster: BroadcastService,
    protected config: XdsResourcePageConfig<R, S>,
  ) {
  }

  public ngOnInit(): void {
    this.setTitle();
    this.loadItems();
    this.reloadPage();

    // reload content on emit
    this.broadcaster
      .listen(BroadcastTypes.RELOAD)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        message => this.reloadPage()
      );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.reloadPage$.unsubscribe();
  }

  public reloadPage(flushSelection: boolean = false): void {
    // XDS-1835: removing items with batch requests causes some issues - on reload some items are still in list, but not longer available
    setTimeout(() => {
      if (flushSelection) {
        this.selectionManager.flush();
      }

      this.reloadPage$.next(true);
    }, 400);
  }

  public goToPage(page: number, size: number = 20, extraParams?: Params): Promise<boolean> {
    const queryParams = {page: page, size: size};
    return this.router.navigate([], {queryParams: queryParams, ...extraParams, queryParamsHandling: 'merge'});
  }

  protected setTitle(): void {
    this.titleBarService.setTitle(this.config.title);
  }

  protected abstract createSearchParams(data: SearchParamsData): S;

  protected loadItems(): void {
    combineLatest(
      this.domainService.getCurrentDomain(),
      this.route.queryParamMap,
      this.reloadPage$.asObservable(),
      this.route.params,
    )
      .pipe(
        takeUntil(this.destroy$),
        map(data => this.createSearchParams(data)),
        switchMap(searchParams => this.config.mainApi.search(searchParams)),
      )
      .subscribe(
        pagedItems => {
          const newItems = [];

          // XDS-1835 - selection only for current page
          this.selectionManager.flush();

          this.paginatorParams.page = pagedItems.page.number;
          this.paginatorParams.pages = pagedItems.page.totalPages;
          this.paginatorParams.items = pagedItems.page.totalElements;
          this.paginatorParams.pageSize = pagedItems.page.size;

          this.loaded = true;

          pagedItems.items.forEach(
            item => newItems.push(new XdsResourceView<R>(item, this.permissionService))
          );

          this.items = newItems;
          this.selectionManager.updateItems(this.items);
          this.viewError = null;

          if (this.items.length === 0) {
            this.infoService.warn(this.translateService.instant('ERROR.EMPTYLIST.TITLE'));
            this.viewError = PageErrorEmptyListComponent;
          }
        },
        err => {
          this.loaded = false;
          this.infoService.error(err, this.translateService.instant('ERROR.SWW.TITLE'));
          this.viewError = PageErrorUtil.parse(err);
        });
  }
}
