<app-card-table-cell>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-thumbnail" [ngClass]="{'pointer': !limitedContentView}">
  <img [alt]="item.xdsResource.name"
       *ngIf="item && item.isMedia()"
       appRetryImage [appRetryImageSrc]="item.thumbnailDownload()"
       appImagePreview [appImagePreviewBlocked]="limitedContentView" [data]="item.xdsResource"/>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-highlighted">
  <div class="name" *ngIf="item">
    <mat-icon>{{item.iconName}}</mat-icon>
    {{item.xdsResource.name}}
  </div>
</app-card-table-cell>

<app-card-table-cell>
  <table>
    <tr>
      <td>{{'TIMESPAN.FROM' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(playerInventory.xdsResource.inventoryItem.validFrom)}}
          <div class="validity-info" *ngIf="dateUtil.isInFuture(playerInventory.xdsResource.inventoryItem.validFrom)">
            <mat-icon>warning</mat-icon>
            {{'COMMON.VALID_EXPIRED' | translate}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{'TIMESPAN.UNTIL' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(playerInventory.xdsResource.inventoryItem.validTo)}}
          <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}"
                    *ngIf="dateUtil.isInPast(playerInventory.xdsResource.inventoryItem.validTo)">warning
          </mat-icon>
        </div>
      </td>
    </tr>
  </table>
  <div class="progress">
    <mat-progress-bar mode="determinate" [value]="playerInventory.xdsResource.percentDownloaded"></mat-progress-bar>
    ({{playerInventory.xdsResource.percentDownloaded}}% {{'PLAYER.ITEMDOWNLOADED' | translate}})
  </div>
</app-card-table-cell>
