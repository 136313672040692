/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-permissions.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/page-message/page-message.component.ngfactory";
import * as i7 from "../../shared/page-message/page-message.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./no-permissions.component";
import * as i14 from "../../../services/display-size/display-size.service";
import * as i15 from "../../../services/auth/auth.service";
import * as i16 from "../../../services/title-bar/title-bar.service";
var styles_NoPermissionsComponent = [i0.styles];
var RenderType_NoPermissionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoPermissionsComponent, data: {} });
export { RenderType_NoPermissionsComponent as RenderType_NoPermissionsComponent };
export function View_NoPermissionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-toolbar", [["class", "main-toolbar mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, [["toolbar", 4]], 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "span", [], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 15, "app-page-message", [], null, null, null, i6.View_PageMessageComponent_0, i6.RenderType_PageMessageComponent)), i1.ɵdid(8, 114688, null, 0, i7.PageMessageComponent, [], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 0, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(17, { email: 0 }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, 0, 3, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.authService.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i10.MatButton, [i1.ElementRef, i4.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(21, 0, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, "title", (!_co.titleVisibility.value ? "hidden" : "")); _ck(_v, 4, 0, currVal_3); var currVal_5 = "security"; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.title; _ck(_v, 6, 0, currVal_4); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("ERROR.SWW.TITLE")); _ck(_v, 10, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("NOPERMISSION.INFOROW1")); _ck(_v, 13, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 18).transform("NOPERMISSION.INFOROW2", _ck(_v, 17, 0, "<a href='mailto:account@xplace-group.com'>account@xplace-group.com</a>"))); _ck(_v, 16, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 20).disabled || null); var currVal_10 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("COMMON.LOGOUT")); _ck(_v, 21, 0, currVal_11); }); }
export function View_NoPermissionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-permissions", [], null, null, null, View_NoPermissionsComponent_0, RenderType_NoPermissionsComponent)), i1.ɵdid(1, 114688, null, 0, i13.NoPermissionsComponent, [i14.DisplaySizeService, i15.AuthService, i16.TitleBarService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoPermissionsComponentNgFactory = i1.ɵccf("app-no-permissions", i13.NoPermissionsComponent, View_NoPermissionsComponent_Host_0, {}, {}, []);
export { NoPermissionsComponentNgFactory as NoPermissionsComponentNgFactory };
