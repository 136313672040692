/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-table.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-table.component";
var styles_CardTableComponent = [i0.styles];
var RenderType_CardTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardTableComponent, data: {} });
export { RenderType_CardTableComponent as RenderType_CardTableComponent };
export function View_CardTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), i1.ɵncd(null, 1), i1.ɵncd(null, 2)], null, null); }
export function View_CardTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, null, null, View_CardTableComponent_0, RenderType_CardTableComponent)), i1.ɵdid(1, 114688, null, 0, i2.CardTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardTableComponentNgFactory = i1.ɵccf("app-card-table", i2.CardTableComponent, View_CardTableComponent_Host_0, {}, {}, ["app-card-table-header", "app-card-table-body", "app-card-table-footer"]);
export { CardTableComponentNgFactory as CardTableComponentNgFactory };
