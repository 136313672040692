import {Injectable} from '@angular/core';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {HttpClient} from '@angular/common/http';
import {XdsResourceApiService} from '../resource/xds-resource-api.service';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {ContainerItemsSearch} from '../../../interfaces/search/container-items.search';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {Media} from '../../../classes/xds-models/media';
import {Container} from '../../../classes/xds-models/container';
import {XdsApiUtilService} from '../xds-api-util.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Domain} from '../../../classes/xds-models/domain';
import {MediaGroup} from '../../../classes/xds-models/media-group';

@Injectable({
  providedIn: 'root'
})
export class XdsContainerItemsApiService extends XdsResourceApiService<ContainerItem, ContainerItemsSearch> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'containerItems',
    search: {
      endpoint: 'containerItems/search/findByContainerAndItemNameForDomain'
    }
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient,
  ) {
    super(http, mapper, XdsContainerItemsApiService.configOptions);
  }

  public getForItemsAndContainersAndDomain(items: Media[], containers: Container[], domain: Domain): Observable<ContainerItem[]> {
    const itemIds: number[] = [];
    const containerIds: number[] = [];

    items.forEach(item => itemIds.push(item.id));
    containers.forEach(container => containerIds.push(container.id));

    const itemIdsStr = itemIds.join(',');
    const containerIdsStr = containerIds.join(',');

    const searchParams = {
      itemIds: itemIdsStr,
      containerIds: containerIdsStr,
      domainId: domain.id
    };
    const endpoint = 'containerItems/search/findByItemIdInAndContainerIdInAndDomainId';
    const queryString = XdsApiUtilService.buildQueryString(searchParams);

    return this.http.request<any>(
      'GET',
      XdsApiUtilService.createApiUrl(endpoint + queryString)
    ).pipe(map(res => this.mapResourceList<ContainerItem>(res, x => this.mapResource(x))));
  }

  public getDomainsForContainerItem(containerItem: ContainerItem): Observable<Domain[]> {
    return this.http.request(
      'GET',
      containerItem.links.domains.href
    ).pipe(
      map(res => this.mapResourceList<Domain>(res, x => this.mapper.mapDomain(x)))
    );
  }

  public getItem<T extends (Media | MediaGroup)>(containerItem: ContainerItem): Observable<T> {
    return this.http.request<any>(
      'GET',
      containerItem.links.item.href
    )
      .pipe(
        map(res => this.mapper.mapContainerItemItem<T>(res))
      );
  }

  public deleteMany(containerItems: ContainerItem[]): Observable<any> {
    const payload = {
      type: 'delete',
      containerItems: XdsApiUtilService.resourceListToStrIdList(containerItems)
    };

    return this.batch('delete', payload);
  }

  protected mapResource(res: any): ContainerItem {
    return this.mapper.mapContainerItem(res);
  }
}
