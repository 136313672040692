import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsResource} from './xds-resource';
import {InventoryItem} from "./inventory-item";

export class PlayerInventory extends XdsResource {

  constructor(
    private _percentDownloaded: number,
    private _missing: boolean,
    private _inventoryItem: InventoryItem,
    links: ResourceLinks,
  ) {
    super(links);
  }

  get percentDownloaded(): number {
    return this._percentDownloaded;
  }

  get missing(): boolean {
    return this._missing;
  }

  get inventoryItem(): InventoryItem {
    return this._inventoryItem;
  }
}
