import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastService} from '../../../services/broadcast/broadcast.service';
import {DomainService} from '../../../services/domain/domain.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-notification-upload-complete-open',
  templateUrl: './notification-upload-complete-open.component.html',
  styleUrls: ['./notification-upload-complete-open.component.sass']
})
export class NotificationUploadCompleteOpenComponent implements OnInit {
  public component: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private broadcaster: BroadcastService,
    private domainService: DomainService
  ) {
  }

  public ngOnInit() {
  }

  public dismiss() {
    this.component.dismiss();
  }

  public open() {

    this.domainService.getCurrentDomain()
      .pipe(take(1))
      .subscribe(currentDomain => {
        this.router.navigate(['bcn', currentDomain.id, 'medias']);
      });

    this.dismiss();
  }

}
