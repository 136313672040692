import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {XdsMediaGroupsApiService} from './media-groups/xds-media-groups-api.service';
import {XdsContainerItemsApiService} from './container-items/xds-container-items-api.service';
import {XdsMediasApiService} from './medias/xds-medias-api.service';
import {XdsPlayersApiService} from './players/xds-players-api.service';
import {XdsDomainsApiService} from './domains/xds-domains-api.service';
import {Observable} from 'rxjs';
import {XdsApiUtilService} from './xds-api-util.service';
import {IXdsVersions} from '../../interfaces/xds-models/xds-versions';
import {XdsPlayerInventoriesApiService} from "./player-inventories/xds-player-inventories-api.service";

/**
 * returns a bunch of resource apis
 */

@Injectable({
  providedIn: 'root'
})
export class XdsApiService {

  constructor(
    private http: HttpClient,
    private mediasApi: XdsMediasApiService,
    private mediaGroupsApi: XdsMediaGroupsApiService,
    private playersApi: XdsPlayersApiService,
    private playerInventoriesApi: XdsPlayerInventoriesApiService,
    private domainApi: XdsDomainsApiService,
    private containerItemsApi: XdsContainerItemsApiService,
  ) {
  }

  /**
   * return media api
   */
  public get Medias(): XdsMediasApiService {
    return this.mediasApi;
  }

  /**
   * return media group api
   */
  public get MediaGroups(): XdsMediaGroupsApiService {
    return this.mediaGroupsApi;
  }

  /**
   * return container item api
   */
  public get ContainerItems(): XdsContainerItemsApiService {
    return this.containerItemsApi;
  }

  /**
   * return domain api
   */
  public get Domains(): XdsDomainsApiService {
    return this.domainApi;
  }

  /**
   * return player api
   */
  public get Players(): XdsPlayersApiService {
    return this.playersApi;
  }

  /**
   * return player inventories api
   */
  public get PlayerInventories(): XdsPlayerInventoriesApiService {
    return this.playerInventoriesApi;
  }

  /**
   * return backend version
   */
  public getVersion(): Observable<IXdsVersions> {
    return this.http.request<IXdsVersions>(
      'GET',
      XdsApiUtilService.createApiUrl('versions')
    );
  }
}
