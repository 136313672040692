var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Media } from './media';
import { MEDIA_TYPES } from '../../const/media-types.enum';
import { MEDIA } from '../../const/media.const';
var MediaMessage = /** @class */ (function (_super) {
    __extends(MediaMessage, _super);
    function MediaMessage(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount, network, links) {
        return _super.call(this, name, validFrom, validTo, MEDIA_TYPES.Message, hashId, currentRevision, mimeType, usedInContainerItemsCount, network, links) || this;
    }
    Object.defineProperty(MediaMessage.prototype, "iconName", {
        get: function () {
            return 'mail';
        },
        enumerable: true,
        configurable: true
    });
    MediaMessage.prototype.previewDownload = function () {
        var _this = this;
        return [
            this.createMediaItemSource(function (mimeType, size) { return _this.mediaDownloadUrl(mimeType, size); }, MEDIA.mimeTypes.png, MEDIA.sizes.large_preview)
        ];
    };
    return MediaMessage;
}(Media));
export { MediaMessage };
