var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsApiModelMapperService } from "../xds-api-model-mapper.service";
import { HttpClient } from "@angular/common/http";
import { XdsResourceApiService } from "../resource/xds-resource-api.service";
import { of, throwError } from "rxjs";
import { XdsApiUtilService } from "../xds-api-util.service";
import { catchError, map } from "rxjs/operators";
import { MEDIA_TYPES } from "../../../const/media-types.enum";
import { AuthService } from "../../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../auth/auth.service";
var XdsPlayerInventoriesApiService = /** @class */ (function (_super) {
    __extends(XdsPlayerInventoriesApiService, _super);
    function XdsPlayerInventoriesApiService(mapper, http, auth) {
        var _this = _super.call(this, http, mapper, XdsPlayerInventoriesApiService.configOptions) || this;
        _this.auth = auth;
        return _this;
    }
    XdsPlayerInventoriesApiService.prototype.getItem = function (playerInventory) {
        var _this = this;
        return this.http.request('GET', XdsApiUtilService.createApiUrl('medias/' + playerInventory.inventoryItem.itemId)).pipe(catchError(function (err, caught) {
            console.log('Could not load media with id ' + playerInventory.inventoryItem.itemId + ', deleted?', err);
            if (err.status === 404) {
                return of(_this.mapper.mapMedia({
                    name: playerInventory.inventoryItem.itemName,
                    validFrom: playerInventory.inventoryItem.validFrom,
                    validTo: playerInventory.inventoryItem.validTo,
                    type: MEDIA_TYPES.Image,
                    hashId: 'deleted',
                    _links: { self: { href: '' }, domains: { href: '' } }
                }));
            }
            else {
                throwError(err);
            }
        }), map(function (res) {
            return _this.mapper.mapMedia(res);
        }));
    };
    XdsPlayerInventoriesApiService.prototype.mapResource = function (res) {
        return this.mapper.mapPlayerInventory(res);
    };
    XdsPlayerInventoriesApiService.configOptions = {
        endpoint: 'players',
        search: {
            endpoint: 'playerInventories/search/advanced'
        }
    };
    XdsPlayerInventoriesApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsPlayerInventoriesApiService_Factory() { return new XdsPlayerInventoriesApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient), i0.inject(i3.AuthService)); }, token: XdsPlayerInventoriesApiService, providedIn: "root" });
    return XdsPlayerInventoriesApiService;
}(XdsResourceApiService));
export { XdsPlayerInventoriesApiService };
