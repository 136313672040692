import {Directive, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MediaItemSource} from '../../interfaces/media-item-source';
import {DOCUMENT} from '@angular/common';
import {RetryDirective} from '../retry.directive';
import {KeycloakService} from "keycloak-angular";

@Directive({
  selector: '[appRetryVideo]'
})
export class RetryVideoDirective extends RetryDirective implements OnInit, OnDestroy {

  @Input()
  public appRetryVideoSrc: MediaItemSource[];

  @Input()
  public appRetryPosterSrc: MediaItemSource;

  @Input()
  public appRetryVideoInterval: number;

  @Output()
  public appRetryVideoStatusChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @HostBinding('attr.src')
  public srcAttr: string;

  @HostBinding('attr.poster')
  public posterAttr: string;

  private foundOneSrc = false;

  constructor(
    http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    protected keycloak: KeycloakService,
    @Inject(DOCUMENT) private document,
  ) {
    super(http);
  }

  public ngOnInit(): void {
    const videoSrcs: MediaItemSource[] = this.appRetryVideoSrc;
    const posterSrc: MediaItemSource = this.appRetryPosterSrc;
    const interval: number = this.getRetryInterval(this.appRetryVideoInterval);

    if (posterSrc.src) {
      this.performCheckHead(posterSrc.src, interval, (src, successful) => {
        if (successful) {
          console.debug('poster done, waiting for video...');
        }
      });
    }
    videoSrcs.forEach(media => {
      if (media.src) {
        this.performCheckHead(media.src, interval, (src, successful) => {
          if (successful) {
            this.setSrc(media);
            this.setPoster(posterSrc.src);
            this.foundOneSrc = true;
          } else {
            if (!this.foundOneSrc) {
              this.setPoster(src);
            }
          }
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }

  private setSrc(media: MediaItemSource): void {
    this.keycloak.getToken().then((token: string) => {
      const source = this.document.createElement('source');
      source.src = media.src + '?access_token=' + token;
      source.mimeType = media.mimeType;
      this.renderer.appendChild(this.el.nativeElement, source);
      this.appRetryVideoStatusChange.emit(true);
    });
  }

  private setPoster(src: string): void {
    if (src && src.startsWith('data:')) {
      this.posterAttr = src;

    } else {
      this.keycloak.getToken().then((token: string) => {
        this.posterAttr = src + '?access_token=' + token;
      });
    }
  }

}
