import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-error-empty-list',
  templateUrl: './page-error-empty-list.component.html',
  styleUrls: ['./page-error-empty-list.component.sass']
})
export class PageErrorEmptyListComponent implements OnInit {

  constructor() { }

  public ngOnInit() {
  }

}
