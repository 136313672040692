/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/card";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./landing.component";
import * as i9 from "../../../services/title-bar/title-bar.service";
import * as i10 from "../../../services/domain/domain.service";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "theme-logo-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "theme-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 36, "div", [["class", "page-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "mat-card", [["class", "pointer mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), i1.ɵdid(6, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(10, 638976, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["theaters"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "mat-body-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 11, "mat-card", [["class", "pointer mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(16, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 3), i1.ɵdid(18, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(20, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(22, 638976, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["collections"])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "mat-body-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(25, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(27, 0, null, null, 11, "mat-card", [["class", "pointer mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(28, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(29, 3), i1.ɵdid(30, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵeld(31, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(32, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(33, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(34, 638976, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["devices"])), (_l()(), i1.ɵeld(36, 0, null, null, 2, "div", [["class", "mat-body-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(37, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, "../", _co.currentDomainId, "medias"); _ck(_v, 4, 0, currVal_0); _ck(_v, 10, 0); var currVal_3 = _ck(_v, 17, 0, "../", _co.currentDomainId, "mediaGroups"); _ck(_v, 16, 0, currVal_3); _ck(_v, 22, 0); var currVal_6 = _ck(_v, 29, 0, "../", _co.currentDomainId, "players"); _ck(_v, 28, 0, currVal_6); _ck(_v, 34, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 10).inline; _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("COMMON.MEDIA")); _ck(_v, 13, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 22).inline; _ck(_v, 21, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform("COMMON.MEDIAGROUPS")); _ck(_v, 25, 0, currVal_5); var currVal_7 = i1.ɵnov(_v, 34).inline; _ck(_v, 33, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 37, 0, i1.ɵnov(_v, 38).transform("COMMON.PLAYERS")); _ck(_v, 37, 0, currVal_8); }); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 245760, null, 0, i8.LandingComponent, [i9.TitleBarService, i10.DomainService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i8.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
