import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Media} from '../../../../classes/xds-models/media';

@Component({
  selector: 'app-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.sass']
})
export class ImagePreviewDialogComponent implements OnInit {

  public item: Media;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
  ) { }

  public ngOnInit(): void {
    this.item = this.data;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
