import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {XdsResourceApiService} from '../resource/xds-resource-api.service';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {MediaGroupsSearch} from '../../../interfaces/search/media-groups.search';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {Observable} from 'rxjs';
import {Domain} from '../../../classes/xds-models/domain';
import {map} from 'rxjs/operators';
import {XdsApiUtilService} from '../xds-api-util.service';
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class XdsMediaGroupsApiService extends XdsResourceApiService<MediaGroup, MediaGroupsSearch> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'mediaGroups',
    search: {
      endpoint: 'mediaGroups/search/findByAllParamsForDomain'
    }
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient,
    readonly auth: AuthService,
  ) {
    super(http, mapper, XdsMediaGroupsApiService.configOptions);
    this.initialize();
  }

  private initialize() {
    this.auth.network.subscribe(network => {
      if (network.limitedContentView) {
        this.options.search.endpoint = 'mediaGroups/search/findByAllParamsForDomainWithoutParents';
      }
    });
  }

  public getDomainsForMediaGroup(mediaGroup: MediaGroup): Observable<Domain[]> {
    return this.http.request(
      'GET',
      mediaGroup.links.domains.href
    ).pipe(
      map(res => this.mapResourceList<Domain>(res, x => this.mapper.mapDomain(x)))
    );
  }

  public getAllForDomain(domain: Domain): Observable<MediaGroup[]> {
    const searchStr = XdsApiUtilService.buildQueryString({_domain: domain.id});
    return this.http.request(
      'GET',
      XdsApiUtilService.createApiUrl('mediaGroups/search/findAllForDomain' + searchStr)
    ).pipe(
      map(res => this.mapResourceList<MediaGroup>(res, x => this.mapResource(x)))
    );
  }

  protected mapResource(res: any): MediaGroup {
    return this.mapper.mapMediaGroup(res);
  }
}
