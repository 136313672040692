/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-error-sww.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./page-error-sww.component";
var styles_PageErrorSwwComponent = [i0.styles];
var RenderType_PageErrorSwwComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageErrorSwwComponent, data: {} });
export { RenderType_PageErrorSwwComponent as RenderType_PageErrorSwwComponent };
export function View_PageErrorSwwComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["error_outline"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ERROR.SWW.TITLE")); _ck(_v, 5, 0, currVal_1); }); }
export function View_PageErrorSwwComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-error-sww", [], null, null, null, View_PageErrorSwwComponent_0, RenderType_PageErrorSwwComponent)), i1.ɵdid(1, 114688, null, 0, i5.PageErrorSwwComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageErrorSwwComponentNgFactory = i1.ɵccf("app-page-error-sww", i5.PageErrorSwwComponent, View_PageErrorSwwComponent_Host_0, {}, {}, []);
export { PageErrorSwwComponentNgFactory as PageErrorSwwComponentNgFactory };
