import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, combineLatest, from, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {XdsApiUtilService} from '../xds-api/xds-api-util.service';
import {filter, map} from 'rxjs/operators';
import {IXdsNetwork} from '../../interfaces/xds-models/network';
import {IXdsCurrentUser} from '../../interfaces/xds-models/currenUser';
import {IXdsKeycloakToken} from '../../interfaces/xds-keycloak-token';
import {Moment} from 'moment';
import * as moment from 'moment';

// keycloak wrapper to provide a easy access for some values

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private network$: BehaviorSubject<IXdsNetwork> = new BehaviorSubject(null);
  private domainId$: BehaviorSubject<number> = new BehaviorSubject(null);
  private locale$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private keycloak: KeycloakService,
    private http: HttpClient,
  ) {
    combineLatest<IXdsCurrentUser, string>(this.downloadCurrentUser(), this.keycloak.getToken()).subscribe(
      result => {
        const currentUser: IXdsCurrentUser = result[0];
        const parsedToken: IXdsKeycloakToken = this.keycloak.getKeycloakInstance().tokenParsed;
        const accessAllowedList: string[] = this.getAccessAllowedList(parsedToken);
        const domainId: number = this.findDomainId(accessAllowedList, currentUser.networkKey);
        const currentNetwork = this.findCurrentNetwork(currentUser.user.networks, currentUser.networkKey);

        this.network$.next(currentNetwork);
        this.domainId$.next(domainId);
        this.locale$.next(parsedToken.locale);
        moment.locale(parsedToken.locale);

      },
      err => {
        this.network$.next({
          key: '',
          domainRestrictions: false,
          nonBeeceenPlayers: false,
          limitedContentView: false,
          color: '',
          icon: '',
          name: '',
        });
        this.domainId$.next(-1);
        console.error(err);
      }
    );
  }

  public get language(): Observable<string> {
    return this.locale$
      .pipe(filter(val => typeof val !== 'undefined' && val !== null));
  }

  public get network(): Observable<IXdsNetwork> {
    return this.network$.asObservable()
      .pipe(filter(val => typeof val !== 'undefined' && val !== null));
  }

  public get networkKey(): Observable<string> {
    return this.network$.asObservable()
      .pipe(filter(val => typeof val !== 'undefined' && val !== null))
      .pipe(map(network => network.key));
  }

  public get domainId(): Observable<number> {
    return this.domainId$.asObservable()
      .pipe(map(val => {
        return val;
      }))
      .pipe(filter(val => typeof val !== 'undefined' && val !== null));
  }

  public get currentNetworkKey(): string {
    const currentNetwork = this.network$.getValue();

    if (typeof currentNetwork !== 'undefined' && currentNetwork !== null) {
      return currentNetwork.key;
    } else {
      return '';
    }
  }

  public checkGeneralDomainPermissions(): Observable<boolean> {
    return combineLatest<IXdsNetwork, number>(this.network, this.domainId)
      .pipe(map(val => {
        const network: IXdsNetwork = val[0];
        const domainId: number = val[1];

        return domainId >= 0 && network.domainRestrictions;
      }));
  }

  public createAccountUrl(): string {
    return this.keycloak.getKeycloakInstance().createAccountUrl();
  }

  // cannot move to xds-api, caused by circular dependency (auth > api > ... > mapper > auth)
  public logout(): void {
    this.http.request(
      'POST',
      XdsApiUtilService.createApiUrl('userLogout'),
      {}
    ).subscribe(
      () => {
        document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.keycloak.logout();
        // this.keycloak.logout('http://localhost:81/bcn/landing');
      },
      () => {
        document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.keycloak.logout();
        // this.keycloak.logout('http://localhost:81/bcn/landing');
      }
    );
  }

  // cannot move to xds-api, caused by circular dependency (auth > api > ... > mapper > auth)
  private downloadCurrentUser(): Observable<IXdsCurrentUser> {
    return this.http.request<IXdsCurrentUser>(
      'GET',
      XdsApiUtilService.createApiUrl('currentUser')
    );
  }

  private splitAllowedAccess(rawAcces: string): { network: string, domainId: number } {
    // /{network}/{domaintree as ids, separated by /}
    const access = rawAcces.split('/');

    const network: string = access[1]; // access[0] is empty cause of leading '/'
    let domainId = -1;

    if (access.length > 2) {
      domainId = +(access[access.length - 1]);
    }

    return {
      network: network,
      domainId: domainId
    };
  }

  private findDomainId(accessAllowedList: string[], currentNetwork: string): number {
    for (let i = 0; i < accessAllowedList.length; i++) {
      const access: { network: string, domainId: number } = this.splitAllowedAccess(accessAllowedList[i]);

      if (currentNetwork === access.network) {
        return access.domainId;
      }
    }

    return -1;
  }

  private findCurrentNetwork(networkList: IXdsNetwork[], networkKey: string): IXdsNetwork {
    for (let i = 0; i < networkList.length; i++) {
      if (networkList[i].key === networkKey) {
        return networkList[i];
      }
    }

    return {
      key: '',
      domainRestrictions: false,
      nonBeeceenPlayers: false,
      limitedContentView: false,
      color: '',
      icon: '',
      name: '',
    };
  }

  private getAccessAllowedList(token: IXdsKeycloakToken): string[] {
    if (token.XdsTree && token.XdsTree.accessAllowedList) {
      return token.XdsTree.accessAllowedList;
    } else {
      return [];
    }
  }

}
