import {Injectable} from '@angular/core';
import {XdsResourceApiService} from '../resource/xds-resource-api.service';
import {Media} from '../../../classes/xds-models/media';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {HttpClient} from '@angular/common/http';
import {MediasSearch} from '../../../interfaces/search/medias.search';
import {Observable} from 'rxjs';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {map} from 'rxjs/operators';
import {MediaFile} from '../../../classes/xds-models/media-file';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {Container} from '../../../classes/xds-models/container';
import {XdsApiUtilService} from '../xds-api-util.service';
import {PlayoutParams} from '../../../interfaces/xds-models/playout-params';
import {Domain} from '../../../classes/xds-models/domain';
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class XdsMediasApiService extends XdsResourceApiService<Media, MediasSearch> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'medias',
    search: {
      endpoint: 'medias/search/findByAllParamsForDomain'
    }
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient,
    readonly auth: AuthService,
  ) {
    super(http, mapper, XdsMediasApiService.configOptions);
    this.initialize();
  }

  private initialize() {
    this.auth.network.subscribe(network => {
      if (network.limitedContentView) {
        this.options.search.endpoint = 'medias/search/findByAllParamsForDomainWithoutParents';
      }
    });
  }

  public getByContainerItem(container: ContainerItem): Observable<Media> {
    return this.http.request(
      'GET',
      container.links.item.href
    ).pipe(map(res => this.mapResource(res)));
  }

  public getMediaFilesForMedia(media: Media): Observable<MediaFile[]> {
    return this.http.request(
      'GET',
      media.links.mediaFiles.href
    ).pipe(
      map(res => this.mapResourceList<MediaFile>(res, x => this.mapper.mapMediaFile(x)))
    );
  }

  public getDomainsForMedia(media: Media): Observable<Domain[]> {
    return this.http.request(
      'GET',
      media.links.domains.href
    ).pipe(
      map(res => this.mapResourceList(res, x => this.mapper.mapDomain(x)))
    );
  }

  public addTo(medias: Media[], containers: Container[], domains: Domain[], playoutParams: PlayoutParams): Observable<any> {
    const payload = {
      type: 'addTo',
      containers: XdsApiUtilService.resourceListToStrIdList(containers),
      domains: XdsApiUtilService.resourceListToStrIdList(domains),
      items: XdsApiUtilService.resourceListToStrIdList(medias),
      playoutParams: playoutParams
    };

    return this.batch('addTo', payload);
  }

  public deleteMany(medias: Media[], ): Observable<any> {
    const payload = {
      type: 'delete',
      items: XdsApiUtilService.resourceListToStrIdList(medias)
    };

    return this.batch('delete', payload);
  }

  protected mapResource(res: any): Media {
    return this.mapper.mapMedia(res);
  }
}
