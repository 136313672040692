import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ComponentType} from '@angular/cdk/portal';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  // This config stops Angular from setting an max-width=80vw inline style on the element.
  private dialogConfig: MatDialogConfig = {
    maxWidth: '',
    maxHeight: '',
  };
  private dialogClass = 'dialog';
  private previewClass = 'preview';

  constructor(
    private dialog: MatDialog,
  ) {
  }

  /**
   * Returns a display size adaptive MatDialogConfig for standard dialogs.
   * @returns {MatDialogConfig}
   */
  public getConfig(): MatDialogConfig {
    return this.createConfig([this.dialogClass]);
  }

  /**
   * Returns a display size adaptive MatDialogConfig for preview dialogs.
   * @returns {MatDialogConfig}
   */
  public getPreviewConfig(): MatDialogConfig {
    return this.createConfig([this.dialogClass, this.previewClass]);
  }

  /**
   * Opens an Angular Material Dialog an passes data to it.
   * @param {ComponentType<any>} component
   * @param data
   * @returns {Observable<any | undefined>}
   */
  public openDialog(component: ComponentType<any>, data: any): Observable<any | undefined> {
    const config: MatDialogConfig = this.getConfig();
    config.data = data;
    return this.openDialogWithConfig(component, config);
  }

  /**
   * Opens an Angular Material Dialog an passes a MatDialogConfig to it.
   * @param {ComponentType<any>} component
   * @param {MatDialogConfig} config
   * @returns {Observable<any | undefined>}
   */
  public openDialogWithConfig(component: ComponentType<any>, config: MatDialogConfig): Observable<any | undefined> {
    const dialogRef = this.dialog.open(component, config);
    return dialogRef.afterClosed();
  }

  private createConfig(panelClass: string | string[]): MatDialogConfig {
    const config: MatDialogConfig = {};
    Object.assign(config, this.dialogConfig);
    config.panelClass = panelClass;
    return config;
  }
}
