<mat-icon class="icon-fix"
          (click)="toggle()"
          [ngClass]="showSearchBarSwitch === false ? 'hidden' : ''">
  {{(domainService.currentDomainIsTld() | async) ? 'public' : 'store_mall_directory'}}
</mat-icon>
<mat-icon class="icon-fix"
          (click)="toggle()"
          [ngClass]="(displaySizeService.isHandset | async) === false || showSearchBar === false ? 'hidden' : ''">close
</mat-icon>

<mat-form-field
  [ngClass]="showSearchBar === false ? 'hidden' : ''">
  <mat-select (selectionChange)="changeDomain($event)"
              [value]="currentDomain">
    <mat-option *ngFor="let domain of domains"
                [value]="domain">
      {{ domain.name }}
    </mat-option>
  </mat-select>
  <mat-icon matPrefix class="icon-fix"
            *ngIf="(domainService.currentDomainIsTld() | async)">public
  </mat-icon>
  <mat-icon matPrefix class="icon-fix"
            *ngIf="!(domainService.currentDomainIsTld() | async)"
            [matTooltip]="'COMMON.STOREPERMISSIONS' | translate">store_mall_directory
  </mat-icon>
</mat-form-field>

<div class="text-container" *ngIf="showSearchBar === false">
  {{currentDomain?.name}}
</div>

