import {Component, OnInit} from '@angular/core';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';

@Component({
  selector: 'app-responsive-toolbar',
  templateUrl: './responsive-toolbar.component.html',
  styleUrls: ['./responsive-toolbar.component.sass']
})
export class ResponsiveToolbarComponent implements OnInit {

  constructor(
    public displaySize: DisplaySizeService
  ) {
  }

  public ngOnInit() {
  }

}
