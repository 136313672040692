import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastService} from '../../../services/broadcast/broadcast.service';

// fix me ExpressionChangedAfterItHasBeenCheckedError

@Component({
  selector: 'app-notification-upload-complete',
  templateUrl: './notification-upload-complete.component.html',
  styleUrls: ['./notification-upload-complete.component.sass']
})
export class NotificationUploadCompleteComponent implements OnInit {
  public component: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private broadcaster: BroadcastService
  ) {
  }

  public ngOnInit() {
  }

  public dismiss() {
    this.component.dismiss();
  }

  public refresh() {
    this.broadcaster.emitReload();
    this.dismiss();
  }

}
