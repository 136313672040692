var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsNamedResource } from './xds-named-resource';
import { MEDIA } from '../../const/media.const';
import { DateUtil } from '../date-util';
import { XdsApiUtilService } from '../../services/xds-api/xds-api-util.service';
var Media = /** @class */ (function (_super) {
    __extends(Media, _super);
    function Media(name, validFrom, validTo, _type, _hashId, _currentRevision, _mimeType, _usedInContainerItemsCount, _network, links) {
        var _this = _super.call(this, name, links) || this;
        _this._type = _type;
        _this._hashId = _hashId;
        _this._currentRevision = _currentRevision;
        _this._mimeType = _mimeType;
        _this._usedInContainerItemsCount = _usedInContainerItemsCount;
        _this._network = _network;
        _this._isWritable = false;
        _this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
        _this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
        return _this;
    }
    Object.defineProperty(Media.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "hashId", {
        get: function () {
            return this._hashId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "isWritable", {
        get: function () {
            return this._isWritable;
        },
        set: function (value) {
            this._isWritable = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "currentRevision", {
        get: function () {
            return this._currentRevision;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "mimeType", {
        get: function () {
            return this._mimeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "validFrom", {
        get: function () {
            return this._validFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "validTo", {
        get: function () {
            return this._validTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Media.prototype, "usedInContainerItemsCount", {
        get: function () {
            return this._usedInContainerItemsCount;
        },
        enumerable: true,
        configurable: true
    });
    Media.prototype.thumbnailDownload = function () {
        var _this = this;
        return this.createMediaItemSource(function (mimeType, size) { return _this.mediaDownloadUrl(mimeType, size); }, MEDIA.mimeTypes.png, MEDIA.sizes.thumbnail);
    };
    Media.prototype.mediaDownloadUrl = function (mimeType, size) {
        return XdsApiUtilService.createApiUrl('' +
            'medias/bin/' +
            this.Network + '/' +
            this.hashId + '/' +
            this.currentRevision + '/' +
            mimeType + '/' +
            size + '/' +
            this.hashId + '.' + mimeType.split('/')[1]);
    };
    Object.defineProperty(Media.prototype, "Network", {
        get: function () {
            return this._network;
        },
        enumerable: true,
        configurable: true
    });
    Media.prototype.createMediaItemSource = function (src, mimeType, size) {
        // this as a higher order allows us to set different types of soruces, in our case iframe links and media links
        return {
            src: src(mimeType, size),
            mimeType: mimeType,
            size: size,
        };
    };
    return Media;
}(XdsNamedResource));
export { Media };
