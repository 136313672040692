var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { XdsResourceApiService } from '../resource/xds-resource-api.service';
import { XdsApiModelMapperService } from '../xds-api-model-mapper.service';
import { map } from 'rxjs/operators';
import { XdsApiUtilService } from '../xds-api-util.service';
import { AuthService } from "../../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../auth/auth.service";
var XdsMediaGroupsApiService = /** @class */ (function (_super) {
    __extends(XdsMediaGroupsApiService, _super);
    function XdsMediaGroupsApiService(mapper, http, auth) {
        var _this = _super.call(this, http, mapper, XdsMediaGroupsApiService.configOptions) || this;
        _this.auth = auth;
        _this.initialize();
        return _this;
    }
    XdsMediaGroupsApiService.prototype.initialize = function () {
        var _this = this;
        this.auth.network.subscribe(function (network) {
            if (network.limitedContentView) {
                _this.options.search.endpoint = 'mediaGroups/search/findByAllParamsForDomainWithoutParents';
            }
        });
    };
    XdsMediaGroupsApiService.prototype.getDomainsForMediaGroup = function (mediaGroup) {
        var _this = this;
        return this.http.request('GET', mediaGroup.links.domains.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapper.mapDomain(x); }); }));
    };
    XdsMediaGroupsApiService.prototype.getAllForDomain = function (domain) {
        var _this = this;
        var searchStr = XdsApiUtilService.buildQueryString({ _domain: domain.id });
        return this.http.request('GET', XdsApiUtilService.createApiUrl('mediaGroups/search/findAllForDomain' + searchStr)).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapResource(x); }); }));
    };
    XdsMediaGroupsApiService.prototype.mapResource = function (res) {
        return this.mapper.mapMediaGroup(res);
    };
    XdsMediaGroupsApiService.configOptions = {
        endpoint: 'mediaGroups',
        search: {
            endpoint: 'mediaGroups/search/findByAllParamsForDomain'
        }
    };
    XdsMediaGroupsApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsMediaGroupsApiService_Factory() { return new XdsMediaGroupsApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient), i0.inject(i3.AuthService)); }, token: XdsMediaGroupsApiService, providedIn: "root" });
    return XdsMediaGroupsApiService;
}(XdsResourceApiService));
export { XdsMediaGroupsApiService };
