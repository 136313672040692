import {XdsApiUtilService} from '../../services/xds-api/xds-api-util.service';
import {Media} from './media';
import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {MEDIA_TYPES} from '../../const/media-types.enum';
import {MEDIA} from '../../const/media.const';
import {MediaItemSource} from '../../interfaces/media-item-source';

export class MediaMessage extends Media {

  constructor(
    name: string,
    validFrom: Date | null,
    validTo: Date | null,
    hashId: string,
    currentRevision: number,
    mimeType: string,
    usedInContainerItemsCount: number,
    network: string,
    links: ResourceLinks
  ) {
    super(
      name,
      validFrom,
      validTo,
      MEDIA_TYPES.Message,
      hashId,
      currentRevision,
      mimeType,
      usedInContainerItemsCount,
      network,
      links
    );
  }

  public get iconName(): string {
    return 'mail';
  }

  public previewDownload(): MediaItemSource[] {
    return [
      this.createMediaItemSource(
        (mimeType, size) => this.mediaDownloadUrl(mimeType, size),
        MEDIA.mimeTypes.png,
        MEDIA.sizes.large_preview
      )
    ];
  }
}
