import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {XdsResourceView} from './xds-models/xds-resource-view';
import {MediaGroup} from './xds-models/media-group';
import {ContainerItem} from './xds-models/container-item';
import {Media} from './xds-models/media';
import {PlayerInventory} from "./xds-models/player-inventory";

export class SelectionManager<T extends XdsResourceView<(MediaGroup | ContainerItem | Media | PlayerInventory)>> {
  private _selectedItems: T[] = [];
  private _selectedItems$ = new BehaviorSubject<T[]>([]);

  constructor() {
  }

  public change(value: T) {
    if (this.isSelected(value)) {
      this.unselect(value);
    } else {
      this.select(value);
    }
    this._selectedItems$.next(this.items);
  }

  public get count(): number {
    return this._selectedItems.length;
  }

  public isAllSelected(values: T[] | Observable<T[]>): boolean {
    if (values instanceof Observable) {
      values
        .pipe(
          take(1)
        )
        .subscribe(vs => this.isAllSelected(vs));
      return;
    }

    for (const value of values) {
      if (!this.isSelected(value)) {
        return false;
      }
    }
    return true;
  }

  public isAnySelected(): boolean {
    return this._selectedItems.length > 0;
  }

  public isNoneSelected(): boolean {
    return this._selectedItems.length === 0;
  }

  public isSelected(value: T): boolean {
    return this._selectedItems.indexOf(value) > -1;
  }

  public getItems(): Observable<T[]> {
    return this._selectedItems$.asObservable();
  }

  public removeAllBut(values: T | T[]): void {
    const elements = Array.isArray(values) ? values : [values];
    const missing = this._selectedItems.filter(item => elements.indexOf(item) < 0);
    this.unselect(missing);
  }

  public updateItems(values: T[]): void {
    for (let i = 0; i < this._selectedItems.length; i++) {
      const newValue = values.filter(item => item.xdsResource.id === this._selectedItems[i].xdsResource.id)[0];
      if (newValue) {
        this._selectedItems[i] = newValue;
      }
    }
    this._selectedItems$.next(this.items);
  }

  public flush(): void {
    this.unselect(this.items);
  }

  public selectUnselectAll(values: T[] | Observable<T[]>): void {
    if (values instanceof Observable) {
      values
        .pipe(
          take(1)
        )
        .subscribe(vs => this.selectUnselectAll(vs));
      return;
    }

    if (this.isAllSelected(values)) {
      this.unselect(values);
    } else {
      this.select(values);
    }
  }

  public select(value: T | T[] | Observable<T[]>): void {
    this.changeSelection(value, true);
  }

  public unselect(value: T | T[] | Observable<T[]>): void {
    this.changeSelection(value, false);
  }

  private changeSelection(value: T | T[] | Observable<T[]>, select: boolean) {
    if (value instanceof Observable) {
      value
        .pipe(
          take(1)
        )
        .subscribe(vs => this.changeSelection(vs, select));
      return;
    }

    if (!Array.isArray(value)) {
      value = [value];
    }

    if (select) {
      this.selectValues(value);
    } else {
      this.unselectValues(value);
    }

    this._selectedItems$.next(this.items);
  }

  private selectValues(values: T[]): void {
    values.forEach(item => {
      if (this._selectedItems.indexOf(item) === -1) {
        this._selectedItems.push(item);
      }
    });
  }

  private unselectValues(values: T[]): void {
    values.map(item => item.xdsResource.id)
      .forEach(deselectId => {
        const pos = this._selectedItems.map(item => item.xdsResource.id).indexOf(deselectId);
        if (pos > -1) {
          this._selectedItems.splice(pos, 1);
        }
      });
  }

  private get items(): T[] {
    return this._selectedItems;
  }
}
