/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-preview-dialog.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../directives/retry-image/retry-image.directive";
import * as i5 from "@angular/common/http";
import * as i6 from "keycloak-angular";
import * as i7 from "./image-preview-dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_ImagePreviewDialogComponent = [i0.styles];
var RenderType_ImagePreviewDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePreviewDialogComponent, data: {} });
export { RenderType_ImagePreviewDialogComponent as RenderType_ImagePreviewDialogComponent };
export function View_ImagePreviewDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "close-icon-wrapper pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["appRetryImage", ""]], [[1, "src", 4]], null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RetryImageDirective, [i5.HttpClient, i6.KeycloakService], { appRetryImageSrc: [0, "appRetryImageSrc"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.item.previewDownload()[0].src; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).srcAttr; _ck(_v, 4, 0, currVal_1); }); }
export function View_ImagePreviewDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-preview-dialog", [], null, null, null, View_ImagePreviewDialogComponent_0, RenderType_ImagePreviewDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.ImagePreviewDialogComponent, [i8.MAT_DIALOG_DATA, i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagePreviewDialogComponentNgFactory = i1.ɵccf("app-image-preview-dialog", i7.ImagePreviewDialogComponent, View_ImagePreviewDialogComponent_Host_0, {}, {}, []);
export { ImagePreviewDialogComponentNgFactory as ImagePreviewDialogComponentNgFactory };
