var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResource } from './xds-resource';
var PlayerStatus = /** @class */ (function (_super) {
    __extends(PlayerStatus, _super);
    function PlayerStatus(_heartbeat, links) {
        var _this = _super.call(this, links) || this;
        _this._heartbeat = _heartbeat;
        return _this;
    }
    Object.defineProperty(PlayerStatus.prototype, "lastHeartbeat", {
        get: function () {
            return this._heartbeat;
        },
        enumerable: true,
        configurable: true
    });
    PlayerStatus.prototype.lastHeartbeatDiff = function () {
        var currentDate = Date.now();
        var diffMilliseconds = Math.abs(currentDate - this.lastHeartbeat.getTime());
        var diffSeconds = Math.floor(diffMilliseconds / (60 * 1000));
        var diffMinutes = Math.floor(diffMilliseconds / (60 * 1000));
        var diffHours = Math.floor(diffMilliseconds / (60 * 60 * 1000));
        var diffDays = Math.floor(diffMilliseconds / (24 * 60 * 60 * 1000));
        return {
            ms: diffMilliseconds,
            s: diffSeconds,
            m: diffMinutes,
            h: diffHours,
            d: diffDays
        };
    };
    PlayerStatus.prototype.statusState = function () {
        var diff = this.lastHeartbeatDiff();
        if (diff.d > 0) {
            return 'red';
        }
        else if (diff.h > 0) {
            return 'yellow';
        }
        else {
            return 'green';
        }
    };
    PlayerStatus.prototype.statusHeartbeat = function () {
        var diff = this.lastHeartbeatDiff();
        if (diff.d > 0) {
            return { key: 'PLAYER.DAYS', value: diff.d };
        }
        else if (diff.h > 0) {
            return { key: 'PLAYER.HOURS', value: diff.h };
        }
        else if (diff.m > 0) {
            return { key: 'PLAYER.MINUTES', value: diff.m };
        }
        else {
            return { key: 'PLAYER.MOMENTS' };
        }
    };
    return PlayerStatus;
}(XdsResource));
export { PlayerStatus };
