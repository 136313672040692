var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RetryDirective } from '../retry.directive';
import { KeycloakService } from "keycloak-angular";
var RetryImageDirective = /** @class */ (function (_super) {
    __extends(RetryImageDirective, _super);
    function RetryImageDirective(http, keycloak) {
        var _this = _super.call(this, http) || this;
        _this.keycloak = keycloak;
        _this.appRetryImageStatusChange = new EventEmitter();
        return _this;
    }
    RetryImageDirective.prototype.ngOnInit = function () {
        var _this = this;
        var srcUri = this.appRetryImageSrc;
        var interval = this.getRetryInterval(this.appRetryImageInterval);
        if (srcUri) {
            this.performCheckHead(srcUri, interval, function (src, successful) {
                _this.setSrc(src);
            });
        }
    };
    RetryImageDirective.prototype.ngOnDestroy = function () {
        this.destroy$.next(false);
        this.destroy$.unsubscribe();
    };
    RetryImageDirective.prototype.setSrc = function (src) {
        var _this = this;
        if (src && src.startsWith('data:')) {
            this.srcAttr = src;
        }
        else {
            this.keycloak.getToken().then(function (token) {
                _this.srcAttr = src + '?access_token=' + token;
            });
        }
    };
    return RetryImageDirective;
}(RetryDirective));
export { RetryImageDirective };
