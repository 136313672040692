import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Media} from '../../../../classes/xds-models/media';

@Component({
  selector: 'app-video-preview-dialog',
  templateUrl: './video-preview-dialog.component.html',
  styleUrls: ['./video-preview-dialog.component.sass']
})
export class VideoPreviewDialogComponent implements OnInit {

  public item: Media;

  @ViewChild('video') private videoElement: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VideoPreviewDialogComponent>,
  ) {
  }

  public ngOnInit(): void {
    this.item = this.data;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public reload(): void {
    this.videoElement.nativeElement.load();
  }

}
