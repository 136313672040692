import { XdsApiUtilService } from '../../services/xds-api/xds-api-util.service';
var XdsResource = /** @class */ (function () {
    function XdsResource(_links) {
        this._links = _links;
        this._id = XdsApiUtilService.getIdFromSelfLink(this._links.self.href);
    }
    XdsResource.new = function () {
        throw new Error('Not implemented yet!');
    };
    Object.defineProperty(XdsResource.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsResource.prototype, "links", {
        get: function () {
            return this._links;
        },
        enumerable: true,
        configurable: true
    });
    return XdsResource;
}());
export { XdsResource };
