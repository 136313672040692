import {Component, Inject} from '@angular/core';
import {DisplaySizeService} from './services/display-size/display-size.service';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {AuthService} from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(
    private displaySize: DisplaySizeService,
    private translate: TranslateService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translate.addLangs(['en', 'de', 'es']);
    this.translate.setDefaultLang('en');
    const handset = 'is-handset';
    const tablet = 'is-tablet';
    const desktop = 'is-desktop';
    this.displaySize.isHandset.subscribe(isSet => isSet ? this.addClass(handset) : this.removeClass(handset));
    this.displaySize.isTablet.subscribe(isSet => isSet ? this.addClass(tablet) : this.removeClass(tablet));
    this.displaySize.isDesktop.subscribe(isSet => isSet ? this.addClass(desktop) : this.removeClass(desktop));

    this.authService.language.subscribe(lang => {
      if (this.translate.getLangs().indexOf(lang) > -1) {
        this.translate.use(lang);
      } else {
        console.warn(`'${lang}' is not available as language. Using default language.`);
      }
    });
  }

  private addClass(name: string): void {
    this.document.body.classList.add(name);
  }

  private removeClass(name: string): void {
    this.document.body.classList.remove(name);
  }
}
