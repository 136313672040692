// object to merge default values
export const PLAYOUT_PARAMS: PlayoutParams = {
  duration: 0,
  itemsPerCycle: 1,
  playoutEndTime: '23:59',
  playoutStartTime: '00:00',
  priority: 1,
  shuffle: false,
  validDays: 127,
  validFrom: null,
  validTo: null
};

export interface PlayoutParams {
  duration?: number;
  itemsPerCycle?: number;
  playoutEndTime?: string;
  playoutStartTime?: string;
  priority?: number;
  shuffle?: false;
  validDays?: number;
  validFrom: string;
  validTo: string;
}
