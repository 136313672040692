<h1 mat-dialog-title>{{'DIALOG.REMOVEFROMGROUPS.TITLE' | translate}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1">{{'DIALOG.REMOVEFROMGROUPS.HEADER' | translate}}</p>

  <mat-accordion>

    <!--selection of media items-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIA' | translate }}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: mediaItemSelectionManager.count,
            count: (mediasToRemove | async).length
            } }}
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-chip-list #chipList [multiple]="true">
        <mat-chip *ngFor="let media of (mediasToRemove | async)"
                  [selected]="mediaItemSelectionManager.isSelected(media)"
                  (click)="mediaItemSelectionManager.change(media)"
                  class="pointer">
          {{media.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="mediaItemSelectionManager.selectUnselectAll(mediasToRemove)">
        {{(mediaItemSelectionManager.isAllSelected(mediasToRemove| async) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>

    <!--selection of media groups-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIAGROUPS' | translate }}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: mediaGroupSelectionManager.count,
            count: mediaGroups.length
            } }}
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <p class="mat-caption" *ngIf="containerItemSelectionManager.isNoneSelected()">
        <mat-icon class="mat-warn icon-fix">warning</mat-icon>{{'MEDIA.NOELEMENTSTOREMOVE' | translate}}
      </p>

      <mat-chip-list #selectableMGList [multiple]="true">
        <mat-chip *ngFor="let mg of mediaGroups"
                  [selected]="mediaGroupSelectionManager.isSelected(mg)"
                  (click)="mediaGroupSelectionManager.change(mg)"
                  class="pointer">
          {{mg.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="mediaGroupSelectionManager.selectUnselectAll(mediaGroups)">
        {{(mediaGroupSelectionManager.isAllSelected(mediaGroups) ? 'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="ConfirmClick()"
          [disabled]="containerItemSelectionManager.isNoneSelected() || !selectionHasOnlyRWContainers()">
    {{'COMMON.OK' | translate}}
  </button>
  <button mat-button (click)="AbortClick()" cdkFocusInitial>
    {{'COMMON.CANCEL' | translate}}
  </button>
</div>
