import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth/auth.service";
import * as i2 from "@angular/router";
var DomainPermissionGuard = /** @class */ (function () {
    function DomainPermissionGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    DomainPermissionGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.authService.checkGeneralDomainPermissions()
            .pipe(map(function (hasPermissions) {
            if (!hasPermissions) {
                _this.router.navigate(['stay-out']);
            }
            return hasPermissions;
        }));
    };
    DomainPermissionGuard.ngInjectableDef = i0.defineInjectable({ factory: function DomainPermissionGuard_Factory() { return new DomainPermissionGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: DomainPermissionGuard, providedIn: "root" });
    return DomainPermissionGuard;
}());
export { DomainPermissionGuard };
