<mat-toolbar #toolbar class="main-toolbar" color="primary">
  <span [ngClass]="['title', !titleVisibility.value? 'hidden' : '']">{{title}}</span>
</mat-toolbar>

<app-page-message [icon]="'security'">
  <h1>{{'ERROR.SWW.TITLE'|translate}}</h1>
  <p>
    {{'NOPERMISSION.INFOROW1'|translate}}
    <br>
    <span [innerHTML]="'NOPERMISSION.INFOROW2' | translate: {
      email: '<a href=\'mailto:account@xplace-group.com\'>account@xplace-group.com</a>'
      }"></span>
  </p>
  <button mat-flat-button (click)="authService.logout()">{{'COMMON.LOGOUT' | translate }}</button>
</app-page-message>

