import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material';

/**
 * This class overrides the default Angular Material PaginatorIntl class to customize label texts.
 * In order to add i18n support TranslateService has to be injected.
 *
 * @link https://stackoverflow.com/a/49532776
 */
@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl {
  public translate: TranslateService;
  public itemsPerPageLabel = 'Items per page';
  public nextPageLabel     = 'Next page';
  public previousPageLabel = 'Previous page';

  /**
   * Specifices the "page x of y" label text.
   * @param page The current page.
   * @param pageSize The size of a page.
   * @param length The total amout of elements.
   * @returns {string}
   */
  public getRangeLabel = function (page, pageSize, length) {
    const of = this.translate ? this.translate.instant('PAGINATOR.OF') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  /**
   * This function injects TranslateService into this class.
   * All texts will be translated initially and on every following language change of the TranslateService.
   * @param {TranslateService} translate
   */
  public injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  /**
   * Translates all internal used texts.
   */
  public translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMSPERPAGE');
    this.nextPageLabel = this.translate.instant('PAGINATOR.NEXTPAGE');
    this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUSPAGE');
  }

}
