import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-error-server',
  templateUrl: './page-error-server.component.html',
  styleUrls: ['./page-error-server.component.sass']
})
export class PageErrorServerComponent implements OnInit {

  constructor() {
  }

  public ngOnInit() {
  }

  public reload(): void {
    window.location.reload(true);
  }

}
