var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResourceApiService } from '../resource/xds-resource-api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { XdsApiModelMapperService } from '../xds-api-model-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
var XdsPlayersApiService = /** @class */ (function (_super) {
    __extends(XdsPlayersApiService, _super);
    function XdsPlayersApiService(mapper, http) {
        return _super.call(this, http, mapper, XdsPlayersApiService.configOptions) || this;
    }
    XdsPlayersApiService.prototype.getStatus = function (player) {
        var _this = this;
        return this.http.request('GET', player.links.status.href).pipe(map(function (res) { return _this.mapper.mapPlayerStatus(res); }));
    };
    XdsPlayersApiService.prototype.getChannels = function (player) {
        var _this = this;
        return this.http.request('GET', player.links.channels.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapper.mapChannel(x); }); }));
    };
    XdsPlayersApiService.prototype.mapResource = function (res) {
        return this.mapper.mapPlayer(res);
    };
    XdsPlayersApiService.configOptions = {
        endpoint: 'players',
        search: {
            endpoint: 'players/search/advanced'
        }
    };
    XdsPlayersApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsPlayersApiService_Factory() { return new XdsPlayersApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient)); }, token: XdsPlayersApiService, providedIn: "root" });
    return XdsPlayersApiService;
}(XdsResourceApiService));
export { XdsPlayersApiService };
