var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatPaginatorIntl } from '@angular/material';
import * as i0 from "@angular/core";
/**
 * This class overrides the default Angular Material PaginatorIntl class to customize label texts.
 * In order to add i18n support TranslateService has to be injected.
 *
 * @link https://stackoverflow.com/a/49532776
 */
var PaginatorIntlService = /** @class */ (function (_super) {
    __extends(PaginatorIntlService, _super);
    function PaginatorIntlService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.itemsPerPageLabel = 'Items per page';
        _this.nextPageLabel = 'Next page';
        _this.previousPageLabel = 'Previous page';
        /**
         * Specifices the "page x of y" label text.
         * @param page The current page.
         * @param pageSize The size of a page.
         * @param length The total amout of elements.
         * @returns {string}
         */
        _this.getRangeLabel = function (page, pageSize, length) {
            var of = this.translate ? this.translate.instant('PAGINATOR.OF') : 'of';
            if (length === 0 || pageSize === 0) {
                return '0 ' + of + ' ' + length;
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            var endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
        };
        return _this;
    }
    /**
     * This function injects TranslateService into this class.
     * All texts will be translated initially and on every following language change of the TranslateService.
     * @param {TranslateService} translate
     */
    PaginatorIntlService.prototype.injectTranslateService = function (translate) {
        var _this = this;
        this.translate = translate;
        this.translate.onLangChange.subscribe(function () {
            _this.translateLabels();
        });
        this.translateLabels();
    };
    /**
     * Translates all internal used texts.
     */
    PaginatorIntlService.prototype.translateLabels = function () {
        this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMSPERPAGE');
        this.nextPageLabel = this.translate.instant('PAGINATOR.NEXTPAGE');
        this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUSPAGE');
    };
    PaginatorIntlService.ngInjectableDef = i0.defineInjectable({ factory: function PaginatorIntlService_Factory() { return new PaginatorIntlService(); }, token: PaginatorIntlService, providedIn: "root" });
    return PaginatorIntlService;
}(MatPaginatorIntl));
export { PaginatorIntlService };
