<app-card-table-cell class="checkbox-cell" *ngIf="!(displaySize.isHandset | async)">

</app-card-table-cell>

<app-card-table-cell class="app-card-table-thumbnail">
  <app-icon-image *ngIf="item" [icon]="item.iconName"></app-icon-image>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-highlighted">
  <div class="name" *ngIf="item">
    <mat-icon>{{(item.isWritable() | async) ? 'lock_open' : 'lock'}}</mat-icon>
    <a [routerLink]="['..', item.xdsResource.id]">{{item.xdsResource.name}}</a>
  </div>
</app-card-table-cell>

<app-card-table-cell>
  {{containerItem.xdsResource.domainName}}
</app-card-table-cell>

<app-card-table-cell>
  <table>
    <tr>
      <td>{{'TIMESPAN.FROM' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(containerItem.xdsResource.validFrom)}}
          <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}" *ngIf="dateUtil.isInFuture(containerItem.xdsResource.validFrom)">warning</mat-icon>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{'TIMESPAN.UNTIL' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(containerItem.xdsResource.validTo)}}
          <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}" *ngIf="dateUtil.isInPast(containerItem.xdsResource.validTo)">warning</mat-icon>
        </div>
      </td>
    </tr>
  </table>
</app-card-table-cell>
