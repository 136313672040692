import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {DialogService} from '../../dialog/dialog.service';
import {catchError} from 'rxjs/operators';
import {PageErrorOfflineComponent} from '../../../components/shared/errors/page-errors/page-error-offline/page-error-offline.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private dialogService: DialogService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err, caught) => {
          const httpError: HttpErrorResponse = err as HttpErrorResponse;

          if (this.isConnectionError(httpError)) {
            this.dialogService.openDialog(PageErrorOfflineComponent, {})
              .subscribe(
                () => location.reload(),
                () => location.reload(),
                () => location.reload()
              );
          } else {
            // pass error
            throw err;
          }

          return of(err);
        })
      );
  }

  private isConnectionError(err: HttpErrorResponse): boolean {
    const statusCode = err.status;

    return typeof statusCode !== 'undefined' && statusCode !== null && (statusCode === 502 || statusCode === 503);
  }
}
