import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.sass']
})
export class HelpComponent implements OnInit {

  public frontendVersion: {version: string} = environment.version;
  public userManualVariant = 'default';

  public userManualName_DE = '20210819_BEECEEN_Mobile_DE.pdf';
  public userManualName_EN = '20210819_BEECEEN_Mobile_EN.pdf';

  constructor(public auth: AuthService) {
  }

  ngOnInit() {
    this.auth.networkKey.subscribe(
      networkName => {
        if (networkName === 'intersportde') {  // only intersport has a special version currently
          this.userManualVariant = networkName;
          this.userManualName_DE = '20190715_BEECEEN_Store_User_Frontend_V02.pdf';
          this.userManualName_EN = undefined;
        }
      }
    );
  }

}
