import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {DomainService} from '../../../services/domain/domain.service';
import {noop, Observable, Subject} from 'rxjs';
import {InformationService} from '../../../services/information/information.service';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {Moment} from 'moment';
import {take, takeUntil} from 'rxjs/operators';
import {SelectionManager} from '../../../classes/selection-manager';
import {PermissionService} from '../../../services/permission/permission.service';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {TranslateService} from '@ngx-translate/core';
import {TimespanPickerComponent} from '../../shared/timespan-picker/timespan-picker.component';

@Component({
  selector: 'app-edit-container-item-dialog',
  templateUrl: './edit-container-item-dialog.component.html',
  styleUrls: ['./edit-container-item-dialog.component.sass']
})
export class EditContainerItemDialogComponent implements OnInit, OnDestroy {
  public mediaGroups: MediaGroup[] = [];
  public validFromDate: Date = null;
  public validToDate: Date = null;
  public isValid = false;
  public containerItemsToEdit: Observable<XdsResourceView<ContainerItem>[]>;
  public containerItemSelectionManager = new SelectionManager<XdsResourceView<ContainerItem>>();
  public showBlockedInfo = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private notEditableItemsCount = -1;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditContainerItemDialogComponent>,
    private api: XdsApiService,
    private domainService: DomainService,
    private infoService: InformationService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
  ) {
    this.containerItemsToEdit = data.items;
  }

  public ngOnInit() {
    // ToDo: use normal ngOnInit. This is fix is used to avoid flickering while dialog is opening. See permissions.service.ts (L:64)
    setTimeout(this.delayedNgOnInit.bind(this), 200);
  }

  public delayedNgOnInit() {
    const datesFrom: Moment[] = [];
    const datesTo: Moment[] = [];

    this.containerItemSelectionManager.selectUnselectAll(this.containerItemsToEdit);
    this.containerItemSelectionManager.getItems()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(containerItems => {
        this.notEditableItemsCount = 0;
        containerItems.forEach(item => item.isWritable()
          .pipe(take(1))
          .subscribe(writable => {
            if (!writable) {
              this.notEditableItemsCount++;
            }
          })
        );
      });

    this.permissionService.getRWMediaGroups()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        mediaGroups => this.mediaGroups = mediaGroups,
        err => {
          this.infoService.error(err, this.translateService.instant('ERROR.LOADINGITEMS'));
        }
      );

    this.containerItemsToEdit
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(containerItems => {
        containerItems.forEach((item) => {
          datesTo.push(item.xdsResource.validTo ? item.xdsResource.validTo : null);
          datesFrom.push(item.xdsResource.validFrom ? item.xdsResource.validFrom : null);
        });
        this.validFromDate = TimespanPickerComponent.findSharedDate(datesFrom);
        this.validToDate = TimespanPickerComponent.findSharedDate(datesTo);
        this.showBlockedInfo = containerItems.filter(item => !item.writeable()).length > 0;
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {
    this.containerItemSelectionManager.getItems()
      .pipe(
        take(1)
      )
      .subscribe(containerItems => {
          containerItems.forEach((item) => {
            const newItem = item.xdsResource.updateValidDates(this.validFromDate, this.validToDate);

            this.api.ContainerItems.patch(newItem)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                () => noop(),
                (err) => {
                  this.infoService.error(err, this.translateService.instant(
                    'DIALOG.EDITTIMESPAN.ERROR',
                    {item: newItem.name}
                    ));
                },
                () => this.infoService.success(this.translateService.instant('DIALOG.EDITTIMESPAN.SUCCESS'))
              );
          });
        },
        () => {
        },
        () => this.dialogRef.close({dataChanged: true}));
  }

  public validate(): void {
    const isNull: boolean = this.validToDate == null || this.validFromDate == null;
    this.isValid = (isNull) ? true : (this.validFromDate.getDate() <= this.validToDate.getDate());
  }

  public isAllEditable(): boolean {
    return this.containerItemSelectionManager.isAnySelected() && this.notEditableItemsCount === 0;
  }
}
