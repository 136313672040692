import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {AuthService} from '../../../services/auth/auth.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';


@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.sass']
})
export class NoPermissionsComponent implements OnInit {

  public titleVisibility: BehaviorSubject<boolean>;
  public title: string;
  protected isDesktop: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public displaySizeService: DisplaySizeService,
    public authService: AuthService,
    private titleBarService: TitleBarService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit() {
    this.titleVisibility = this.titleBarService.TitleVisibility;
    this.displaySizeService.isDesktop
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => this.isDesktop = val);
    this.translateService.get('NOPERMISSION.TITLE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(msg => {
        this.title = msg;
        this.titleBarService.setTitle(this.title);
      });
  }
}
