import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloak: KeycloakService) {
    super(router, keycloak);
  }

  public isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated || this.keycloak.isTokenExpired()) {
        this.keycloakAngular.login();
        return;
      }

      // todo handle roles (currently not needed)
      resolve(true);
    });
  }
}
