import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {KeycloakGuard} from './guards/keycloak-guard/keycloak-guard.service';
import {DomainPermissionGuard} from './guards/permission-guard/domain-permission-guard.service';
import {GetInGuard} from './guards/get-in/get-in.guard';
import {BcnComponent} from './components/routes/bcn/bcn.component';
import {LandingComponent} from './components/routes/landing/landing.component';
import {PageNotFoundComponent} from './components/routes/page-not-found/page-not-found.component';
import {MediaGroupListComponent} from './components/routes/media-group-list/media-group-list.component';
import {MediaGroupComponent} from './components/routes/media-group/media-group.component';
import {PlayerListComponent} from './components/routes/player-list/player-list.component';
import {MediaItemListComponent} from './components/routes/media-item-list/media-item-list.component';
import {NoPermissionsComponent} from './components/routes/no-permissions/no-permissions.component';
import {HelpComponent} from "./components/routes/help/help.component";
import {PlayerComponent} from "./components/routes/player/player.component";


const routes: Routes = [
  {
    // wrapper to separate (non-existing) public and protected content
    // we only have to assign KeycloakGuard one time
    path: 'bcn',
    component: BcnComponent,
    canActivate: [KeycloakGuard, DomainPermissionGuard],
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
      },
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'help',
        component: HelpComponent
      },
      {
        path: '404',
        component: PageNotFoundComponent,
      },
      {
        path: ':domainId',
        children: [
          {
            path: 'mediaGroups',
            component: MediaGroupListComponent,
          },
          {
            path: 'mediaGroups/:id',
            component: MediaGroupComponent,
          },
          {
            path: 'players',
            component: PlayerListComponent,
          },
          {
            path: 'players/:id',
            component: PlayerComponent
          },
          {
            path: 'medias',
            component: MediaItemListComponent,
          },
        ]
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
  {
    path: 'stay-out', // todo proper name
    component: NoPermissionsComponent,
    canActivate: [KeycloakGuard, GetInGuard]
  },
  {
    path: '**',
    redirectTo: 'bcn/landing',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
