/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "./footer.component";
import * as i5 from "../../../services/xds-api/xds-api.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i3.MatDivider, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "mat-body-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "http://www.xplace.de"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A02018-2022"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "mat-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["BEECEEN v", " [", "]"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "mat-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["v", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ((_co.backendVersions == null) ? null : _co.backendVersions.vendor); _ck(_v, 5, 0, currVal_4); var currVal_5 = ((_co.backendVersions == null) ? null : _co.backendVersions.version); var currVal_6 = ((_co.backendVersions == null) ? null : _co.backendVersions.buildNumber); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = _co.frontendVersion.version; _ck(_v, 10, 0, currVal_7); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [i5.XdsApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
