<app-card-table-cell class="checkbox-cell" *ngIf="!(displaySize.isHandset | async)">
  <mat-checkbox [checked]="selectionManager.isSelected(containerItem)"
                (change)="selectionManager.change(containerItem)"
                (click)="$event.preventDefault();"
                *ngIf="isRWMediaGroup"></mat-checkbox>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-thumbnail">
  <img [alt]="item.xdsResource.name"
       *ngIf="item && item.isMedia()"
       appRetryImage [appRetryImageSrc]="item.thumbnailDownload()"
       appImagePreview [data]="item.xdsResource"/>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-highlighted">
  <div class="name" *ngIf="item">
    <mat-icon>{{item.iconName}}</mat-icon>
    {{item.xdsResource.name}}
  </div>
</app-card-table-cell>

<app-card-table-cell>
  {{containerItem.xdsResource.domainName}}
</app-card-table-cell>

<app-card-table-cell>
  <table>
    <tr>
      <td>{{'TIMESPAN.FROM' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(containerItem.xdsResource.validFrom)}}
          <div class="validity-info" *ngIf="dateUtil.isInFuture(containerItem.xdsResource.validFrom)">
            <mat-icon>warning</mat-icon>
            {{'COMMON.VALID_EXPIRED' | translate}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{'TIMESPAN.UNTIL' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment(containerItem.xdsResource.validTo)}}
          <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}"
                    *ngIf="dateUtil.isInPast(containerItem.xdsResource.validTo)">warning
          </mat-icon>
        </div>
      </td>
    </tr>
  </table>
</app-card-table-cell>
