<h1 mat-dialog-title>{{'DIALOG.DELETEMEDIA.TITLE' | translate}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1">{{'DIALOG.DELETEMEDIA.HEADER' | translate}}</p>

  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIA' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: (mediaItemSelectionManager.getItems() | async).length,
            count: (mediasToRemove | async).length
            } }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div *ngIf="containsBlocked" class="mat-caption">
        <mat-icon class="mat-warn icon-fix">block</mat-icon>{{'COMMON.PERMISSIONINFO' | translate}}
      </div>
      <div *ngIf="containsUsed" class="mat-caption">
        <mat-icon class="mat-warn icon-fix">warning</mat-icon>{{'COMMON.INUSEINFO' | translate}}
      </div>

      <mat-chip-list #chipList [multiple]="true">
        <mat-chip *ngFor="let media of (mediasToRemove | async)"
                  [selected]="mediaItemSelectionManager.isSelected(media)"
                  (click)="mediaItemSelectionManager.change(media)"
                  class="pointer">
          <!--if you have no rw permissions, show error-->
          <mat-icon *ngIf="!(media.isWritable() | async)"
                    class="mat-warn"
                    [matTooltip]="'DIALOG.DELETEMEDIA.TIPPERMISSION' | translate"
                    matTooltipPosition="right">block
          </mat-icon>
          <!--if you have rw permissions, but cannot delete the item because it is still in use -->
          <mat-icon *ngIf="media.writeable && media.xdsResource.usedInContainerItemsCount > 0"
                    class="mat-warn"
                    [matTooltip]="'DIALOG.DELETEMEDIA.TIPINUSE' | translate: {times: media.xdsResource.usedInContainerItemsCount}"
                    matTooltipPosition="right">warning
          </mat-icon>
          <!--<mat-icon matChipRemove>cancel</mat-icon>-->
          {{media.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="mediaItemSelectionManager.selectUnselectAll(mediasToRemove)">
        {{(mediaItemSelectionManager.isAllSelected(mediasToRemove | async) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="ConfirmClick()"
          [disabled]="!isAlldeletable()">
    {{'COMMON.OK' | translate}}
  </button>
  <button mat-button (click)="AbortClick()" cdkFocusInitial>
    {{'COMMON.CANCEL' | translate}}
  </button>
</div>
