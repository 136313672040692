<div>

  <h1 mat-dialog-title>{{'DIALOG.UPLOADMEDIA.TITLE' | translate}}</h1>

  <div mat-dialog-content
       (dragover)="prevent($event)"
       (dragleave)="onDragLeave($event)"
       (dragenter)="onDragEnter($event)"
       ng2FileDrop
       [uploader]="uploader"
       (onFileDrop)="onDrop($event)"
       class="dropzone"
       [ngClass]="isDragOver ? 'dragover' : ''">

    <div>
      <div *ngFor="let file of uploader.queue.filter(isNewFile)" class="preview">
        <img class="preview thumbnail" src="../../../../assets/images/processing.gif" appThumbnail
             [source]="file._file" [type]="file.file.type" [name]="file.file.name"/>
        <mat-icon class="remove pointer" (click)="removeFile(file)" color="accent">cancel</mat-icon>
      </div>
    </div>

    <button mat-button (click)="fileinput.click()">
      <mat-icon>add</mat-icon>
      {{((displaySize.isHandset | async) ? 'DIALOG.UPLOADMEDIA.CHOOSEPROMPT' : 'DIALOG.UPLOADMEDIA.DROPPROMPT') |
      translate}}
    </button>

    <input #fileinput mat-button type="file" name="files" accept="video/*,image/jpeg,image/gif,image/png"
           ng2FileSelect [uploader]="uploader" multiple/>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="ConfirmClick()"
            [disabled]="!uploader.getNotUploadedItems().length">
      {{'COMMON.OK'| translate}}
    </button>
    <button mat-button (click)="AbortClick()" cdkFocusInitial>{{'COMMON.CANCEL'| translate}}</button>
  </div>
</div>
