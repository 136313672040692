var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CardTableRowItem } from '../card-table-row-item';
import { DisplaySizeService } from '../../../../services/display-size/display-size.service';
import { XdsApiService } from '../../../../services/xds-api/xds-api.service';
import { InformationService } from '../../../../services/information/information.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { XdsResourceView } from '../../../../classes/xds-models/xds-resource-view';
import { SelectionManager } from '../../../../classes/selection-manager';
import { PermissionService } from '../../../../services/permission/permission.service';
var CardTableRowCiGroupComponent = /** @class */ (function (_super) {
    __extends(CardTableRowCiGroupComponent, _super);
    function CardTableRowCiGroupComponent(displaySize, api, infoService, translateService, permissionService) {
        return _super.call(this, displaySize, api, infoService, translateService, permissionService) || this;
    }
    CardTableRowCiGroupComponent.prototype.loadData = function () {
        var _this = this;
        this.api.ContainerItems.getItem(this.containerItem.xdsResource)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (item) {
            _this.item = new XdsResourceView(item, _this.permissionService);
        }, function (err) {
            console.log('Unable to load item for ContainerItem: ' + _this.containerItem.xdsResource.name);
            _this.infoService.error(err, _this.translateService.instant('ERROR.LOADINGITEMS'));
        });
    };
    return CardTableRowCiGroupComponent;
}(CardTableRowItem));
export { CardTableRowCiGroupComponent };
