import { DatePipe } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var DateService = /** @class */ (function () {
    function DateService(datePipe) {
        this.datePipe = datePipe;
        this.shortDateFormat = 'yyyy-MM-dd';
    }
    /**
     * Returns a 'yyyy-MM-dd representation of a given date.
     * @param {Date} date
     * @returns {string}
     */
    DateService.prototype.shortDate = function (date) {
        return this.datePipe.transform(date, this.shortDateFormat);
    };
    DateService.ngInjectableDef = i0.defineInjectable({ factory: function DateService_Factory() { return new DateService(i0.inject(i1.DatePipe)); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
