import {Observable} from 'rxjs';
import {XdsResource} from '../../../classes/xds-models/xds-resource';
import {HttpClient} from '@angular/common/http';
import {XdsApiUtilService} from '../xds-api-util.service';
import {map} from 'rxjs/operators';
import {PagedResources} from '../../../interfaces/xds-models/paged-resources';
import {XdsApiConfig} from '../../../interfaces/xds-models/xds-api-config';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {PagedSearchParams} from '../../../interfaces/search/paged-search-params';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {IPatchObj} from '../../../interfaces/patch-obj';

/**
 * basic class for apis containing the most important end points for all(!) possible resources
 */
export abstract class XdsResourceApiService<T extends XdsResource, S extends PagedSearchParams> {

  protected options: XdsApiConfig;

  /* Notes
   * Using http.request instead of http.get to be more flexible while response handling
   * and also choosing the method
   */

  private static createConfig(options: XdsApiConfigOptions): XdsApiConfig {
    return {
      get: {
        method: options.get && options.get.method ? options.get.method : 'GET',
        endpoint: options.get && options.get.endpoint ? options.get.endpoint : options.endpoint
      },
      search: {
        method: options.search && options.search.method ? options.search.method : 'GET',
        endpoint: options.search && options.search.endpoint ? options.search.endpoint : options.endpoint
      },
      delete: {
        method: options.delete && options.delete.method ? options.delete.method : 'DELETE',
        endpoint: options.delete && options.delete.endpoint ? options.delete.endpoint : options.endpoint
      },
      patch: {
        method: options.patch && options.patch.method ? options.patch.method : 'PATCH',
        endpoint: options.patch && options.patch.endpoint ? options.patch.endpoint : options.endpoint
      },
      batch: {
        method: 'POST',
        endpoint: `${options.endpoint}/batch/`
      }
    };
  }

  protected constructor(
    protected http: HttpClient,
    protected mapper: XdsApiModelMapperService,
    configOptions: XdsApiConfigOptions
  ) {
    this.options = XdsResourceApiService.createConfig(configOptions);
  }

  /**
   * find an item by id
   * @param id
   */
  public get(id: number): Observable<T> {
    return this.http.request<any>(
      this.options.get.method,
      XdsApiUtilService.createApiUrl(`${this.options.get.endpoint}/${id}`)
    )
      .pipe(
        map(res => this.mapResource(res)),
      );
  }

  /**
   * search for items based on passed search params
   * @param searchParams
   */
  public search(searchParams: S): Observable<PagedResources<T>> {
    const searchStr: string = XdsApiUtilService.buildQueryString(searchParams);

    return this.http.request<any>(
      this.options.search.method,
      XdsApiUtilService.createApiUrl(this.options.search.endpoint + searchStr)
    ).pipe(
      map(response => this.mapPagedResourceList<T>(response, x => this.mapResource(x)))
    );
  }

  /**
   * remove and item by id
   * @param item
   */
  public delete(item: T): Observable<any> {
    return this.http.request(
      this.options.delete.method,
      XdsApiUtilService.createApiUrl(`${this.options.delete.endpoint}/${item.id}`)
    );
  }

  /**
   * updates an item
   * @param item
   */
  public patch(item: T & IPatchObj): Observable<any> {
    return this.http.request(
      this.options.patch.method,
      XdsApiUtilService.createApiUrl(`${this.options.patch.endpoint}/${item.id}`),
      {
        body: item.getPatchObj()
      }
    );
  }

  /**
   * map a response to a paged resource list of a resource type
   * @param res
   * @param mapResourceFn
   */
  protected mapPagedResourceList<R extends XdsResource>(res: any, mapResourceFn: (res: any) => R): PagedResources<R> {
    return {
      page: res.page,
      items: this.mapResourceList<R>(res, mapResourceFn),
    };
  }

  /**
   * map a response to a list of a resource type
   * @param res
   * @param mapResourceFn
   * @param predicate
   */
  protected mapResourceList<R extends XdsResource>
  (res: any, mapResourceFn: (res: any) => R, predicate?: (res: any, resKey: string) => boolean): R[] {
    const resources = [];
    const embedded = res._embedded;

    if (embedded) {
      const resKeys = Object.keys(embedded);

      resKeys.forEach(resKey => {
        embedded[resKey].forEach(r => {
          if (typeof predicate === 'undefined' || predicate(r, resKey)) {
            r._type = resKey;
            resources.push(mapResourceFn(r));
          }
        });
      });
    }

    return resources;
  }

  /**
   * map a response to a resource
   * @param res
   */
  protected abstract mapResource(res: any): T;

  /**
   * do a batch request
   * @param type
   * @param payload
   */
  protected batch(type: string, payload: any): Observable<any> {
    return this.http.request(
      this.options.batch.method,
      XdsApiUtilService.createApiUrl(this.options.batch.endpoint + type),
      {body: payload}
    );
  }
}
