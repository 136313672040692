import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {XdsResourcePage} from '../../../classes/xds-resource-page';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {MediaGroupsSearch} from '../../../interfaces/search/media-groups.search';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {DateUtil} from '../../../classes/date-util';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {DomainService} from '../../../services/domain/domain.service';
import {InformationService} from '../../../services/information/information.service';
import {PAGINATOR_PARAMS} from '../../../const/mat-paginator.const';
import {Domain} from '../../../classes/xds-models/domain';
import {PermissionService} from '../../../services/permission/permission.service';
import {BroadcastService} from '../../../services/broadcast/broadcast.service';
import {SearchParamsData} from '../../../interfaces/search-params-data';

@Component({
  selector: 'app-media-group-list',
  templateUrl: './media-group-list.component.html',
  styleUrls: ['./media-group-list.component.sass']
})
export class MediaGroupListComponent extends XdsResourcePage<MediaGroup, MediaGroupsSearch> {
  public dateUtil = DateUtil;
  private sort = 'modified,desc';

  constructor(
    displaySize: DisplaySizeService,
    api: XdsApiService,
    router: Router,
    route: ActivatedRoute,
    titleBarService: TitleBarService,
    domainService: DomainService,
    infoService: InformationService,
    permissionService: PermissionService,
    broadcaster: BroadcastService,
    translate: TranslateService
  ) {
    super(displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
      mainApi: api.MediaGroups,
      title: translate.instant('COMMON.MEDIAGROUPS')
    });
  }

  public goTo(id: number) {
    this.router.navigate([id], {relativeTo: this.route});
  }

  protected createSearchParams(data: SearchParamsData): MediaGroupsSearch {
    const currentDomain: Domain = data[0];
    const params: ParamMap = data[1];

    const page: number = +params.get('page') || PAGINATOR_PARAMS.page;
    const domainId: number = currentDomain.id;

    return {
      page: page,
      _domain: domainId,
      sort: this.sort
    };
  }

}
