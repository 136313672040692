import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsNamedResource} from './xds-named-resource';
import {MEDIA} from '../../const/media.const';
import {Writable} from '../../interfaces/writable';
import {MEDIA_TYPES} from '../../const/media-types.enum';
import {Moment} from 'moment';
import {DateUtil} from '../date-util';
import {XdsApiUtilService} from '../../services/xds-api/xds-api-util.service';
import {MediaItemSource} from '../../interfaces/media-item-source';

export abstract class Media extends XdsNamedResource implements Writable {
  private _isWritable = false;

  private readonly _validFrom: Moment | null;
  private readonly _validTo: Moment | null;

  protected constructor(
    name: string,
    validFrom: string | Date | null,
    validTo: string | Date | null,
    private _type: MEDIA_TYPES,
    private _hashId: string,
    private _currentRevision: number,
    private _mimeType: string,
    private _usedInContainerItemsCount: number,
    private _network: string,
    links: ResourceLinks
  ) {
    super(name, links);
    this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
    this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
  }

  public get type(): MEDIA_TYPES {
    return this._type;
  }

  public get hashId(): string {
    return this._hashId;
  }

  public get isWritable(): boolean {
    return this._isWritable;
  }

  public get currentRevision(): number {
    return this._currentRevision;
  }

  public get mimeType(): string {
    return this._mimeType;
  }

  public get validFrom(): Moment | null {
    return this._validFrom;
  }

  public get validTo(): Moment | null {
    return this._validTo;
  }

  public get usedInContainerItemsCount(): number {
    return this._usedInContainerItemsCount;
  }

  public set isWritable(value: boolean) {
    this._isWritable = value;
  }

  public abstract previewDownload(): MediaItemSource[];

  public thumbnailDownload(): MediaItemSource {
    return this.createMediaItemSource(
      (mimeType, size) => this.mediaDownloadUrl(mimeType, size),
      MEDIA.mimeTypes.png,
      MEDIA.sizes.thumbnail
    );
  }

  public abstract get iconName(): string;

  protected mediaDownloadUrl(mimeType: string, size: number): string {
    return XdsApiUtilService.createApiUrl('' +
      'medias/bin/' +
      this.Network + '/' +
      this.hashId + '/' +
      this.currentRevision + '/' +
      mimeType + '/' +
      size + '/' +
      this.hashId + '.' + mimeType.split('/')[1]
    );
  }

  protected get Network(): string {
    return this._network;
  }

  protected createMediaItemSource(src: (mimeType: string, size: number) => string, mimeType: string, size: number): MediaItemSource {
    // this as a higher order allows us to set different types of soruces, in our case iframe links and media links
    return {
      src: src(mimeType, size),
      mimeType: mimeType,
      size: size,
    };
  }
}
