<h1 mat-dialog-title>{{ 'DIALOG.ADDMEDIATOGROUP.TITLE' | translate}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1">{{ 'DIALOG.ADDMEDIATOGROUP.HEADER' | translate}}</p>

  <mat-accordion>
    <!--selection of media items-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIA' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
              selected: (mediaItemSelectionManager.getItems() | async).length,
             count: (mediasToAdd | async).length
            } }}
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-chip-list #chipList [multiple]="true">
        <mat-chip *ngFor="let media of (mediasToAdd | async)"
                  [selected]="mediaItemSelectionManager.isSelected(media)"
                  (click)="mediaItemSelectionManager.change(media)"
                  class="pointer">
          {{media.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="mediaItemSelectionManager.selectUnselectAll(mediasToAdd)">
        {{(mediaItemSelectionManager.isAllSelected(mediasToAdd| async) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>

    <!--valid from/to-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.TIMESPAN' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{'COMMON.TIMESPANINFO' | translate}}</p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-timespan-picker [(dateFrom)]="validFromDate" [(dateTo)]="validToDate"></app-timespan-picker>
    </mat-expansion-panel>

    <!--selection of media groups-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIAGROUPS' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: (mediaGroupSelectionManager.getItems() | async).length,
            count: mediaGroups.length
            } }}</p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-chip-list #availableMGList [multiple]="true">
        <mat-chip *ngFor="let mg of mediaGroups"
                  [selected]="mediaGroupSelectionManager.isSelected(mg)"
                  (click)="mediaGroupSelectionManager.change(mg)"
                  class="pointer">
          {{mg.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button *ngIf="mediaGroups.length > 0"
              (click)="mediaGroupSelectionManager.selectUnselectAll(mediaGroups)">
        {{(mediaGroupSelectionManager.isAllSelected(mediaGroups) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="ConfirmClick()"
          [disabled]="mediaGroupSelectionManager.isNoneSelected() || mediaItemSelectionManager.isNoneSelected()">
    {{'COMMON.OK'| translate}}
  </button>
  <button mat-button (click)="AbortClick()" cdkFocusInitial>
    {{'COMMON.CANCEL' | translate}}
  </button>
</div>
