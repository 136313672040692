import { MatSnackBar } from '@angular/material';
import { NotificationErrorComponent } from '../../components/notifications/notification-error/notification-error.component';
import { NotificationInfoComponent } from '../../components/notifications/notification-info/notification-info.component';
import { NotificationSuccessComponent } from '../../components/notifications/notification-success/notification-success.component';
import { NotificationWarnComponent } from '../../components/notifications/notification-warn/notification-warn.component';
import { NotificationUploadCompleteComponent } from '../../components/notifications/notification-upload-complete/notification-upload-complete.component';
import { NotificationUploadCompleteOpenComponent } from '../../components/notifications/notification-upload-complete-open/notification-upload-complete-open.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export var InformationType;
(function (InformationType) {
    InformationType[InformationType["SUCCESS"] = 0] = "SUCCESS";
    InformationType[InformationType["INFO"] = 1] = "INFO";
    InformationType[InformationType["WARN"] = 2] = "WARN";
    InformationType[InformationType["ERROR"] = 3] = "ERROR";
    InformationType[InformationType["UPLOAD_COMPLETE"] = 4] = "UPLOAD_COMPLETE";
    InformationType[InformationType["UPLOAD_COMPLETE_CHANGE_PAGE"] = 5] = "UPLOAD_COMPLETE_CHANGE_PAGE";
})(InformationType || (InformationType = {}));
var InformationService = /** @class */ (function () {
    function InformationService(snackBar) {
        this.snackBar = snackBar;
        this._information = [];
        this.locked = false;
    }
    /**
     * Displays and logs an error message. User confirmation is enforced.
     * @param {Error} error An error or null, which will be only loged to the console.
     * @param args
     */
    InformationService.prototype.error = function (error) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        /*write full error messages only to console*/
        var message = args.join(' ');
        console.error(error, message);
        this.push({ type: InformationType.ERROR, message: message });
    };
    /**
     * Displays and logs a warning message. Disappers after a cosntant duration without user confiramtion.
     * @param args
     */
    InformationService.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var message = args.join(' ');
        console.warn(message);
        this.push({ type: InformationType.WARN, message: message });
    };
    /**
     * Displays and logs an info message. Disappers after a cosntant duration without user confiramtion.
     * @param args
     */
    InformationService.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var message = args.join(' ');
        console.log(message);
        this.push({ type: InformationType.INFO, message: message });
    };
    /**
     * Displays and logs a success message. Disappers after a cosntant duration without user confiramtion.
     * @param args
     */
    InformationService.prototype.success = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var message = args.join(' ');
        console.log(message);
        this.push({ type: InformationType.SUCCESS, message: message });
    };
    /**
     * Displays an upload completed message and enforces the user to reload the page.
     * @param args
     */
    InformationService.prototype.reloadPage = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var message = args.join(' ');
        this.push({ type: InformationType.UPLOAD_COMPLETE, message: message });
    };
    InformationService.prototype.goToMediaAfterUpload = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var message = args.join(' ');
        this.push({ type: InformationType.UPLOAD_COMPLETE_CHANGE_PAGE, message: message });
    };
    /**
     * Pauses processing of queued messages.
     */
    InformationService.prototype.lock = function () {
        this.locked = true;
    };
    /**
     * Resumses processing of queued messages.
     */
    InformationService.prototype.unlock = function () {
        this.locked = false;
        this.emit();
    };
    /**
     * Checks for identical messages in cache, so that only unique messages get displayed.
     * @param {Information} info
     * @returns {boolean}
     */
    InformationService.prototype.isInCache = function (info) {
        return this._information.find(function (i) { return i.type === info.type && i.message === info.message; }) !== undefined;
    };
    /**
     * Emits a message or queues it, when a message is already shown.
     * @param {Information} info
     */
    InformationService.prototype.push = function (info) {
        /*avoid multiple identical messages*/
        if (this.isInCache(info)) {
            return;
        }
        this._information.push(info);
        if (this._information.length === 1) {
            this.emit();
        }
    };
    /**
     * Removes the current message and shows the next queued.
     * This behaviour can be controlled via lock() and unlock().
     */
    InformationService.prototype.dismiss = function () {
        this._information.shift();
        if (!this.locked) {
            this.emit();
        }
    };
    /**
     * Shows the first message in the queue.
     */
    InformationService.prototype.emit = function () {
        if (this._information.length > 0) {
            var info = this._information[0];
            if (info) {
                this.openSnackBar(info);
            }
        }
    };
    /**
     * Opens the MatSnackBar for the provided information.
     * The actual MatSnackBar behaviour depends on the InformationType.
     * @param {Information} info The information to display.
     */
    InformationService.prototype.openSnackBar = function (info) {
        var _this = this;
        var component;
        var duration;
        switch (info.type) {
            case InformationType.UPLOAD_COMPLETE: {
                component = NotificationUploadCompleteComponent;
                break;
            }
            case InformationType.UPLOAD_COMPLETE_CHANGE_PAGE: {
                component = NotificationUploadCompleteOpenComponent;
                break;
            }
            case InformationType.ERROR: {
                component = NotificationErrorComponent;
                break;
            }
            case InformationType.SUCCESS: {
                component = NotificationSuccessComponent;
                duration = 3000;
                break;
            }
            case InformationType.WARN: {
                component = NotificationWarnComponent;
                duration = 5000;
                break;
            }
            case InformationType.INFO:
            default: {
                component = NotificationInfoComponent;
                duration = 3000;
                break;
            }
        }
        this.snackBarRef = this.snackBar.openFromComponent(component, {
            data: info.message,
            duration: duration
        });
        this.snackBarRef.instance.component = this.snackBarRef;
        var dismissedSubscription = this.snackBarRef.afterDismissed().subscribe(function (dismissed) { return _this.dismiss(); }, null, function () { return dismissedSubscription.unsubscribe(); });
    };
    InformationService.ngInjectableDef = i0.defineInjectable({ factory: function InformationService_Factory() { return new InformationService(i0.inject(i1.MatSnackBar)); }, token: InformationService, providedIn: "root" });
    return InformationService;
}());
export { InformationService };
