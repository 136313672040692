import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MediaDeleteDialogComponent} from '../media-delete-dialog/media-delete-dialog.component';
import {SelectionManager} from '../../../classes/selection-manager';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {Observable, Subject} from 'rxjs';
import {InformationService} from '../../../services/information/information.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-delete-container-item-from-media-group',
  templateUrl: './delete-container-item-from-media-group.component.html',
  styleUrls: ['./delete-container-item-from-media-group.component.sass']
})
export class DeleteContainerItemFromMediaGroupComponent implements OnInit, OnDestroy {
  public containerItemsToDelete: Observable<XdsResourceView<ContainerItem>[]>;
  public containerItemsSelectionManager = new SelectionManager<XdsResourceView<ContainerItem>>();
  public showBlockedInfo = false;

  private itemsWithoutDeletePermissionsCount = -1;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MediaDeleteDialogComponent>,
    public permissionService: PermissionService,
    private api: XdsApiService,
    private infoService: InformationService,
    private translateService: TranslateService,
  ) {

    this.containerItemsToDelete = data.items;
  }

  public ngOnInit() {

    this.containerItemsToDelete
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(containerItems => {
      containerItems.forEach(m => {
        this.permissionService.isDeletable(m.xdsResource)
          .pipe(
            takeUntil(this.destroy$)
          )
          .pipe(take(1))
          .subscribe(deletable => {
            if ( !deletable ) {
              this.showBlockedInfo = true;
            }
            this.containerItemsSelectionManager.select(this.containerItemsToDelete);
          });
      });
    });

    this.containerItemsSelectionManager.getItems()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(containerItems => {
        this.itemsWithoutDeletePermissionsCount = 0;
        containerItems.forEach(item => item.isWritable()
          .pipe(take(1))
          .subscribe(writable => {
            if (!writable) {
              this.itemsWithoutDeletePermissionsCount++;
            }
          })
        );
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {

    this.containerItemsSelectionManager.getItems()
      .pipe(
        take(1)
      )
      .subscribe(containerItems => {
          this.api.ContainerItems.deleteMany(containerItems.map(o => o.xdsResource))
            .subscribe(
              () => {
                this.infoService.info(this.translateService.instant('DIALOG.REMOVEFROMGROUP.SUCCESS'));
              },
              err => {
                this.infoService.error(err, this.translateService.instant('ERROR.DELETINGITEMS'));
              }
            );
        },
        () => {
        },
        () => this.dialogRef.close({dataChanged: true}));
  }

  public isAlldeletable(): boolean {
    return this.containerItemsSelectionManager.isAnySelected() && this.itemsWithoutDeletePermissionsCount === 0;
  }
}
