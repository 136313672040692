import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

@Component({
  selector: 'app-notification-upload-progress',
  templateUrl: './notification-upload-progress.component.html',
  styleUrls: ['./notification-upload-progress.component.sass']
})
export class NotificationUploadProgressComponent implements OnInit {


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  public ngOnInit() {
  }

}
