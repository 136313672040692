<app-responsive-toolbar>
  <mat-paginator #paginator
                 [hidePageSize]="paginatorParams.hidePageSize"
                 [length]="paginatorParams.items"
                 [pageSize]="paginatorParams.pageSize"
                 [pageSizeOptions]="paginatorParams.allowedPageSizes"
                 [pageIndex]="paginatorParams.page"
                 (page)="goToPage($event.pageIndex, $event.pageSize)"
                 class="toolbar-pagination">
  </mat-paginator>
</app-responsive-toolbar>

<app-content>
  <app-page-error-viewer [errorComponent]="viewError"></app-page-error-viewer>

  <app-card-table *ngIf="loaded && items !== null && items.length > 0">
    <app-card-table-header class="card-table-header">
      <app-card-table-row>
        <app-card-table-cell>{{ 'COMMON.NAME' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.WRITEABLE' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DESCRIPTION' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.TIMESPAN' | translate }}</app-card-table-cell>
      </app-card-table-row>
    </app-card-table-header>

    <app-card-table-body *ngIf="items !== null && items.length > 0">
      <app-card-table-row class="pointer" *ngFor="let mg of items" (click)="goTo(mg.xdsResource.id)">
        <app-card-table-cell class="app-card-table-highlighted">
          <div class="name">
            <mat-icon class="icon-fix" *ngIf="(displaySize.isHandset | async)"
                      [matTooltip]="(((mg.isWritable() | async) ? 'COMMON.WRITEABLE_IS_INFO' : 'COMMON.WRITEABLE_IS_NOT_INFO') | translate)">
              {{ mg.writableIcon() | async }}
            </mat-icon>
            {{mg.xdsResource.name}}
          </div>
        </app-card-table-cell>
        <app-card-table-cell class="app-card-table-highlighted" *ngIf="!(displaySize.isHandset | async)">
          <mat-icon
            [matTooltip]="(((mg.isWritable() | async) ? 'COMMON.WRITEABLE_IS_INFO' : 'COMMON.WRITEABLE_IS_NOT_INFO') | translate)">
            {{ mg.writableIcon() | async }}
          </mat-icon>
        </app-card-table-cell>
        <app-card-table-cell>
          <pre class="mat-body-1">{{mg.xdsResource.description}}</pre>
        </app-card-table-cell>
        <app-card-table-cell>
          <table>
            <tr>
              <td>{{'TIMESPAN.FROM' | translate}}:</td>
              <td>
                <div>
                  {{ dateUtil.formatMoment(mg.xdsResource.validFrom)}}
                  <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}"
                            *ngIf="dateUtil.isInFuture(mg.xdsResource.validFrom)">warning
                  </mat-icon>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{'TIMESPAN.UNTIL' | translate}}:</td>
              <td>
                <div>
                  {{ dateUtil.formatMoment(mg.xdsResource.validTo)}}
                  <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}"
                            *ngIf="dateUtil.isInFuture(mg.xdsResource.validTo)">warning
                  </mat-icon>
                </div>
              </td>
            </tr>
          </table>
        </app-card-table-cell>
      </app-card-table-row>
    </app-card-table-body>
  </app-card-table>
</app-content>
