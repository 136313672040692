import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Domain} from '../../classes/xds-models/domain';
import {XdsApiService} from '../xds-api/xds-api.service';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTING_CONST} from '../../const/routing.const';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private currentDomain$: BehaviorSubject<Domain> = new BehaviorSubject(null);
  private allDomains$: BehaviorSubject<Domain[]> = new BehaviorSubject(null);
  private topLevelDomain$: BehaviorSubject<Domain> = new BehaviorSubject(null);
  private childDomains$: BehaviorSubject<Domain[]> = new BehaviorSubject(null);

  constructor(
    private api: XdsApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.auth.domainId
      .pipe(switchMap(domainId => this.api.Domains.get(domainId)))
      .pipe(switchMap(tld => {
        this.topLevelDomain$.next(tld);
        return this.getTopLevelDomain();
      }))
      .pipe(switchMap(tld => {
        return this.api.Domains.getChildrenOnlyDomains(tld);
      }))
      .subscribe(
        children => {
          this.childDomains$.next(children);
          this.triggerAllDomains([this.topLevelDomain$.getValue()].concat(children));
          this.initialCurrentDomain(this.topLevelDomain$.getValue());
        }
      );
  }

  public getAllDomains(): Observable<Domain[]> {
    return this.allDomains$.asObservable()
      .pipe(filter(val => val !== null));
  }

  public getCurrentDomain(): Observable<Domain> {
    return this.currentDomain$.asObservable()
      .pipe(filter(val => val !== null));
  }

  public getCurrentDomainName(): Observable<string> {
    return this.getCurrentDomain()
      .pipe(map(currentDomain => currentDomain.fullyQualifiedName));
  }

  public getTopLevelDomain(): Observable<Domain> {
    return this.topLevelDomain$.asObservable()
      .pipe(filter(val => val !== null));
  }

  public getChildDomains(): Observable<Domain[]> {
    return this.childDomains$.asObservable()
      .pipe(filter(val => val !== null));
  }

  public currentDomainIsTld(): Observable<boolean> {
    return combineLatest<Domain, Domain>(this.getCurrentDomain(), this.getTopLevelDomain())
      .pipe(map(domains => {
        const currentDomain: Domain = domains[0];
        const tld: Domain = domains[1];

        return currentDomain.id === tld.id;
      }));
  }

  // wrapper cause the trigger function should be private
  public updateCurrentDomain(domain: Domain): void {
    this.triggerCurrentDomain(domain);
    this.redirectAfterChange(domain.id);
  }

  private initialCurrentDomain(tld): void {
    this.route.root.firstChild.firstChild.paramMap
      .pipe(
        take(1),
        map(paramMap => {
          return paramMap.get('domainId');
        }),
        switchMap(urlDomainId => this.findDomainById(+urlDomainId)),
        map(searchResult => {
          if (searchResult) {
            return searchResult;
          } else {
            return tld;
          }
        })
      )
      .subscribe(domain => {
        this.triggerCurrentDomain(domain);
      });
  }

  private findDomainById(id: number): Observable<Domain> {
    return this.getAllDomains()
      .pipe(
        take(1),
        map(domains => {
          for (let i = 0; i < domains.length; i++) {
            if (domains[i].id === id) {
              return domains[i];
            }
          }

          this.router.navigate([ROUTING_CONST.pages.landing]);
          return null;
        }));
  }

  private triggerCurrentDomain(domain: Domain): void {
    this.currentDomain$.next(domain);
  }

  private triggerAllDomains(domains: Domain[]): void {
    this.allDomains$.next(domains);
  }

  private redirectAfterChange(targetDomainId: number) {
    const urlFragments = this.router.url.split('/');
    const possibleDomainIdFragment = urlFragments[2]; // only urlFragments[2] can contain domain information
                                                      // 0: ""
                                                      // 1: "bcn"
                                                      // 2: "{domainId}" or "{page without domainId}"
                                                      // 3: "{page with domainId}"
                                                      // 4: "{itemId}"

    let redirectTarget: string[];

    if (typeof possibleDomainIdFragment !== 'undefined' && possibleDomainIdFragment.length > 0 && !isNaN(+possibleDomainIdFragment)) {
      redirectTarget = urlFragments.slice(0, 4);
      redirectTarget[2] = targetDomainId + '';

      const viewFragment = redirectTarget[3];
      const queryStartIndex = viewFragment.indexOf('?');

      if (queryStartIndex >= 0) {
        redirectTarget[3] = redirectTarget[3].slice(0, queryStartIndex);
      }

    } else if (typeof possibleDomainIdFragment !== 'undefined' && possibleDomainIdFragment.length > 0) {
      // urlFragments[2] is not an id
      redirectTarget = urlFragments; // its not a domain based route we can stay here
    } else {
      // i don't know where or what you're... i will redirect you to your landing page. have fun.
      redirectTarget = [ROUTING_CONST.pages.landing];
    }

    this.router.navigate(redirectTarget);
  }
}
