import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsNamedResource} from './xds-named-resource';

export class Container extends XdsNamedResource {

  constructor(
    name: string,
    links: ResourceLinks
  ) {
    super(name, links);
  }
}
