/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-error.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./notification-error.component";
import * as i11 from "@angular/material/snack-bar";
var styles_NotificationErrorComponent = [i0.styles];
var RenderType_NotificationErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationErrorComponent, data: {} });
export { RenderType_NotificationErrorComponent as RenderType_NotificationErrorComponent };
export function View_NotificationErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon-fix mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["error"])), (_l()(), i1.ɵted(3, null, ["\n", "\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["color", "warn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "warn"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 3, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("COMMON.OK")); _ck(_v, 6, 0, currVal_5); }); }
export function View_NotificationErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-error", [], null, null, null, View_NotificationErrorComponent_0, RenderType_NotificationErrorComponent)), i1.ɵdid(1, 114688, null, 0, i10.NotificationErrorComponent, [i11.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationErrorComponentNgFactory = i1.ɵccf("app-notification-error", i10.NotificationErrorComponent, View_NotificationErrorComponent_Host_0, {}, {}, []);
export { NotificationErrorComponentNgFactory as NotificationErrorComponentNgFactory };
