import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
/**
 * This class encapsulates a title bar with its main properties as title and title visiblity.
 */
export class TitleBarService {
  private isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  private isHandset: boolean;

  private title$: BehaviorSubject<string> = new BehaviorSubject('');
  private titleIsVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private defaultTitle = 'BEECEEN - Digital signage made easy';

  constructor(private breakpointObserver: BreakpointObserver,
              private titleService: Title) {
    this.setTitle(this.defaultTitle);

    this.isHandset$.subscribe(x => this.isHandset = x);
  }

  /**
   * Gets the current title.
   * @returns {Observable<string>}
   * @constructor
   */
  public get Title(): Observable<string> {
    return this.title$.asObservable();
  }

  /**
   * Sets the current title. If no input is provided only the default title is shown.
   * @param {string} title
   */
  public setTitle(title: string) {
    let t: string;
    if (title) {
      t = this.defaultTitle + ' - ' + title;
      this.title$.next(title);
      this.titleService.setTitle(t);
    } else {
      t = this.defaultTitle;
      this.title$.next(t);
      this.titleService.setTitle(t);
    }
  }

  /**
   * Allows tracking of the current title visiblity, which may be hidden on handset devices.
   * Use hideTitle() or restoreTitle() to change title visiblity.
   * @returns {BehaviorSubject<boolean>}
   * @constructor
   */
  public get TitleVisibility(): BehaviorSubject<boolean> {
    return this.titleIsVisible$;
  }

  /**
   * Hides the title for handset size devices.
   */
  public hideTitle(): void {
    if (this.isHandset) {
      this.titleIsVisible$.next(false);
    }
  }

  /**
   * Makes the title visible for handset size devices.
   */
  public restoreTitle(): void {
    if (this.isHandset) {
      this.titleIsVisible$.next(true);
    }
  }
}
