var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResourceApiService } from '../resource/xds-resource-api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { XdsApiModelMapperService } from '../xds-api-model-mapper.service';
import { XdsApiUtilService } from '../xds-api-util.service';
import { AuthService } from "../../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../auth/auth.service";
var XdsMediasApiService = /** @class */ (function (_super) {
    __extends(XdsMediasApiService, _super);
    function XdsMediasApiService(mapper, http, auth) {
        var _this = _super.call(this, http, mapper, XdsMediasApiService.configOptions) || this;
        _this.auth = auth;
        _this.initialize();
        return _this;
    }
    XdsMediasApiService.prototype.initialize = function () {
        var _this = this;
        this.auth.network.subscribe(function (network) {
            if (network.limitedContentView) {
                _this.options.search.endpoint = 'medias/search/findByAllParamsForDomainWithoutParents';
            }
        });
    };
    XdsMediasApiService.prototype.getByContainerItem = function (container) {
        var _this = this;
        return this.http.request('GET', container.links.item.href).pipe(map(function (res) { return _this.mapResource(res); }));
    };
    XdsMediasApiService.prototype.getMediaFilesForMedia = function (media) {
        var _this = this;
        return this.http.request('GET', media.links.mediaFiles.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapper.mapMediaFile(x); }); }));
    };
    XdsMediasApiService.prototype.getDomainsForMedia = function (media) {
        var _this = this;
        return this.http.request('GET', media.links.domains.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapper.mapDomain(x); }); }));
    };
    XdsMediasApiService.prototype.addTo = function (medias, containers, domains, playoutParams) {
        var payload = {
            type: 'addTo',
            containers: XdsApiUtilService.resourceListToStrIdList(containers),
            domains: XdsApiUtilService.resourceListToStrIdList(domains),
            items: XdsApiUtilService.resourceListToStrIdList(medias),
            playoutParams: playoutParams
        };
        return this.batch('addTo', payload);
    };
    XdsMediasApiService.prototype.deleteMany = function (medias) {
        var payload = {
            type: 'delete',
            items: XdsApiUtilService.resourceListToStrIdList(medias)
        };
        return this.batch('delete', payload);
    };
    XdsMediasApiService.prototype.mapResource = function (res) {
        return this.mapper.mapMedia(res);
    };
    XdsMediasApiService.configOptions = {
        endpoint: 'medias',
        search: {
            endpoint: 'medias/search/findByAllParamsForDomain'
        }
    };
    XdsMediasApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsMediasApiService_Factory() { return new XdsMediasApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient), i0.inject(i3.AuthService)); }, token: XdsMediasApiService, providedIn: "root" });
    return XdsMediasApiService;
}(XdsResourceApiService));
export { XdsMediasApiService };
