import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

enum MediaType {
  'IMAGE',
  'VIDEO',
  'UNKNOWN'
}

// generates thumbnails for upload dialog

@Directive({
  selector: 'img[appThumbnail]'
})

export class ThumbnailDirective implements OnChanges {

  @Input()
  public source: any;
  @Input()
  public type: string;
  @Input()
  public name: string;

  private maxSize = 192;
  private fileNameBarHeight = 25;
  private fileNameBarPadding = 5;
  private canvas: HTMLCanvasElement = document.createElement('canvas');
  private reader: FileReader = new FileReader();

  constructor(private el: ElementRef) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.reader.onloadend = (readerEvent) => {
      if (this.getType() === MediaType.IMAGE) {
        this.generateImageThumbnail();
      } else if (this.getType() === MediaType.VIDEO) {
        this.generateVideoThumbnail();
      } else {
        this.generateUnknownThumbnail();
      }
    };

    if (this.source) {
      this.reader.readAsArrayBuffer(this.source);
    }
  }

  private getType(): MediaType {
    if (this.type.startsWith('image/')) {
      return MediaType.IMAGE;
    } else if (this.type.startsWith('video/')) {
      return MediaType.VIDEO;
    } else if (this.type === 'text/vnd.trolltech.linguist') {
      // .ts videos might get recognized incorrectly
      // see https://bugs.launchpad.net/ubuntu/+source/shared-mime-info/+bug/502642
      return MediaType.VIDEO;
    }

    return MediaType.UNKNOWN;
  }

  private generateImageThumbnail(): void {
    const image = new Image();
    image.onload = (imageEvent) => {
      this.setCanvasSize(image.width, image.height);
      this.drawElement(image);
      this.writeFileName();
      this.el.nativeElement.src = this.canvas.toDataURL('image/png');
    };
    image.onerror = (err: any) => {
      this.generateUnknownThumbnail();
    };
    image.src = URL.createObjectURL(this.source);
  }

  private generateVideoThumbnail(): void {
    const video: HTMLVideoElement = document.createElement('video');
    video.src = URL.createObjectURL(this.source);
    video.onloadeddata = () => {
      video.currentTime = video.duration / 2;
    };
    video.onseeked = () => {
      this.setCanvasSize(video.videoWidth, video.videoHeight);
      this.drawElement(video);
      this.writeFileName();
      this.el.nativeElement.src = this.canvas.toDataURL('image/png');
    };
    video.onerror = (err: any) => {
      this.generateUnknownThumbnail();
    };
    if (navigator.userAgent && (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i))) {
      // FIXME it seems loadeddata or canplay is never triggered on iOS - just write the file name onto thumbnail
      video.onloadedmetadata = () => {
        this.setCanvasSize(video.videoWidth, video.videoHeight);
        this.writeFileName();
        this.el.nativeElement.src = this.canvas.toDataURL('image/png');
      };
    }
  }

  private generateUnknownThumbnail() {
    this.setCanvasSize(this.maxSize, this.maxSize / 3);
    const y = (this.canvas.height - this.fileNameBarPadding) / 2;
    const x = this.canvas.width / 2;
    const ctx = this.canvas.getContext('2d');
    ctx.font = '36px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText('?', x, y);
    this.writeFileName();
    this.el.nativeElement.src = this.canvas.toDataURL('image/png');
  }

  private setCanvasSize(width: number, height: number): void {
    let _width = width;
    let _height = height;
    if (_width > _height) {
      if (_width > this.maxSize) {
        _height *= this.maxSize / _width;
        _width = this.maxSize;
      }
    } else {
      if (_height > this.maxSize) {
        _width *= this.maxSize / _height;
        _height = this.maxSize;
      }
    }

    this.canvas.width = _width;
    this.canvas.height = _height + this.fileNameBarHeight;
  }

  private drawElement(element: HTMLImageElement | HTMLVideoElement): void {
    let height = 0, width = 0;
    if (element instanceof HTMLImageElement) {
      height = element.height;
      width = element.width;
    } else if (element instanceof HTMLVideoElement) {
      height = element.videoHeight;
      width = element.videoWidth;
    }

    const ctx = this.canvas.getContext('2d');
    // ctx.fillStyle = 'gold';
    // ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.drawImage(element,
      0, 0, width, height,
      0, 0, this.canvas.width, this.canvas.height - this.fileNameBarHeight);
  }
  private writeFileName(): void {
    const y = this.canvas.height - 10;
    const x = this.fileNameBarPadding;
    const ctx = this.canvas.getContext('2d');
    ctx.font = '12px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'left';
    ctx.fillText(this.name, x, y);
  }
}
