import {Injectable} from '@angular/core';
import {XdsApiConfigOptions} from "../../../interfaces/xds-models/xds-api-config-options";
import {XdsApiModelMapperService} from "../xds-api-model-mapper.service";
import {HttpClient} from "@angular/common/http";
import {XdsResourceApiService} from "../resource/xds-resource-api.service";
import {PlayerInventory} from "../../../classes/xds-models/player-inventory";
import {PlayerInventoriesSearch} from "../../../interfaces/search/player-inventories.search";
import {Observable, of, throwError} from "rxjs";
import {XdsApiUtilService} from "../xds-api-util.service";
import {catchError, map} from "rxjs/operators";
import {Media} from "../../../classes/xds-models/media";
import {MediaGroup} from "../../../classes/xds-models/media-group";
import {MEDIA_TYPES} from "../../../const/media-types.enum";
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class XdsPlayerInventoriesApiService extends XdsResourceApiService<PlayerInventory, PlayerInventoriesSearch> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'players',
    search: {
      endpoint: 'playerInventories/search/advanced'
    }
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient,
    private auth: AuthService,
  ) {
    super(http, mapper, XdsPlayerInventoriesApiService.configOptions);
  }

  public getItem<T extends (Media | MediaGroup)>(playerInventory: PlayerInventory): Observable<Media> {
    return this.http.request<any>(
      'GET',
      XdsApiUtilService.createApiUrl('medias/' + playerInventory.inventoryItem.itemId)
    ).pipe(
      catchError((err: any, caught: Observable<{}>) => {
        console.log('Could not load media with id ' + playerInventory.inventoryItem.itemId + ', deleted?', err);
        if (err.status === 404) {
          return of(this.mapper.mapMedia({
            name: playerInventory.inventoryItem.itemName,
            validFrom: playerInventory.inventoryItem.validFrom,
            validTo: playerInventory.inventoryItem.validTo,
            type: MEDIA_TYPES.Image,
            hashId: 'deleted',
            _links: {self: {href: ''}, domains: {href: ''}}
          }));
        } else {
          throwError(err);
        }
      }),
      map((res: any) => {
        return this.mapper.mapMedia(res);
      })
    );
  }

  protected mapResource(res: any): PlayerInventory {
    return this.mapper.mapPlayerInventory(res);
  }
}
