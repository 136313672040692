import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainService } from '../../../services/domain/domain.service';
import { DisplaySizeService } from '../../../services/display-size/display-size.service';
import { TitleBarService } from '../../../services/title-bar/title-bar.service';
import { InformationService } from '../../../services/information/information.service';
import { TranslateService } from '@ngx-translate/core';
var DomainSelectorComponent = /** @class */ (function () {
    function DomainSelectorComponent(domainService, displaySizeService, titleBarService, infoService, translateService) {
        this.domainService = domainService;
        this.displaySizeService = displaySizeService;
        this.titleBarService = titleBarService;
        this.infoService = infoService;
        this.translateService = translateService;
        this.domains = [];
        this.destroy$ = new Subject();
    }
    DomainSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        var displaySizeSubscription = this.displaySizeService.isHandset
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (isHandset) { return _this.showSearch = !isHandset; }, function (err) { return _this.infoService.error(err, _this.translateService.instant('ERROR.GETDISPLAYSIZE')); }, function () { return displaySizeSubscription.unsubscribe(); });
        this.domainService.getAllDomains()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (domains) {
            _this.domains = domains;
        });
        this.domainService.getCurrentDomain()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (domain) {
            _this.currentDomain = domain;
        });
    };
    DomainSelectorComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    DomainSelectorComponent.prototype.changeDomain = function (event) {
        this.domainService.updateCurrentDomain(event.value);
        // this.router.navigate([ROUTING_CONST.pages.landing]);
    };
    Object.defineProperty(DomainSelectorComponent.prototype, "showSearchBarSwitch", {
        get: function () {
            if (this.domains && this.domains.length === 1) {
                return false;
            }
            return !this.showSearch;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DomainSelectorComponent.prototype, "showSearchBar", {
        get: function () {
            if (this.domains && this.domains.length === 1) {
                return false;
            }
            return this.showSearch;
        },
        enumerable: true,
        configurable: true
    });
    DomainSelectorComponent.prototype.toggle = function () {
        this.showSearch = !this.showSearch;
        if (this.showSearchBar) {
            this.titleBarService.hideTitle();
        }
        if (!this.showSearchBar) {
            this.titleBarService.restoreTitle();
        }
    };
    return DomainSelectorComponent;
}());
export { DomainSelectorComponent };
