import {Component, OnInit} from '@angular/core';
import {IXdsVersions} from '../../../interfaces/xds-models/xds-versions';
import {environment} from '../../../../environments/environment';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  public backendVersions: IXdsVersions;
  public frontendVersion: {version: string} = environment.version;

  constructor(
    private api: XdsApiService
  ) {
  }

  public ngOnInit() {
    this.api.getVersion()
      .subscribe(
        versions => this.backendVersions = versions
      );
  }

}
