import {Moment} from 'moment';
import * as moment from 'moment';
import {MOMENTJS} from '../const/momentjs.const';

export class DateUtil {
  private static TODAY: Moment = moment(Date.now());

  public static createValidDate(date: string | Date): Moment {
    if (typeof date === 'string') {
      return moment(date, MOMENTJS.validDate);
    } else {
      return moment(date);
    }
  }

  public static formatMoment(momentObj: Moment): string {
    if (momentObj) {
      return momentObj.format('DD. MMMM YYYY');
    } else {
      return '-';
    }
  }

  public static isInFuture(momentObj: Moment): boolean {
    if (!momentObj) {
      return false;
    }

    return momentObj.isAfter(this.TODAY);
  }

  public static isInPast(momentObj: Moment): boolean {
    if (!momentObj) {
      return false;
    }

    return momentObj.isBefore(this.TODAY);
  }
}
