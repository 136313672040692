import {Component, OnInit, OnDestroy} from '@angular/core';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Player} from '../../../classes/xds-models/player';
import {PlayerStatus} from '../../../classes/xds-models/player-status';
import {DomainService} from '../../../services/domain/domain.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {InformationService} from '../../../services/information/information.service';
import {combineLatest, Subject} from 'rxjs';
import {Domain} from '../../../classes/xds-models/domain';
import {ComponentType} from '@angular/cdk/portal';
import {PageErrorComponent} from '../../../classes/page-error/page-error-component';
import {PageErrorUtil} from '../../../classes/page-error/page-error-util';
import {TranslateService} from '@ngx-translate/core';
import {PageErrorEmptyListComponent} from '../../shared/errors/page-errors/page-error-empty-list/page-error-empty-list.component';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';

interface PlayerWithStatus {
  player: Player;
  status: PlayerStatus;
}

/* This page has currently no pagination
 * currently we have no search request like 'findPlayersForDomain', which returns a paged list
 */

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.sass']
})
export class PlayerListComponent implements OnInit, OnDestroy {

  public players: PlayerWithStatus[] = [];
  public loaded = false;
  public viewError: ComponentType<PageErrorComponent> = null;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public displaySize: DisplaySizeService,
    private api: XdsApiService,
    private router: Router,
    private route: ActivatedRoute,
    private domains: DomainService,
    private titleBarService: TitleBarService,
    private infoService: InformationService,
    private translate: TranslateService
  ) {
  }

  public ngOnInit() {
    this.titleBarService.setTitle(this.translate.instant('COMMON.PLAYERS'));
    this.search();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private search(): void {
    this.domains.getCurrentDomain().pipe(
      takeUntil(this.destroy$),
      switchMap(domain => this.api.Domains.getChildrenOnlyPlayers(domain))
    ).subscribe(players => {
        this.viewError = null;
        this.updatePlayerList(players);
      },
      (err) => {
        this.translate.get('ERROR.SWW').subscribe(
          res => this.infoService.error(err, res.TITLE)
        );
        this.viewError = PageErrorUtil.parse(err);
      });
  }

  private updatePlayerList(players: Player[]): void {
    const newPlayerList: PlayerWithStatus[] = [];

    players.forEach(player => {
      const playerWithStatus: PlayerWithStatus = {
        player: player,
        status: undefined,
      };

      this.api.Players.getStatus(player).subscribe(
        playerStatus => {
          playerWithStatus.status = playerStatus;
        },
      );

      newPlayerList.push(playerWithStatus);
    });
    this.players = newPlayerList;
    this.loaded = true;

    if (this.players.length === 0) {
      this.infoService.warn(this.translate.instant('PLAYER.NOPLAYERSFOUND'));
      this.viewError = PageErrorEmptyListComponent;
    }
  }

  public goTo(player: PlayerWithStatus) {
    if (!player.status) {
      this.infoService.warn(this.translate.instant('PLAYER.NOTCONNECTED'));
      return;
    }
    this.router.navigate([player.player.id], {
      relativeTo: this.route, queryParams: {
        status: player.status.id
      }
    });
  }
}
