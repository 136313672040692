var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResource } from './xds-resource';
var XdsNamedResource = /** @class */ (function (_super) {
    __extends(XdsNamedResource, _super);
    function XdsNamedResource(_name, links) {
        var _this = _super.call(this, links) || this;
        _this._name = _name;
        return _this;
    }
    Object.defineProperty(XdsNamedResource.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    return XdsNamedResource;
}(XdsResource));
export { XdsNamedResource };
