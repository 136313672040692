/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./help.component";
import * as i9 from "../../../services/auth/auth.service";
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
export { RenderType_HelpComponent as RenderType_HelpComponent };
function View_HelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "pointer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["library_books"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " (English, PDF) "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("../../../assets/themes/" + _co.userManualVariant) + "/") + _co.userManualName_EN); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("COMMON.MANUAL")); _ck(_v, 9, 0, currVal_2); }); }
function View_HelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "pointer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["library_books"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " (Deutsch, PDF) "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("../../../assets/themes/" + _co.userManualVariant) + "/") + _co.userManualName_DE); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("COMMON.MANUAL")); _ck(_v, 9, 0, currVal_2); }); }
export function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "page-links"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userManualName_EN; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.userManualName_DE; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i8.HelpComponent, [i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpComponentNgFactory = i1.ɵccf("app-help", i8.HelpComponent, View_HelpComponent_Host_0, {}, {}, []);
export { HelpComponentNgFactory as HelpComponentNgFactory };
