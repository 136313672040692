import {Injectable} from '@angular/core';
import {InformationService} from '../information/information.service';
import {FileItem, FileLikeObject, FileUploader} from 'ng2-file-upload';
import {XdsApiUtilService} from '../xds-api/xds-api-util.service';
import {DomainService} from '../domain/domain.service';
import {NotificationUploadProgressComponent} from '../../components/notifications/notification-upload-progress/notification-upload-progress.component';
import {MatSnackBar} from '@angular/material';
import {BehaviorSubject, interval, Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BroadcastService} from '../broadcast/broadcast.service';
import {KeycloakService} from "keycloak-angular";

const mimeTypes = {
  images: [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png'
  ],
  videos: [
    'video/avi',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/quicktime',
    'video/vnd.dlna.mpeg-tts',
    'video/webm',
    'video/x-m4v',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/3gpp',
    'text/vnd.trolltech.linguist', // .ts hack https://bugs.launchpad.net/ubuntu/+source/shared-mime-info/+bug/502642
    'video/x-ms-asf',
    'video/x-la-asf',
    'video/x-sgi-movie',
    'video/3gpp2',
    'video/mp2t',
    'video/x-flv',
    'video/vnd.vivo',
  ]
};

@Injectable({
  providedIn: 'root'
})
/**
 * This class is a wrapper class for FileUploader and provides an easy to use interface for uploading files.
 * Progress, error and success messages are forwarded to InformationService. Only certain mimeTypes are
 * allowed as input.
 */
export class UploadService {
  private readonly _uploader: FileUploader;
  private _snackBarRef: any;
  private interval = interval(100);
  private maxFileSize = 5 * 1024 * 1024 * 1024;
  private allowedMimeTypes = [].concat(mimeTypes.images).concat(mimeTypes.videos);

  private progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private current$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private domainService: DomainService,
    private infoService: InformationService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router,
    private broadcaster: BroadcastService,
    private keycloak: KeycloakService
  ) {
    this._uploader = new FileUploader({
      url: XdsApiUtilService.createApiUrl('medias/bin'),
      maxFileSize: this.maxFileSize,
      allowedMimeType: this.allowedMimeTypes
    });

    // The upload path depends on the current domain
    this.domainService.getCurrentDomain()
      .subscribe(currentDomain => {
        this.keycloak.getToken().then((token: string) => {
          this._uploader.setOptions({
            url: XdsApiUtilService.createApiUrl(`medias/bin?domainId=${currentDomain.id}&access_token=${token}`)}
          );
        });
      });

    this._uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
    };

    this._uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('file uploaded', item);
    };

    this._uploader.onCompleteAll = () => {
      this.destroy$.next(true);
      this.infoService.unlock();
      this.clearList();

      // handle different situations if upload complete
      this.domainService.getCurrentDomain()
        .pipe(take(1))
        .subscribe(currentDomain => {
          const targetUrlTree = this.router.parseUrl('/bcn/' + currentDomain.id + '/medias');
          const currentUrlTree = this.router.parseUrl(this.router.url);
          const currentPage = currentUrlTree.queryParamMap.get('page');
          const isMediaState = this.router.isActive(targetUrlTree, false);

          if (isMediaState && currentPage === null || currentPage === '0') {
            this.broadcaster.emitReload();
          } else if (isMediaState) {
            this.infoService.reloadPage(this.translateService.instant('DIALOG.UPLOADMEDIA.UPLOADCOMPLETED'));
          } else {
            this.infoService.goToMediaAfterUpload(this.translateService.instant('DIALOG.UPLOADMEDIA.UPLOADCOMPLETED'));
          }
        });
    };

    this._uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      switch (filter.name) {
        case 'fileSize': {
          this.translateService.get(
            'DIALOG.UPLOADMEDIA.ERRORSIZE',
            {
              size: item.size,
              maxSize: this.maxFileSize
            })
            .subscribe(text => this.infoService.error(null, text)
            );
          break;
        }
        case 'mimeType': {
          const allowedTypes = this.allowedMimeTypes.join();
          this.translateService.get(
            'DIALOG.UPLOADMEDIA.ERRORTYPE',
            {
              type: item.type,
              allowedTypes: this.allowedMimeTypes.map(o => o
                .replace('image/', ' ')
                .replace('video/', ' ')
                .replace('text/', ' ')
              )
            })
            .subscribe(text => this.infoService.error(null, text)
            );
          break;
        }
        default: {
          console.error(`Unknown error (filter is ${filter.name})`);
          this.infoService.error(null, this.translateService.instant('ERROR.UPLOADINGITEMS'));
        }
      }
    };
  }

  /**
   * Starts the upload process of all enqueued files. The progress is displayed via Material SnackBar.
   */
  public beginUpload(): void {
    this.infoService.lock();
    this.uploader.uploadAll();
    this.interval
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const progress = this._uploader.progress;
        const total = this._uploader.queue.length;
        const current = 1 + total - this._uploader.getNotUploadedItems().length;

        this.progress$.next(progress);
        this.total$.next(total);
        this.current$.next(current);

        if (progress === 100) {
          this.snackBar.dismiss();
        }
      });

    // material guidelines allow only one snackbar at one
    // of you open another snackbar while one is open, the open one gets automatically closed
    // we propably want to implement some sort of locking mechanism here
    this._snackBarRef = this.snackBar.openFromComponent(NotificationUploadProgressComponent, {
      // duration: 5000,
      data: {
        progress: this.progress,
        current: this.current,
        total: this.total,
      }
    });
  }

  /**
   * Removes all files from the uploader queue.
   */
  public clearList(): void {
    this._uploader.clearQueue();
  }

  /**
   * Removes a file from the uploader queue.
   * @param {FileItem} file
   */
  public removeFile(file: FileItem) {
    const pos = this._uploader.queue.indexOf(file);
    if (pos > -1) {
      this._uploader.removeFromQueue(file);
    }
  }

  /**
   * Returns the underlying FileUploader.
   * @returns {FileUploader}
   */
  public get uploader(): FileUploader {
    return this._uploader;
  }

  /**
   * Tracks the uploading progress
   * @returns {Observable<number>}
   */
  public get progress(): Observable<number> {
    return this.progress$.asObservable();
  }

  /**
   * Tracks the current processed file index.
   * @returns {Observable<number>}
   */
  public get current(): Observable<number> {
    return this.current$.asObservable();
  }

  /**
   * Tracks the total number of files to uplaod.
   * @returns {Observable<number>}
   */
  public get total(): Observable<number> {
    return this.total$.asObservable();
  }
}
