import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

@Component({
  selector: 'app-notification-error',
  templateUrl: './notification-error.component.html',
  styleUrls: ['./notification-error.component.sass']
})
export class NotificationErrorComponent implements OnInit {
  public component: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }


  public ngOnInit() {
  }

  public dismiss() {
    this.component.dismiss();
  }

}
