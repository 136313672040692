/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon-image.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./icon-image.component";
var styles_IconImageComponent = [i0.styles];
var RenderType_IconImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconImageComponent, data: {} });
export { RenderType_IconImageComponent as RenderType_IconImageComponent };
export function View_IconImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "div", [["class", "media-group-icon"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(4, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.MEDIAGROUP")); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 5, 0, currVal_2); }); }
export function View_IconImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon-image", [], null, null, null, View_IconImageComponent_0, RenderType_IconImageComponent)), i1.ɵdid(1, 114688, null, 0, i11.IconImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconImageComponentNgFactory = i1.ɵccf("app-icon-image", i11.IconImageComponent, View_IconImageComponent_Host_0, { icon: "icon" }, {}, []);
export { IconImageComponentNgFactory as IconImageComponentNgFactory };
