import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {MEDIA} from '../const/media.const';

const loadingImg = require('../../assets/images/loading.jpg');
const errorImg = require('../../assets/images/error.jpg');
const deletedImg = require('../../assets/images/deleted.png');
const processingImg = require('../../assets/images/processing.gif');

const statusImages = {
  loading: loadingImg as string,
  error: errorImg as string,
  processing: processingImg as string,
  deleted: deletedImg as string
};

export class RetryDirective {

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected http: HttpClient
  ) {
  }

  protected getRetryInterval(passedInterval: number): number {
    if (passedInterval && !isNaN(passedInterval)) {
      return passedInterval;
    } else {
      return MEDIA.retryInterval;
    }
  }

  protected performCheckHead(uri: string, interval: number, setSrc: (uri: string, successful: boolean) => void) {
    if (!this.destroy$.isStopped) {
      setSrc(statusImages.loading, false);

      if (uri.indexOf('/deleted/') !== -1) {
        setSrc(statusImages.deleted, false);
        return;
      }

      this.checkHead(uri)
        .subscribe(res => {
            const status: number = res.status;
            switch (status) {
              case 200:
                setSrc(uri, true);
                break;
              case 202:
                setSrc(statusImages.processing, false);
                setTimeout(() => this.performCheckHead(uri, interval, setSrc), interval);
                break;
              default:
                setSrc(statusImages.error, false);
            }
          },
          err => {
            setSrc(statusImages.error, false); // error
          });
    }
  }

  private checkHead(uri: string): Observable<HttpResponse<Object>> {
    return this.http.request(
      'HEAD',
      uri,
      {observe: 'response'}
    );
  }
}
