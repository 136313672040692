import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {Container} from './container';
import {Moment} from 'moment';
import {DateUtil} from '../date-util';

export class MediaGroup extends Container {
  private readonly _formattedTotalDuration: string;

  private readonly _validFrom: Moment | null;
  private readonly _validTo: Moment | null;

  constructor(
    name: string,
    validFrom: string | Date | null,
    validTo: string | Date | null,
    private _description: string | null,
    private _containerItemsCount: number,
    private _totalDuration: number,
    links: ResourceLinks,
  ) {
    super(name, links);
    this._formattedTotalDuration = formatDuration(this.totalDuration);
    this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
    this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
  }

  public get validFrom(): Moment | null {
    return this._validFrom;
  }

  public get validTo(): Moment | null {
    return this._validTo;
  }

  public get description(): string | null {
    return this._description;
  }

  public get containerItemsCount(): number {
    return this._containerItemsCount;
  }

  public get totalDuration(): number {
    return this._totalDuration;
  }

  public get formattedTotalDuration(): string {
    return this._formattedTotalDuration;
  }
}

function formatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  let result = '';

  if (hours > 0) {
    result += `${hours} hours `;
  }

  if (minutes > 0) {
    result += `${minutes % 60} minutes`;
  }

  result += `${seconds % 60} seconds`;

  return result;
}
