import {Directive, Input} from '@angular/core';
import {HostListener} from '@angular/core';
import {DialogService} from '../../services/dialog/dialog.service';
import {ImagePreviewDialogComponent} from '../../components/dialogs/preview-dialogs/image-preview-dialog/image-preview-dialog.component';
import {Media} from '../../classes/xds-models/media';
import {MEDIA_TYPES} from '../../const/media-types.enum';
import {MatDialogConfig} from '@angular/material';
import {VideoPreviewDialogComponent} from '../../components/dialogs/preview-dialogs/video-preview-dialog/video-preview-dialog.component';

/**
 * directive to open huge image previews / lightbox
 */

@Directive({
  selector: '[appImagePreview]'
})
export class ImagePreviewDirective {

  private static DIALOGS_FOR_TYPES = {
    'Image': ImagePreviewDialogComponent,
    'Video': VideoPreviewDialogComponent,
    'Message': ImagePreviewDialogComponent,
  };

  @Input() private data: Media;

  @Input()
  public appImagePreviewBlocked: boolean;

  constructor(private dialogService: DialogService,
  ) {
  }

  @HostListener('click', ['$event'])
  public onClick($event: any): void {
    $event.preventDefault();
    $event.stopPropagation();

    const dialogComponent = ImagePreviewDirective.DIALOGS_FOR_TYPES[MEDIA_TYPES[this.data.type]];

    if (dialogComponent && !this.appImagePreviewBlocked) {
      const config: MatDialogConfig = this.dialogService.getPreviewConfig();
      config.data = this.data;
      this.dialogService.openDialogWithConfig(dialogComponent, config);
    }
  }
}
