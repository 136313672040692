import { Player } from '../../classes/xds-models/player';
import { Domain } from '../../classes/xds-models/domain';
import { ContainerItem } from '../../classes/xds-models/container-item';
import { MediaGroup } from '../../classes/xds-models/media-group';
import { MediaFile } from '../../classes/xds-models/media-file';
import { PlayerStatus } from '../../classes/xds-models/player-status';
import { Channel } from '../../classes/xds-models/channel';
import { MediaImage } from '../../classes/xds-models/media-image';
import { MediaVideo } from '../../classes/xds-models/media-video';
import { MediaMessage } from '../../classes/xds-models/media-message';
import { MEDIA_TYPES } from '../../const/media-types.enum';
import { AuthService } from '../auth/auth.service';
import { PlayerInventory } from "../../classes/xds-models/player-inventory";
import { InventoryItem } from "../../classes/xds-models/inventory-item";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
/**
 * maps objects (any) to proper classes
 */
var XdsApiModelMapperService = /** @class */ (function () {
    function XdsApiModelMapperService(auth) {
        this.auth = auth;
    }
    XdsApiModelMapperService.prototype.mapDomain = function (res) {
        var name = res.name;
        var description = res.description;
        var fullyQualifiedName = res.fullyQualifiedName;
        var links = res._links;
        var childrenCount = res.childrenCount;
        if ('uuid' in res) {
            return new Player(name, description, fullyQualifiedName, links);
        }
        else {
            return new Domain(name, description, fullyQualifiedName, childrenCount, links);
        }
    };
    XdsApiModelMapperService.prototype.mapContainerItem = function (res) {
        var name = res.name;
        var validFrom = res.validFrom === null ? null : new Date(res.validFrom);
        var validTo = res.validTo === null ? null : new Date(res.validTo);
        var fullyQualifiedDomainName = res.fullyQualifiedDomainName;
        var domainName = res.domainName;
        var containerName = res.containerName;
        var itemType = res.itemType;
        var links = res._links;
        return new ContainerItem(name, validFrom, validTo, fullyQualifiedDomainName, domainName, containerName, itemType, links);
    };
    XdsApiModelMapperService.prototype.mapMediaGroup = function (res) {
        var name = res.name;
        var description = res.description;
        var containerItemsCount = res.containerItemsCount;
        var totalDuration = res.totalDuration;
        var links = res._links;
        var validFrom = res.validFrom === null ? null : new Date(res.validFrom);
        var validTo = res.validTo === null ? null : new Date(res.validTo);
        return new MediaGroup(name, validFrom, validTo, description, containerItemsCount, totalDuration, links);
    };
    XdsApiModelMapperService.prototype.mapMedia = function (res) {
        var name = res.name;
        var validFrom = res.validFrom === null ? null : new Date(res.validFrom);
        var validTo = res.validTo === null ? null : new Date(res.validTo);
        var type = res.type;
        var hashId = res.hashId;
        var currentRevision = res.currentRevision;
        var mimeType = res.mimeType;
        var usedInContainerItemsCount = res.usedInContainerItemsCount;
        var links = res._links;
        switch (type) {
            case MEDIA_TYPES[MEDIA_TYPES.Video]:
                return new MediaVideo(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount, this.auth.currentNetworkKey, links);
            case MEDIA_TYPES[MEDIA_TYPES.Message]:
                return new MediaMessage(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount, this.auth.currentNetworkKey, links);
            case MEDIA_TYPES[MEDIA_TYPES.Image]:
            default:
                return new MediaImage(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount, this.auth.currentNetworkKey, links);
        }
    };
    XdsApiModelMapperService.prototype.mapMediaFile = function (res) {
        var width = res.width;
        var height = res.height;
        var mimeType = res.mimeType;
        var hash = res.hash;
        var revision = res.revision;
        var links = res._links;
        return new MediaFile(width, height, mimeType, hash, revision, links);
    };
    XdsApiModelMapperService.prototype.mapPlayer = function (res) {
        var name = res.name;
        var description = res.description;
        var fullyQualifiedName = res.fullyQualifiedName;
        var links = res._links;
        return new Player(name, description, fullyQualifiedName, links);
    };
    XdsApiModelMapperService.prototype.mapPlayerStatus = function (res) {
        var heartBeat = new Date(res.heartbeat);
        var links = res._links;
        return new PlayerStatus(heartBeat, links);
    };
    XdsApiModelMapperService.prototype.mapChannel = function (res) {
        var name = res.name;
        var links = res._links;
        return new Channel(name, links);
    };
    XdsApiModelMapperService.prototype.mapContainerItemItem = function (res) {
        if (res.itemType === 'group') {
            return this.mapMediaGroup(res);
        }
        else if (res.itemType === 'media') {
            return this.mapMedia(res);
        }
        else {
            throw new Error('Cannot parse item from resource:\n' + JSON.stringify(res));
        }
    };
    XdsApiModelMapperService.prototype.mapPlayerInventory = function (res) {
        var percent = res.percentDownloaded;
        var missing = res.missing;
        var inventoryItem = new InventoryItem(res.inventoryItem.itemId, res.inventoryItem.itemName, res.inventoryItem.mediaType, res.inventoryItem.itemValidFrom, res.inventoryItem.itemValidTo);
        var links = res._links;
        return new PlayerInventory(percent, missing, inventoryItem, links);
    };
    XdsApiModelMapperService.ngInjectableDef = i0.defineInjectable({ factory: function XdsApiModelMapperService_Factory() { return new XdsApiModelMapperService(i0.inject(i1.AuthService)); }, token: XdsApiModelMapperService, providedIn: "root" });
    return XdsApiModelMapperService;
}());
export { XdsApiModelMapperService };
