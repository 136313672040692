import { HttpClient } from '@angular/common/http';
import { XdsMediaGroupsApiService } from './media-groups/xds-media-groups-api.service';
import { XdsContainerItemsApiService } from './container-items/xds-container-items-api.service';
import { XdsMediasApiService } from './medias/xds-medias-api.service';
import { XdsPlayersApiService } from './players/xds-players-api.service';
import { XdsDomainsApiService } from './domains/xds-domains-api.service';
import { XdsApiUtilService } from './xds-api-util.service';
import { XdsPlayerInventoriesApiService } from "./player-inventories/xds-player-inventories-api.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./medias/xds-medias-api.service";
import * as i3 from "./media-groups/xds-media-groups-api.service";
import * as i4 from "./players/xds-players-api.service";
import * as i5 from "./player-inventories/xds-player-inventories-api.service";
import * as i6 from "./domains/xds-domains-api.service";
import * as i7 from "./container-items/xds-container-items-api.service";
/**
 * returns a bunch of resource apis
 */
var XdsApiService = /** @class */ (function () {
    function XdsApiService(http, mediasApi, mediaGroupsApi, playersApi, playerInventoriesApi, domainApi, containerItemsApi) {
        this.http = http;
        this.mediasApi = mediasApi;
        this.mediaGroupsApi = mediaGroupsApi;
        this.playersApi = playersApi;
        this.playerInventoriesApi = playerInventoriesApi;
        this.domainApi = domainApi;
        this.containerItemsApi = containerItemsApi;
    }
    Object.defineProperty(XdsApiService.prototype, "Medias", {
        /**
         * return media api
         */
        get: function () {
            return this.mediasApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsApiService.prototype, "MediaGroups", {
        /**
         * return media group api
         */
        get: function () {
            return this.mediaGroupsApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsApiService.prototype, "ContainerItems", {
        /**
         * return container item api
         */
        get: function () {
            return this.containerItemsApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsApiService.prototype, "Domains", {
        /**
         * return domain api
         */
        get: function () {
            return this.domainApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsApiService.prototype, "Players", {
        /**
         * return player api
         */
        get: function () {
            return this.playersApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XdsApiService.prototype, "PlayerInventories", {
        /**
         * return player inventories api
         */
        get: function () {
            return this.playerInventoriesApi;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * return backend version
     */
    XdsApiService.prototype.getVersion = function () {
        return this.http.request('GET', XdsApiUtilService.createApiUrl('versions'));
    };
    XdsApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsApiService_Factory() { return new XdsApiService(i0.inject(i1.HttpClient), i0.inject(i2.XdsMediasApiService), i0.inject(i3.XdsMediaGroupsApiService), i0.inject(i4.XdsPlayersApiService), i0.inject(i5.XdsPlayerInventoriesApiService), i0.inject(i6.XdsDomainsApiService), i0.inject(i7.XdsContainerItemsApiService)); }, token: XdsApiService, providedIn: "root" });
    return XdsApiService;
}());
export { XdsApiService };
