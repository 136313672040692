/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bcn.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navigation/navigation.component.ngfactory";
import * as i3 from "../navigation/navigation.component";
import * as i4 from "../../../services/display-size/display-size.service";
import * as i5 from "../../../services/auth/auth.service";
import * as i6 from "../../../services/domain/domain.service";
import * as i7 from "@angular/cdk/layout";
import * as i8 from "../../../services/xds-api/xds-api.service";
import * as i9 from "../../../services/xds-api/domains/xds-domains-api.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../services/title-bar/title-bar.service";
import * as i12 from "./bcn.component";
import * as i13 from "@angular/common";
var styles_BcnComponent = [i0.styles];
var RenderType_BcnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BcnComponent, data: {} });
export { RenderType_BcnComponent as RenderType_BcnComponent };
export function View_BcnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-component", [], null, null, null, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationComponent, [i4.DisplaySizeService, i5.AuthService, i6.DomainService, i7.BreakpointObserver, i8.XdsApiService, i9.XdsDomainsApiService, i10.Router, i10.ActivatedRoute, i11.TitleBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BcnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bcn", [], null, null, null, View_BcnComponent_0, RenderType_BcnComponent)), i1.ɵdid(1, 114688, null, 0, i12.BcnComponent, [i5.AuthService, i13.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BcnComponentNgFactory = i1.ɵccf("app-bcn", i12.BcnComponent, View_BcnComponent_Host_0, {}, {}, []);
export { BcnComponentNgFactory as BcnComponentNgFactory };
