import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {DomainService} from '../../../services/domain/domain.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.sass']
})
export class LandingComponent implements OnInit, OnDestroy {
  public currentDomainId = -1;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private titleBarSercice: TitleBarService,
    private domainService: DomainService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit() {
    this.titleBarSercice.setTitle('');
    this.domainService.getCurrentDomain()
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentDomain => {
        this.currentDomainId = currentDomain.id;
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
