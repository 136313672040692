import { of } from 'rxjs';
import { DialogService } from '../../dialog/dialog.service';
import { catchError } from 'rxjs/operators';
import { PageErrorOfflineComponent } from '../../../components/shared/errors/page-errors/page-error-offline/page-error-offline.component';
import * as i0 from "@angular/core";
import * as i1 from "../../dialog/dialog.service";
var ErrorInterceptorService = /** @class */ (function () {
    function ErrorInterceptorService(dialogService) {
        this.dialogService = dialogService;
    }
    ErrorInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request)
            .pipe(catchError(function (err, caught) {
            var httpError = err;
            if (_this.isConnectionError(httpError)) {
                _this.dialogService.openDialog(PageErrorOfflineComponent, {})
                    .subscribe(function () { return location.reload(); }, function () { return location.reload(); }, function () { return location.reload(); });
            }
            else {
                // pass error
                throw err;
            }
            return of(err);
        }));
    };
    ErrorInterceptorService.prototype.isConnectionError = function (err) {
        var statusCode = err.status;
        return typeof statusCode !== 'undefined' && statusCode !== null && (statusCode === 502 || statusCode === 503);
    };
    ErrorInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorInterceptorService_Factory() { return new ErrorInterceptorService(i0.inject(i1.DialogService)); }, token: ErrorInterceptorService, providedIn: "root" });
    return ErrorInterceptorService;
}());
export { ErrorInterceptorService };
