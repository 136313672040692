var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { XdsResourcePage } from '../../../classes/xds-resource-page';
import { DisplaySizeService } from '../../../services/display-size/display-size.service';
import { XdsApiService } from '../../../services/xds-api/xds-api.service';
import { TitleBarService } from '../../../services/title-bar/title-bar.service';
import { DomainService } from '../../../services/domain/domain.service';
import { InformationService } from '../../../services/information/information.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { MediaDeleteDialogComponent } from '../../dialogs/media-delete-dialog/media-delete-dialog.component';
import { AddMediaToMediaGroupsDialogComponent } from '../../dialogs/add-media-to-media-groups-dialog/add-media-to-media-groups-dialog.component';
import { RemoveMediaFromMediaGroupsDialogComponent } from '../../dialogs/remove-media-from-media-groups-dialog/remove-media-from-media-groups-dialog.component';
import { UploadMediaItemDialogComponent } from '../../dialogs/upload-media-item-dialog/upload-media-item-dialog.component';
import { PAGINATOR_PARAMS } from '../../../const/mat-paginator.const';
import { MEDIA_TYPES } from '../../../const/media-types.enum';
import { BroadcastService } from '../../../services/broadcast/broadcast.service';
var MediaItemListComponent = /** @class */ (function (_super) {
    __extends(MediaItemListComponent, _super);
    function MediaItemListComponent(displaySize, api, router, route, titleBarService, domainService, infoService, permissionService, translate, broadcaster, dialogService) {
        var _this = _super.call(this, displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
            mainApi: api.Medias,
            title: translate.instant('COMMON.MEDIA')
        }) || this;
        _this.dialogService = dialogService;
        _this.sort = 'modified,desc';
        return _this;
    }
    MediaItemListComponent.prototype.openDeleteMediaDialog = function () {
        var _this = this;
        this.dialogService.openDialog(MediaDeleteDialogComponent, { items: this.selectionManager.getItems() })
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                _this.reloadPage(true);
            }
        });
    };
    MediaItemListComponent.prototype.openAddMediaDialog = function () {
        var _this = this;
        this.dialogService.openDialog(AddMediaToMediaGroupsDialogComponent, { items: this.selectionManager.getItems() })
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                _this.reloadPage(true);
            }
        });
    };
    MediaItemListComponent.prototype.openRemoveMediaDialog = function () {
        var _this = this;
        this.dialogService.openDialog(RemoveMediaFromMediaGroupsDialogComponent, { items: this.selectionManager.getItems() })
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                _this.reloadPage(true);
            }
        });
    };
    MediaItemListComponent.prototype.openUploadMediaDialog = function () {
        this.dialogService.openDialog(UploadMediaItemDialogComponent, {})
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                // this.reloadPage(true);
            }
        });
    };
    MediaItemListComponent.prototype.createSearchParams = function (data) {
        var currentDomain = data[0];
        var params = data[1];
        var page = +(params.get('page')) || PAGINATOR_PARAMS.page;
        var domainId = currentDomain.id;
        return {
            page: page,
            _domain: domainId,
            types: [MEDIA_TYPES[MEDIA_TYPES.Image], MEDIA_TYPES[MEDIA_TYPES.Video], MEDIA_TYPES[MEDIA_TYPES.Message]],
            sort: this.sort,
        };
    };
    return MediaItemListComponent;
}(XdsResourcePage));
export { MediaItemListComponent };
