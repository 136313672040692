import {Component, OnInit} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {MatSidenav} from '@angular/material';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {AuthService} from '../../../services/auth/auth.service';
import {DomainService} from '../../../services/domain/domain.service';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {XdsDomainsApiService} from '../../../services/xds-api/domains/xds-domains-api.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {IXdsVersions} from '../../../interfaces/xds-models/xds-versions';

@Component({
  selector: 'app-navigation-component',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})
export class NavigationComponent implements OnInit {
  public title: Observable<string>;
  public titleVisibility: Observable<boolean>;
  public currentDomainId = -1;
  public versions: IXdsVersions;
  private isDesktop: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public displaySizeService: DisplaySizeService,
    public authService: AuthService,
    private domainService: DomainService,
    private breakpointObserver: BreakpointObserver,
    private api: XdsApiService,
    private domainApi: XdsDomainsApiService,
    private router: Router,
    private route: ActivatedRoute,
    private titleBarService: TitleBarService,
  ) {
  }

  public ngOnInit(): void {
    this.title = this.titleBarService.Title;
    this.titleVisibility = this.titleBarService.TitleVisibility;

    this.domainService.getCurrentDomain()
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentDomain => {
        this.currentDomainId = currentDomain.id;
      });
    this.displaySizeService.isDesktop
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => this.isDesktop = val);
    this.api.getVersion().subscribe(
      versions => this.versions = versions,
      err => console.log('!!!!', err)
    );
  }

  public logout(): void {
    this.authService.logout();
  }

  public isActiveRoute(route: string[]): boolean {
    const routeCopy = route.slice();
    routeCopy.unshift(this.route.snapshot.url[0].path);

    return this.router.isActive(routeCopy.join('/'), false);
  }

  public closeSidenavOnClick(sidenav: MatSidenav): void {
    if (!this.isDesktop) {
      sidenav.close();
    }
  }
}
