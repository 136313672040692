<div class="theme-logo-wrapper">
  <div class="theme-logo"></div>
</div>
<div class="page-links">
  <mat-card [routerLink]="['../',currentDomainId, 'medias']"
            class="pointer">
    <mat-card-content>
      <mat-icon>theaters</mat-icon>
      <div class="mat-body-2">{{'COMMON.MEDIA' | translate }}</div>
    </mat-card-content>
  </mat-card>
  <mat-card [routerLink]="['../',currentDomainId, 'mediaGroups']"
            class="pointer">
    <mat-card-content>
      <mat-icon>collections</mat-icon>
      <div class=mat-body-2>{{'COMMON.MEDIAGROUPS' | translate }}</div>
    </mat-card-content>
  </mat-card>
  <mat-card [routerLink]="['../',currentDomainId, 'players']"
            class="pointer">
    <mat-card-content>
      <mat-icon>devices</mat-icon>
      <div class="mat-body-2">{{'COMMON.PLAYERS' | translate }}</div>
    </mat-card-content>
  </mat-card>
</div>
