import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsNamedResource} from './xds-named-resource';
import {IPatchObj} from '../../interfaces/patch-obj';
import {Moment} from 'moment';
import {MOMENTJS} from '../../const/momentjs.const';
import {Writable} from '../../interfaces/writable';
import {DateUtil} from '../date-util';

export class ContainerItem extends XdsNamedResource implements IPatchObj, Writable {

  private readonly _validFrom: Moment | null;
  private readonly _validTo: Moment | null;
  private _isWritable = false;

  constructor(
    name: string,
    validFrom: string | Date | null,
    validTo: string | Date | null,
    private _fullyQualifiedDomainName: string,
    private _domainName: string,
    private _containerName: string,
    private _itemType: string,
    links: ResourceLinks,
  ) {
    super(name, links);

    this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
    this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
  }

  public get validFrom(): Moment | null {
    return this._validFrom;
  }

  public get validTo(): Moment | null {
    return this._validTo;
  }

  public get fullyQualifiedDomainName(): string {
    return this._fullyQualifiedDomainName;
  }

  public get domainName(): string {
    return this._domainName;
  }

  public updateValidDates(validFrom: string | Date, validTo: string | Date): ContainerItem {
    return new ContainerItem(
      this.name,
      validFrom,
      validTo,
      this.fullyQualifiedDomainName,
      this.domainName,
      this.containerName,
      this.itemType,
      this.links
    );
  }

  public getPatchObj(): any {
    return {
      id: this.id,
      validFrom: this.validFrom ? this.validFrom.format(MOMENTJS.validDate) : null,
      validTo: this.validTo ? this.validTo.format(MOMENTJS.validDate) : null,
    };
  }

  public get containerName(): string {
    return this._containerName;
  }

  public get itemType(): string {
    return this._itemType;
  }

  public get isWritable(): boolean {
    return this._isWritable;
  }

  public set isWritable(value: boolean) {
    this._isWritable = value;
  }
}
