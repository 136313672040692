import {Injectable} from '@angular/core';
import {Player} from '../../classes/xds-models/player';
import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {Domain} from '../../classes/xds-models/domain';
import {ContainerItem} from '../../classes/xds-models/container-item';
import {MediaGroup} from '../../classes/xds-models/media-group';
import {Media} from '../../classes/xds-models/media';
import {MediaFile} from '../../classes/xds-models/media-file';
import {PlayerStatus} from '../../classes/xds-models/player-status';
import {Channel} from '../../classes/xds-models/channel';
import {MediaImage} from '../../classes/xds-models/media-image';
import {MediaVideo} from '../../classes/xds-models/media-video';
import {MediaMessage} from '../../classes/xds-models/media-message';
import {MEDIA_TYPES} from '../../const/media-types.enum';
import {AuthService} from '../auth/auth.service';
import {PlayerInventory} from "../../classes/xds-models/player-inventory";
import {InventoryItem} from "../../classes/xds-models/inventory-item";

/**
 * maps objects (any) to proper classes
 */

@Injectable({
  providedIn: 'root'
})
export class XdsApiModelMapperService {

  constructor(
    private auth: AuthService,
  ) {
  }

  public mapDomain(res: any): Domain {
    const name: string = res.name;
    const description: string = res.description;
    const fullyQualifiedName: string = res.fullyQualifiedName;
    const links: ResourceLinks = res._links;
    const childrenCount: number = res.childrenCount;

    if ('uuid' in res) {
      return new Player(name, description, fullyQualifiedName, links);
    } else {
      return new Domain(name, description, fullyQualifiedName, childrenCount, links);
    }
  }

  public mapContainerItem(res: any): ContainerItem {
    const name: string = res.name;
    const validFrom: Date | null = res.validFrom === null ? null : new Date(res.validFrom);
    const validTo: Date | null = res.validTo === null ? null : new Date(res.validTo);
    const fullyQualifiedDomainName: string = res.fullyQualifiedDomainName;
    const domainName: string = res.domainName;
    const containerName = res.containerName;
    const itemType = res.itemType;
    const links: ResourceLinks = res._links;

    return new ContainerItem(name, validFrom, validTo, fullyQualifiedDomainName, domainName, containerName, itemType, links);
  }

  public mapMediaGroup(res: any): MediaGroup {
    const name: string = res.name;
    const description: string = res.description;
    const containerItemsCount: number = res.containerItemsCount;
    const totalDuration: number = res.totalDuration;
    const links: ResourceLinks = res._links;
    const validFrom: Date | null = res.validFrom === null ? null : new Date(res.validFrom);
    const validTo: Date | null = res.validTo === null ? null : new Date(res.validTo);

    return new MediaGroup(
      name, validFrom, validTo, description, containerItemsCount, totalDuration, links
    );
  }

  public mapMedia(res: any): Media {
    const name: string = res.name;
    const validFrom: Date | null = res.validFrom === null ? null : new Date(res.validFrom);
    const validTo: Date | null = res.validTo === null ? null : new Date(res.validTo);
    const type: string = res.type;
    const hashId: string = res.hashId;
    const currentRevision: number = res.currentRevision;
    const mimeType: string = res.mimeType;
    const usedInContainerItemsCount: number = res.usedInContainerItemsCount;
    const links: ResourceLinks = res._links;

    switch (type) {
      case MEDIA_TYPES[MEDIA_TYPES.Video]:
        return new MediaVideo(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount,
          this.auth.currentNetworkKey, links);
      case MEDIA_TYPES[MEDIA_TYPES.Message]:
        return new MediaMessage(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount,
          this.auth.currentNetworkKey, links);
      case MEDIA_TYPES[MEDIA_TYPES.Image]:
      default:
        return new MediaImage(name, validFrom, validTo, hashId, currentRevision, mimeType, usedInContainerItemsCount,
          this.auth.currentNetworkKey, links);
    }
  }

  public mapMediaFile(res: any): MediaFile {
    const width: number = res.width;
    const height: number = res.height;
    const mimeType: string = res.mimeType;
    const hash: string = res.hash;
    const revision: number = res.revision;

    const links: ResourceLinks = res._links;

    return new MediaFile(width, height, mimeType, hash, revision, links);
  }

  public mapPlayer(res: any): Player {
    const name: string = res.name;
    const description: string = res.description;
    const fullyQualifiedName: string = res.fullyQualifiedName;
    const links: ResourceLinks = res._links;

    return new Player(name, description, fullyQualifiedName, links);
  }

  public mapPlayerStatus(res: any): PlayerStatus {
    const heartBeat: Date = new Date(res.heartbeat);
    const links: ResourceLinks = res._links;

    return new PlayerStatus(heartBeat, links);
  }

  public mapChannel(res: any): Channel {
    const name: string = res.name;
    const links: ResourceLinks = res._links;

    return new Channel(name, links);
  }

  public mapContainerItemItem<T extends Media | MediaGroup>(res: any): T {
    if (res.itemType === 'group') {
      return this.mapMediaGroup(res) as T;
    } else if (res.itemType === 'media') {
      return this.mapMedia(res) as T;
    } else {
      throw new Error('Cannot parse item from resource:\n' + JSON.stringify(res));
    }
  }

  public mapPlayerInventory(res: any): PlayerInventory {
    const percent: number = res.percentDownloaded;
    const missing: boolean = res.missing;
    const inventoryItem: InventoryItem = new InventoryItem(
      res.inventoryItem.itemId,
      res.inventoryItem.itemName,
      res.inventoryItem.mediaType,
      res.inventoryItem.itemValidFrom,
      res.inventoryItem.itemValidTo
    );
    const links: ResourceLinks = res._links;

    return new PlayerInventory(percent, missing, inventoryItem, links);
  }

}
