var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RetryDirective } from '../retry.directive';
import { KeycloakService } from "keycloak-angular";
var RetryVideoDirective = /** @class */ (function (_super) {
    __extends(RetryVideoDirective, _super);
    function RetryVideoDirective(http, el, renderer, keycloak, document) {
        var _this = _super.call(this, http) || this;
        _this.el = el;
        _this.renderer = renderer;
        _this.keycloak = keycloak;
        _this.document = document;
        _this.appRetryVideoStatusChange = new EventEmitter();
        _this.foundOneSrc = false;
        return _this;
    }
    RetryVideoDirective.prototype.ngOnInit = function () {
        var _this = this;
        var videoSrcs = this.appRetryVideoSrc;
        var posterSrc = this.appRetryPosterSrc;
        var interval = this.getRetryInterval(this.appRetryVideoInterval);
        if (posterSrc.src) {
            this.performCheckHead(posterSrc.src, interval, function (src, successful) {
                if (successful) {
                    console.debug('poster done, waiting for video...');
                }
            });
        }
        videoSrcs.forEach(function (media) {
            if (media.src) {
                _this.performCheckHead(media.src, interval, function (src, successful) {
                    if (successful) {
                        _this.setSrc(media);
                        _this.setPoster(posterSrc.src);
                        _this.foundOneSrc = true;
                    }
                    else {
                        if (!_this.foundOneSrc) {
                            _this.setPoster(src);
                        }
                    }
                });
            }
        });
    };
    RetryVideoDirective.prototype.ngOnDestroy = function () {
        this.destroy$.next(false);
        this.destroy$.unsubscribe();
    };
    RetryVideoDirective.prototype.setSrc = function (media) {
        var _this = this;
        this.keycloak.getToken().then(function (token) {
            var source = _this.document.createElement('source');
            source.src = media.src + '?access_token=' + token;
            source.mimeType = media.mimeType;
            _this.renderer.appendChild(_this.el.nativeElement, source);
            _this.appRetryVideoStatusChange.emit(true);
        });
    };
    RetryVideoDirective.prototype.setPoster = function (src) {
        var _this = this;
        if (src && src.startsWith('data:')) {
            this.posterAttr = src;
        }
        else {
            this.keycloak.getToken().then(function (token) {
                _this.posterAttr = src + '?access_token=' + token;
            });
        }
    };
    return RetryVideoDirective;
}(RetryDirective));
export { RetryVideoDirective };
