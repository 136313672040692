import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

interface IBroadcast {
  type: BroadcastTypes;
  message: any;
}

export enum BroadcastTypes {
  RELOAD
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private broadcast$: Subject<IBroadcast> = new Subject();

  constructor() {
  }

  public emitReload(): void {
    /* NOTE ABOUT RELOADS IN ANGULAR 6
     * As of Angular 5.1 there is a supported technique for route reloading.
     * This can be done by using the onSameUrlNavigation configuration option as part of the buil-on Angular router.
     * But Angular is no designed to reload routes, components should handle reloads
     */
    this.emit({
      type: BroadcastTypes.RELOAD,
      message: {force: true}
    });
  }

  public listen(type: BroadcastTypes): Observable<any> {
    return this.broadcast$.asObservable()
      .pipe(filter(broadcast => type === broadcast.type
      ))
      .pipe(map(
        broadcast => broadcast.message
      ));
  }

  private emit(newBC: IBroadcast): void {
    this.broadcast$.next(newBC);
  }
}
