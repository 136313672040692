import {Directive, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RetryDirective} from '../retry.directive';
import {KeycloakService} from "keycloak-angular";

@Directive({
  selector: 'img[appRetryImage]'
})
export class RetryImageDirective extends RetryDirective implements OnInit, OnDestroy {

  @Input()
  public appRetryImageSrc: string;

  @Input()
  public appRetryImageInterval: number;

  @Output()
  public appRetryImageStatusChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @HostBinding('attr.src')
  public srcAttr: string;

  constructor(
    http: HttpClient,
    protected keycloak: KeycloakService
  ) {
    super(http);
  }

  public ngOnInit(): void {
    const srcUri: string = this.appRetryImageSrc;
    const interval: number = this.getRetryInterval(this.appRetryImageInterval);

    if (srcUri) {
      this.performCheckHead(srcUri, interval, (src, successful) => {
        this.setSrc(src);
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }

  private setSrc(src: string): void {
    if (src && src.startsWith('data:')) {
      this.srcAttr = src;

    } else {
      this.keycloak.getToken().then((token: string) => {
        this.srcAttr = src + '?access_token=' + token;
      });
    }
  }

}
