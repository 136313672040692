var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { XdsResourcePage } from "../../../classes/xds-resource-page";
import { DisplaySizeService } from "../../../services/display-size/display-size.service";
import { XdsApiService } from "../../../services/xds-api/xds-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleBarService } from "../../../services/title-bar/title-bar.service";
import { DomainService } from "../../../services/domain/domain.service";
import { InformationService } from "../../../services/information/information.service";
import { PermissionService } from "../../../services/permission/permission.service";
import { BroadcastService } from "../../../services/broadcast/broadcast.service";
import { DialogService } from "../../../services/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { PAGINATOR_PARAMS } from "../../../const/mat-paginator.const";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { DateUtil } from "../../../classes/date-util";
import { MOMENTJS } from "../../../const/momentjs.const";
var PlayerComponent = /** @class */ (function (_super) {
    __extends(PlayerComponent, _super);
    function PlayerComponent(displaySize, api, router, route, titleBarService, domainService, infoService, permissionService, broadcaster, dialogService, translate) {
        return _super.call(this, displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
            mainApi: api.PlayerInventories,
            title: translate.instant('COMMON.PLAYERS')
        }) || this;
    }
    PlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.paramMap.subscribe(function (params) {
            _this.playerStatusId = params.get('status');
        });
    };
    PlayerComponent.prototype.setTitle = function () {
        var _this = this;
        this.route.paramMap
            .pipe(takeUntil(this.destroy$), map(function (params) { return +params.get('id'); }), switchMap(function (id) { return _this.api.Players.get(id); }))
            .subscribe(function (player) {
            _this.titleBarService.setTitle(_this.config.title + ': ' + player.name);
        });
    };
    PlayerComponent.prototype.createSearchParams = function (data) {
        var queryParams = data[1];
        var page = +queryParams.get('page') || PAGINATOR_PARAMS.page;
        var pageSize = +queryParams.get('size') || PAGINATOR_PARAMS.pageSize;
        var statusId = +queryParams.get('status');
        var validity = queryParams.get('validity') ? queryParams.get('validity') : 'V';
        this.validityFilter = validity;
        var validFrom = new Date(); // XDS-2200: use current date as validFrom
        var validTo = new Date();
        return {
            playerStatus: statusId,
            page: page,
            size: pageSize,
            itemValidFrom: DateUtil.createValidDate(validFrom).format(MOMENTJS.validDate),
            itemValidTo: DateUtil.createValidDate(validTo).format(MOMENTJS.validDate),
            validity: validity
            // sort: 'position' // ATTENTION for some reason, adding a 'sort' param breaks the paging
        };
    };
    PlayerComponent.prototype.toggleFilter = function () {
        this.router.navigate([], {
            relativeTo: this.route, queryParamsHandling: 'merge', queryParams: {
                validity: this.validityFilter !== 'I' ? 'I' : 'V'
            }
        });
    };
    return PlayerComponent;
}(XdsResourcePage));
export { PlayerComponent };
