<h1 mat-dialog-title>{{'DIALOG.EDITTIMESPAN.TITLE' | translate}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1">{{'DIALOG.EDITTIMESPAN.HEADER' | translate}}</p>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">
            <mat-icon *ngIf="!isAllEditable()"
                      class="mat-warn"
                      matTooltipPosition="right">
              block
            </mat-icon>
            {{'COMMON.MEDIA' | translate}}
          </p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: (containerItemSelectionManager.getItems() | async).length,
            count:  (containerItemsToEdit | async).length
            } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p class="mat-caption" *ngIf="showBlockedInfo">
        <mat-icon class="mat-warn icon-fix">block</mat-icon>
        {{'COMMON.PERMISSIONINFO' | translate }}
      </p>
      <mat-chip-list #chipList [multiple]="true">
        <mat-chip *ngFor="let ci of (containerItemsToEdit | async)"
                  [selected]="containerItemSelectionManager.isSelected(ci)"
                  (click)="containerItemSelectionManager.change(ci)"
                  class="pointer">
          <mat-icon *ngIf="!(ci.isWritable()  | async)"
                    class="mat-warn"
                    [matTooltip]="'Cannot edit because of insufficient permissions'"
                    matTooltipPosition="right">block
          </mat-icon>
          {{ci.xdsResource.name}}
          <!--<mat-icon matChipRemove>cancel</mat-icon>-->
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="containerItemSelectionManager.selectUnselectAll(containerItemsToEdit)">
        {{(containerItemSelectionManager.isAllSelected(containerItemsToEdit | async) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.TIMESPAN' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{'COMMON.TIMESPANINFO' | translate}}</p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-timespan-picker [(dateFrom)]="validFromDate" [(dateTo)]="validToDate" (change)="validate()"></app-timespan-picker>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="ConfirmClick()"
          [disabled]="!isAllEditable()">
    {{'COMMON.OK' | translate}}
  </button>
  <button mat-button (click)="AbortClick()" cdkFocusInitial>
    {{'COMMON.CANCEL' | translate}}
  </button>
</div>
