import {environment} from '../../../environments/environment';
import {XdsResource} from '../../classes/xds-models/xds-resource';
import {PLAYOUT_PARAMS, PlayoutParams} from '../../interfaces/xds-models/playout-params';

/**
 * Helper class to handle some basic operations
 */

export class XdsApiUtilService {

  public static createApiUrl(path: string): string {
    return environment.xds.apiRoot + path;
  }

  /**
   * ids are handled by prefixing id params with a underscore (_)
   */
  public static buildQueryString(params: { [key: string]: any }): string {
    const keys = Object.keys(params);
    let query = '';
    let connector = '?';

    if (keys.length > 0) {
      keys.forEach(key => {
        const isPath = key.startsWith('_');

        let queryKey = '';
        let queryValue = '';

        if (isPath) {
          queryKey = key.substr(1);
          queryValue = '/' + params[key];
        } else {
          queryKey = key;
          queryValue = params[key];
        }

        query += connector + queryKey + '=' + queryValue;
        connector = '&';
      });
    }

    return query;
  }

  /**
   * extract the id of response._link.self.href
   * @param link response._link.self.href
   * @return id inlcuded in link
   */
  public static getIdFromSelfLink(link: string): number {
    const pos = link.lastIndexOf('/') + 1;
    return +(link.substr(pos));
  }

  public static resourceListToStrIdList(resources: XdsResource[]): string[] {
    const list: string[] = [];

    resources.forEach(res => {
      list.push('' + res.id);
    });

    return list;
  }

  public static createPlayoutParams(validFrom: string = null, validTo: string = null): PlayoutParams {
    const sourceParams: PlayoutParams = {validFrom: validFrom, validTo: validTo};
    return Object.assign(PLAYOUT_PARAMS, sourceParams);
  }

  /**
   * only static classes, must be static because it is used in some classes, where no injection is possible
   */
  private constructor() {
  }
}
