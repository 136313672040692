/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-error-offline.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./page-error-offline.component";
import * as i11 from "../../../../../services/auth/auth.service";
var styles_PageErrorOfflineComponent = [i0.styles];
var RenderType_PageErrorOfflineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageErrorOfflineComponent, data: {} });
export { RenderType_PageErrorOfflineComponent as RenderType_PageErrorOfflineComponent };
export function View_PageErrorOfflineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["error_outline"])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(12, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.authService.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(16, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ERROR.OFFLINE.TITLE")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("ERROR.OFFLINE.INFO")); _ck(_v, 8, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11).disabled || null); var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("COMMON.RELOAD")); _ck(_v, 12, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 15).disabled || null); var currVal_7 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("COMMON.LOGOUT")); _ck(_v, 16, 0, currVal_8); }); }
export function View_PageErrorOfflineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-error-offline", [], null, null, null, View_PageErrorOfflineComponent_0, RenderType_PageErrorOfflineComponent)), i1.ɵdid(1, 114688, null, 0, i10.PageErrorOfflineComponent, [i11.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageErrorOfflineComponentNgFactory = i1.ɵccf("app-page-error-offline", i10.PageErrorOfflineComponent, View_PageErrorOfflineComponent_Host_0, {}, {}, []);
export { PageErrorOfflineComponentNgFactory as PageErrorOfflineComponentNgFactory };
