import {
  Component, ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentType} from '@angular/cdk/portal';
import {PageErrorComponent} from '../../../../classes/page-error/page-error-component';

@Component({
  selector: 'app-page-error-viewer',
  templateUrl: './page-error-viewer.component.html',
  styleUrls: ['./page-error-viewer.component.sass']
})
export class PageErrorViewerComponent implements OnDestroy, OnChanges {

  @ViewChild('messagecontainer', {read: ViewContainerRef})
  public entry: ViewContainerRef;
  @Input()
  private errorComponent: ComponentType<PageErrorComponent>;

  private componentRef: ComponentRef<PageErrorComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  public ngOnDestroy(): void {
    if (this.componentRef !== undefined) {
      this.componentRef.destroy();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.loadComponent();
  }

  private loadComponent(): void {
    this.entry.clear();

    if (typeof this.errorComponent !== 'undefined' && this.errorComponent !== null) {
      const factory: ComponentFactory<PageErrorComponent> = this.componentFactoryResolver.resolveComponentFactory(this.errorComponent);
      this.componentRef = this.entry.createComponent(factory);
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

}
