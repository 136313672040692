import {Injectable} from '@angular/core';
import {XdsResourceApiService} from '../resource/xds-resource-api.service';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {HttpClient} from '@angular/common/http';
import {PlayersSearch} from '../../../interfaces/search/players.search';
import {Player} from '../../../classes/xds-models/player';
import {Observable} from 'rxjs';
import {PlayerStatus} from '../../../classes/xds-models/player-status';
import {map} from 'rxjs/operators';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {Channel} from '../../../classes/xds-models/channel';
import {PlayerInventory} from "../../../classes/xds-models/player-inventory";
import {XdsApiUtilService} from "../xds-api-util.service";
import {PagedResources} from "../../../interfaces/xds-models/paged-resources";

@Injectable({
  providedIn: 'root'
})
export class XdsPlayersApiService extends XdsResourceApiService<Player, PlayersSearch> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'players',
    search: {
      endpoint: 'players/search/advanced'
    }
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient,
  ) {
    super(http, mapper, XdsPlayersApiService.configOptions);
  }

  public getStatus(player: Player): Observable<PlayerStatus> {
    return this.http.request<any>(
      'GET',
      player.links.status.href
    ).pipe(map(res => this.mapper.mapPlayerStatus(res)));
  }

  public getChannels(player: Player): Observable<Channel[]> {
    return this.http.request<any>(
      'GET',
      player.links.channels.href
    ).pipe(
      map(res => this.mapResourceList<Channel>(res, x => this.mapper.mapChannel(x)))
    );
  }

  protected mapResource(res: any): Player {
    return this.mapper.mapPlayer(res);
  }
}
