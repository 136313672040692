/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-error-viewer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-error-viewer.component";
var styles_PageErrorViewerComponent = [i0.styles];
var RenderType_PageErrorViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageErrorViewerComponent, data: {} });
export { RenderType_PageErrorViewerComponent as RenderType_PageErrorViewerComponent };
export function View_PageErrorViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { entry: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["messagecontainer", 1]], null, 0, "template", [], null, null, null, null, null))], null, null); }
export function View_PageErrorViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-error-viewer", [], null, null, null, View_PageErrorViewerComponent_0, RenderType_PageErrorViewerComponent)), i1.ɵdid(1, 704512, null, 0, i2.PageErrorViewerComponent, [i1.ComponentFactoryResolver], null, null)], null, null); }
var PageErrorViewerComponentNgFactory = i1.ɵccf("app-page-error-viewer", i2.PageErrorViewerComponent, View_PageErrorViewerComponent_Host_0, { errorComponent: "errorComponent" }, {}, []);
export { PageErrorViewerComponentNgFactory as PageErrorViewerComponentNgFactory };
