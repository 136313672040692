import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsResource} from './xds-resource';
import {Player} from './player';

interface LastHeartbeatDiff {
  ms: number;
  s: number;
  m: number;
  h: number;
  d: number;
}

export class PlayerStatus extends XdsResource {

  constructor(
    private _heartbeat: Date,
    links: ResourceLinks,
  ) {
    super(links);
  }

  public get lastHeartbeat(): Date {
    return this._heartbeat;
  }

  public lastHeartbeatDiff(): LastHeartbeatDiff {
    const currentDate = Date.now();
    const diffMilliseconds = Math.abs(currentDate - this.lastHeartbeat.getTime());
    const diffSeconds = Math.floor(diffMilliseconds / (60 * 1000));
    const diffMinutes = Math.floor(diffMilliseconds / (60 * 1000));
    const diffHours = Math.floor(diffMilliseconds / (60 * 60 * 1000));
    const diffDays = Math.floor(diffMilliseconds / (24 * 60 * 60 * 1000));

    return {
      ms: diffMilliseconds,
      s: diffSeconds,
      m: diffMinutes,
      h: diffHours,
      d: diffDays
    };
  }

  public statusState(): string {
    const diff = this.lastHeartbeatDiff();

    if (diff.d > 0) {
      return 'red';
    } else if (diff.h > 0) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  public statusHeartbeat(): object {
    const diff = this.lastHeartbeatDiff();
    if (diff.d > 0) {
      return {key: 'PLAYER.DAYS', value: diff.d};
    } else if (diff.h > 0) {
      return {key: 'PLAYER.HOURS', value: diff.h};
    } else if (diff.m > 0) {
      return {key: 'PLAYER.MINUTES', value: diff.m};
    } else {
      return {key: 'PLAYER.MOMENTS'};
    }
  }
}
