import { DateUtil } from "../date-util";
var InventoryItem = /** @class */ (function () {
    function InventoryItem(_itemId, _itemName, _mediaType, validFrom, validTo) {
        this._itemId = _itemId;
        this._itemName = _itemName;
        this._mediaType = _mediaType;
        this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
        this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
    }
    Object.defineProperty(InventoryItem.prototype, "itemId", {
        get: function () {
            return this._itemId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryItem.prototype, "itemName", {
        get: function () {
            return this._itemName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryItem.prototype, "mediaType", {
        get: function () {
            return this._mediaType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryItem.prototype, "validFrom", {
        get: function () {
            return this._validFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryItem.prototype, "validTo", {
        get: function () {
            return this._validTo;
        },
        enumerable: true,
        configurable: true
    });
    return InventoryItem;
}());
export { InventoryItem };
