var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Container } from './container';
import { DateUtil } from '../date-util';
var MediaGroup = /** @class */ (function (_super) {
    __extends(MediaGroup, _super);
    function MediaGroup(name, validFrom, validTo, _description, _containerItemsCount, _totalDuration, links) {
        var _this = _super.call(this, name, links) || this;
        _this._description = _description;
        _this._containerItemsCount = _containerItemsCount;
        _this._totalDuration = _totalDuration;
        _this._formattedTotalDuration = formatDuration(_this.totalDuration);
        _this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
        _this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
        return _this;
    }
    Object.defineProperty(MediaGroup.prototype, "validFrom", {
        get: function () {
            return this._validFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "validTo", {
        get: function () {
            return this._validTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "containerItemsCount", {
        get: function () {
            return this._containerItemsCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "totalDuration", {
        get: function () {
            return this._totalDuration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "formattedTotalDuration", {
        get: function () {
            return this._formattedTotalDuration;
        },
        enumerable: true,
        configurable: true
    });
    return MediaGroup;
}(Container));
export { MediaGroup };
function formatDuration(seconds) {
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var result = '';
    if (hours > 0) {
        result += hours + " hours ";
    }
    if (minutes > 0) {
        result += minutes % 60 + " minutes";
    }
    result += seconds % 60 + " seconds";
    return result;
}
