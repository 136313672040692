import { InformationService } from '../information/information.service';
import { FileUploader } from 'ng2-file-upload';
import { XdsApiUtilService } from '../xds-api/xds-api-util.service';
import { DomainService } from '../domain/domain.service';
import { NotificationUploadProgressComponent } from '../../components/notifications/notification-upload-progress/notification-upload-progress.component';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BroadcastService } from '../broadcast/broadcast.service';
import { KeycloakService } from "keycloak-angular";
import * as i0 from "@angular/core";
import * as i1 from "../domain/domain.service";
import * as i2 from "../information/information.service";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "../broadcast/broadcast.service";
import * as i7 from "keycloak-angular";
var mimeTypes = {
    images: [
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/png'
    ],
    videos: [
        'video/avi',
        'video/mp4',
        'video/mpeg',
        'video/ogg',
        'video/quicktime',
        'video/vnd.dlna.mpeg-tts',
        'video/webm',
        'video/x-m4v',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/3gpp',
        'text/vnd.trolltech.linguist',
        'video/x-ms-asf',
        'video/x-la-asf',
        'video/x-sgi-movie',
        'video/3gpp2',
        'video/mp2t',
        'video/x-flv',
        'video/vnd.vivo',
    ]
};
var UploadService = /** @class */ (function () {
    function UploadService(domainService, infoService, snackBar, translateService, router, broadcaster, keycloak) {
        var _this = this;
        this.domainService = domainService;
        this.infoService = infoService;
        this.snackBar = snackBar;
        this.translateService = translateService;
        this.router = router;
        this.broadcaster = broadcaster;
        this.keycloak = keycloak;
        this.interval = interval(100);
        this.maxFileSize = 5 * 1024 * 1024 * 1024;
        this.allowedMimeTypes = [].concat(mimeTypes.images).concat(mimeTypes.videos);
        this.progress$ = new BehaviorSubject(0);
        this.current$ = new BehaviorSubject(0);
        this.total$ = new BehaviorSubject(0);
        this.destroy$ = new Subject();
        this._uploader = new FileUploader({
            url: XdsApiUtilService.createApiUrl('medias/bin'),
            maxFileSize: this.maxFileSize,
            allowedMimeType: this.allowedMimeTypes
        });
        // The upload path depends on the current domain
        this.domainService.getCurrentDomain()
            .subscribe(function (currentDomain) {
            _this.keycloak.getToken().then(function (token) {
                _this._uploader.setOptions({
                    url: XdsApiUtilService.createApiUrl("medias/bin?domainId=" + currentDomain.id + "&access_token=" + token)
                });
            });
        });
        this._uploader.onAfterAddingFile = function (file) {
            file.withCredentials = true;
        };
        this._uploader.onCompleteItem = function (item, response, status, headers) {
            console.log('file uploaded', item);
        };
        this._uploader.onCompleteAll = function () {
            _this.destroy$.next(true);
            _this.infoService.unlock();
            _this.clearList();
            // handle different situations if upload complete
            _this.domainService.getCurrentDomain()
                .pipe(take(1))
                .subscribe(function (currentDomain) {
                var targetUrlTree = _this.router.parseUrl('/bcn/' + currentDomain.id + '/medias');
                var currentUrlTree = _this.router.parseUrl(_this.router.url);
                var currentPage = currentUrlTree.queryParamMap.get('page');
                var isMediaState = _this.router.isActive(targetUrlTree, false);
                if (isMediaState && currentPage === null || currentPage === '0') {
                    _this.broadcaster.emitReload();
                }
                else if (isMediaState) {
                    _this.infoService.reloadPage(_this.translateService.instant('DIALOG.UPLOADMEDIA.UPLOADCOMPLETED'));
                }
                else {
                    _this.infoService.goToMediaAfterUpload(_this.translateService.instant('DIALOG.UPLOADMEDIA.UPLOADCOMPLETED'));
                }
            });
        };
        this._uploader.onWhenAddingFileFailed = function (item, filter, options) {
            switch (filter.name) {
                case 'fileSize': {
                    _this.translateService.get('DIALOG.UPLOADMEDIA.ERRORSIZE', {
                        size: item.size,
                        maxSize: _this.maxFileSize
                    })
                        .subscribe(function (text) { return _this.infoService.error(null, text); });
                    break;
                }
                case 'mimeType': {
                    var allowedTypes = _this.allowedMimeTypes.join();
                    _this.translateService.get('DIALOG.UPLOADMEDIA.ERRORTYPE', {
                        type: item.type,
                        allowedTypes: _this.allowedMimeTypes.map(function (o) { return o
                            .replace('image/', ' ')
                            .replace('video/', ' ')
                            .replace('text/', ' '); })
                    })
                        .subscribe(function (text) { return _this.infoService.error(null, text); });
                    break;
                }
                default: {
                    console.error("Unknown error (filter is " + filter.name + ")");
                    _this.infoService.error(null, _this.translateService.instant('ERROR.UPLOADINGITEMS'));
                }
            }
        };
    }
    /**
     * Starts the upload process of all enqueued files. The progress is displayed via Material SnackBar.
     */
    UploadService.prototype.beginUpload = function () {
        var _this = this;
        this.infoService.lock();
        this.uploader.uploadAll();
        this.interval
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            var progress = _this._uploader.progress;
            var total = _this._uploader.queue.length;
            var current = 1 + total - _this._uploader.getNotUploadedItems().length;
            _this.progress$.next(progress);
            _this.total$.next(total);
            _this.current$.next(current);
            if (progress === 100) {
                _this.snackBar.dismiss();
            }
        });
        // material guidelines allow only one snackbar at one
        // of you open another snackbar while one is open, the open one gets automatically closed
        // we propably want to implement some sort of locking mechanism here
        this._snackBarRef = this.snackBar.openFromComponent(NotificationUploadProgressComponent, {
            // duration: 5000,
            data: {
                progress: this.progress,
                current: this.current,
                total: this.total,
            }
        });
    };
    /**
     * Removes all files from the uploader queue.
     */
    UploadService.prototype.clearList = function () {
        this._uploader.clearQueue();
    };
    /**
     * Removes a file from the uploader queue.
     * @param {FileItem} file
     */
    UploadService.prototype.removeFile = function (file) {
        var pos = this._uploader.queue.indexOf(file);
        if (pos > -1) {
            this._uploader.removeFromQueue(file);
        }
    };
    Object.defineProperty(UploadService.prototype, "uploader", {
        /**
         * Returns the underlying FileUploader.
         * @returns {FileUploader}
         */
        get: function () {
            return this._uploader;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadService.prototype, "progress", {
        /**
         * Tracks the uploading progress
         * @returns {Observable<number>}
         */
        get: function () {
            return this.progress$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadService.prototype, "current", {
        /**
         * Tracks the current processed file index.
         * @returns {Observable<number>}
         */
        get: function () {
            return this.current$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadService.prototype, "total", {
        /**
         * Tracks the total number of files to uplaod.
         * @returns {Observable<number>}
         */
        get: function () {
            return this.total$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    UploadService.ngInjectableDef = i0.defineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.inject(i1.DomainService), i0.inject(i2.InformationService), i0.inject(i3.MatSnackBar), i0.inject(i4.TranslateService), i0.inject(i5.Router), i0.inject(i6.BroadcastService), i0.inject(i7.KeycloakService)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
