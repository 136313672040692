import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsNamedResource} from './xds-named-resource';

export class Domain extends XdsNamedResource {

  constructor(
    name: string,
    private _description: string,
    private _fullyQualifiedName: string,
    private _childrenCount: number,
    links: ResourceLinks,
  ) {
    super(name, links);
  }

  public get description(): string {
    return this._description;
  }

  public get fullyQualifiedName(): string {
    return this._fullyQualifiedName;
  }

  public get parentDomainName(): string {
    try {
      let domains = this.fullyQualifiedName.split('/');
      return domains[domains.length - 2].trim();
    } catch (e) {
      console.warn(e);
      return '';
    }
  }

  public hasChildren(): boolean {
    return this._childrenCount > 0;
  }
}
