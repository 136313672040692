var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { XdsResourcePage } from '../../../classes/xds-resource-page';
import { DisplaySizeService } from '../../../services/display-size/display-size.service';
import { DateUtil } from '../../../classes/date-util';
import { XdsApiService } from '../../../services/xds-api/xds-api.service';
import { TitleBarService } from '../../../services/title-bar/title-bar.service';
import { DomainService } from '../../../services/domain/domain.service';
import { InformationService } from '../../../services/information/information.service';
import { PAGINATOR_PARAMS } from '../../../const/mat-paginator.const';
import { PermissionService } from '../../../services/permission/permission.service';
import { BroadcastService } from '../../../services/broadcast/broadcast.service';
var MediaGroupListComponent = /** @class */ (function (_super) {
    __extends(MediaGroupListComponent, _super);
    function MediaGroupListComponent(displaySize, api, router, route, titleBarService, domainService, infoService, permissionService, broadcaster, translate) {
        var _this = _super.call(this, displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
            mainApi: api.MediaGroups,
            title: translate.instant('COMMON.MEDIAGROUPS')
        }) || this;
        _this.dateUtil = DateUtil;
        _this.sort = 'modified,desc';
        return _this;
    }
    MediaGroupListComponent.prototype.goTo = function (id) {
        this.router.navigate([id], { relativeTo: this.route });
    };
    MediaGroupListComponent.prototype.createSearchParams = function (data) {
        var currentDomain = data[0];
        var params = data[1];
        var page = +params.get('page') || PAGINATOR_PARAMS.page;
        var domainId = currentDomain.id;
        return {
            page: page,
            _domain: domainId,
            sort: this.sort
        };
    };
    return MediaGroupListComponent;
}(XdsResourcePage));
export { MediaGroupListComponent };
