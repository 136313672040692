import { OnDestroy, OnInit } from '@angular/core';
import { SelectionManager } from '../../../classes/selection-manager';
import { DisplaySizeService } from '../../../services/display-size/display-size.service';
import { DateUtil } from '../../../classes/date-util';
import { XdsResourceView } from '../../../classes/xds-models/xds-resource-view';
import { XdsApiService } from '../../../services/xds-api/xds-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var CardTableRowMediaitemComponent = /** @class */ (function () {
    function CardTableRowMediaitemComponent(displaySize, api) {
        this.displaySize = displaySize;
        this.api = api;
        this.dateUtil = DateUtil;
        this.domainList$ = new BehaviorSubject([]);
        this.destroy$ = new Subject();
    }
    CardTableRowMediaitemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.Domains.getDomainsForItem(this.media.xdsResource)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (data) {
            _this.domainList$.next(data);
        });
    };
    CardTableRowMediaitemComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(false);
        this.destroy$.unsubscribe();
    };
    Object.defineProperty(CardTableRowMediaitemComponent.prototype, "mediaDomains", {
        get: function () {
            return this.domainList$.asObservable();
            // .pipe(map(domains => {
            //   return domains.map(domain => {
            //     return domain.name;
            //   });
            // }));
        },
        enumerable: true,
        configurable: true
    });
    return CardTableRowMediaitemComponent;
}());
export { CardTableRowMediaitemComponent };
