<div>
  <mat-form-field>
    <label class="hidden" for="valid-from-input"></label>
    <input matInput
           id="valid-from-input"
           [placeholder]="placeholderFrom"
           [max]="dateTo"
           [matDatepicker]="pickerValidFrom"
           [(ngModel)]="dateFrom"
           (dateChange)="update()"
           disabled>
    <mat-datepicker-toggle matSuffix [for]="pickerValidFrom"></mat-datepicker-toggle>
    <mat-datepicker #pickerValidFrom disabled="false"></mat-datepicker>
  </mat-form-field>
  <mat-icon (click)="this.resetDateFrom()" class="icon-fix">clear</mat-icon>
</div>

<div>
  <mat-form-field>
    <label class="hidden" for="valid-to-input"></label>
    <input matInput
           id="valid-to-input"
           [placeholder]="placeholderTo"
           [min]="dateFrom"
           [matDatepicker]="pickerValidTo"
           [(ngModel)]="dateTo"
           (dateChange)="update()"
           disabled>
    <mat-datepicker-toggle matSuffix [for]="pickerValidTo"></mat-datepicker-toggle>
    <mat-datepicker #pickerValidTo disabled="false"></mat-datepicker>
  </mat-form-field>
  <mat-icon (click)="resetDateTo()" class="icon-fix">clear</mat-icon>
</div>
