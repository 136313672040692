import {HttpErrorResponse} from '@angular/common/http';
import {PageErrorComponent} from './page-error-component';
import {ComponentType} from '@angular/cdk/portal';
import {PageErrorSwwComponent} from '../../components/shared/errors/page-errors/page-error-sww/page-error-sww.component';
import {PageErrorOfflineComponent} from '../../components/shared/errors/page-errors/page-error-offline/page-error-offline.component';
import {PageErrorServerComponent} from '../../components/shared/errors/page-errors/page-error-server/page-error-server.component';

export class PageErrorUtil {

  public static parse(error: any): ComponentType<PageErrorComponent> {
    if (error === undefined || error === null) {
      return null;
    } else if (error instanceof HttpErrorResponse) {
      return this.getHttpErrorComponent(error.status);
    } else {
      return PageErrorSwwComponent;
    }
  }

  private static getHttpErrorComponent(status: number): ComponentType<PageErrorComponent> {
    if (status === 502 || status === 503) {
      return PageErrorOfflineComponent;
    } else {
      return PageErrorServerComponent;
    }
  }
}

