import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {Container} from './container';

export class Channel extends Container {

  constructor(
    name: string,
    links: ResourceLinks,
  ) {
    super(name, links);
  }
}
