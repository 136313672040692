import { BehaviorSubject, combineLatest } from 'rxjs';
import { XdsApiService } from '../xds-api/xds-api.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTING_CONST } from '../../const/routing.const';
import { AuthService } from '../auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../xds-api/xds-api.service";
import * as i2 from "../auth/auth.service";
import * as i3 from "@angular/router";
var DomainService = /** @class */ (function () {
    function DomainService(api, auth, route, router) {
        var _this = this;
        this.api = api;
        this.auth = auth;
        this.route = route;
        this.router = router;
        this.currentDomain$ = new BehaviorSubject(null);
        this.allDomains$ = new BehaviorSubject(null);
        this.topLevelDomain$ = new BehaviorSubject(null);
        this.childDomains$ = new BehaviorSubject(null);
        this.auth.domainId
            .pipe(switchMap(function (domainId) { return _this.api.Domains.get(domainId); }))
            .pipe(switchMap(function (tld) {
            _this.topLevelDomain$.next(tld);
            return _this.getTopLevelDomain();
        }))
            .pipe(switchMap(function (tld) {
            return _this.api.Domains.getChildrenOnlyDomains(tld);
        }))
            .subscribe(function (children) {
            _this.childDomains$.next(children);
            _this.triggerAllDomains([_this.topLevelDomain$.getValue()].concat(children));
            _this.initialCurrentDomain(_this.topLevelDomain$.getValue());
        });
    }
    DomainService.prototype.getAllDomains = function () {
        return this.allDomains$.asObservable()
            .pipe(filter(function (val) { return val !== null; }));
    };
    DomainService.prototype.getCurrentDomain = function () {
        return this.currentDomain$.asObservable()
            .pipe(filter(function (val) { return val !== null; }));
    };
    DomainService.prototype.getCurrentDomainName = function () {
        return this.getCurrentDomain()
            .pipe(map(function (currentDomain) { return currentDomain.fullyQualifiedName; }));
    };
    DomainService.prototype.getTopLevelDomain = function () {
        return this.topLevelDomain$.asObservable()
            .pipe(filter(function (val) { return val !== null; }));
    };
    DomainService.prototype.getChildDomains = function () {
        return this.childDomains$.asObservable()
            .pipe(filter(function (val) { return val !== null; }));
    };
    DomainService.prototype.currentDomainIsTld = function () {
        return combineLatest(this.getCurrentDomain(), this.getTopLevelDomain())
            .pipe(map(function (domains) {
            var currentDomain = domains[0];
            var tld = domains[1];
            return currentDomain.id === tld.id;
        }));
    };
    // wrapper cause the trigger function should be private
    DomainService.prototype.updateCurrentDomain = function (domain) {
        this.triggerCurrentDomain(domain);
        this.redirectAfterChange(domain.id);
    };
    DomainService.prototype.initialCurrentDomain = function (tld) {
        var _this = this;
        this.route.root.firstChild.firstChild.paramMap
            .pipe(take(1), map(function (paramMap) {
            return paramMap.get('domainId');
        }), switchMap(function (urlDomainId) { return _this.findDomainById(+urlDomainId); }), map(function (searchResult) {
            if (searchResult) {
                return searchResult;
            }
            else {
                return tld;
            }
        }))
            .subscribe(function (domain) {
            _this.triggerCurrentDomain(domain);
        });
    };
    DomainService.prototype.findDomainById = function (id) {
        var _this = this;
        return this.getAllDomains()
            .pipe(take(1), map(function (domains) {
            for (var i = 0; i < domains.length; i++) {
                if (domains[i].id === id) {
                    return domains[i];
                }
            }
            _this.router.navigate([ROUTING_CONST.pages.landing]);
            return null;
        }));
    };
    DomainService.prototype.triggerCurrentDomain = function (domain) {
        this.currentDomain$.next(domain);
    };
    DomainService.prototype.triggerAllDomains = function (domains) {
        this.allDomains$.next(domains);
    };
    DomainService.prototype.redirectAfterChange = function (targetDomainId) {
        var urlFragments = this.router.url.split('/');
        var possibleDomainIdFragment = urlFragments[2]; // only urlFragments[2] can contain domain information
        // 0: ""
        // 1: "bcn"
        // 2: "{domainId}" or "{page without domainId}"
        // 3: "{page with domainId}"
        // 4: "{itemId}"
        var redirectTarget;
        if (typeof possibleDomainIdFragment !== 'undefined' && possibleDomainIdFragment.length > 0 && !isNaN(+possibleDomainIdFragment)) {
            redirectTarget = urlFragments.slice(0, 4);
            redirectTarget[2] = targetDomainId + '';
            var viewFragment = redirectTarget[3];
            var queryStartIndex = viewFragment.indexOf('?');
            if (queryStartIndex >= 0) {
                redirectTarget[3] = redirectTarget[3].slice(0, queryStartIndex);
            }
        }
        else if (typeof possibleDomainIdFragment !== 'undefined' && possibleDomainIdFragment.length > 0) {
            // urlFragments[2] is not an id
            redirectTarget = urlFragments; // its not a domain based route we can stay here
        }
        else {
            // i don't know where or what you're... i will redirect you to your landing page. have fun.
            redirectTarget = [ROUTING_CONST.pages.landing];
        }
        this.router.navigate(redirectTarget);
    };
    DomainService.ngInjectableDef = i0.defineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.inject(i1.XdsApiService), i0.inject(i2.AuthService), i0.inject(i3.ActivatedRoute), i0.inject(i3.Router)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
