import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {FileItem} from 'ng2-file-upload';
import {UploadService} from '../../../services/upload/upload.service';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';

@Component({
  selector: 'app-upload-media-item-dialog',
  templateUrl: './upload-media-item-dialog.component.html',
  styleUrls: ['./upload-media-item-dialog.component.sass']
})
export class UploadMediaItemDialogComponent implements OnInit, OnDestroy {
  public uploader = this.uploadService.uploader;
  public isDragOver = false;

  @ViewChild('fileinput')
  private fileInput: ElementRef;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<UploadMediaItemDialogComponent>,
    private uploadService: UploadService,
    public displaySize: DisplaySizeService
  ) {
  }

  public isNewFile(item: FileItem): boolean {
    return item.isUploaded  === false
        && item.isUploading === false
        && item.isReady     === false;
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.uploadService.clearList();
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {
    console.log('uploading items:', this.uploader.queue.length);
    this.uploadService.beginUpload();
    this.dialogRef.close({dataChanged: true});
  }

  public removeFile(file: FileItem): void {
    this.uploadService.removeFile(file);
  }

  public onDragEnter(event: DragEvent): void {
    this.isDragOver = true;
    event.preventDefault();
  }

  public onDrop(fileList: FileList): void {
    this.fileInput.nativeElement.files = fileList;
    this.isDragOver = false;
  }

  public onDragLeave(event: DragEvent): void {
    this.isDragOver = false;
    event.preventDefault();
  }

  public prevent(event: DragEvent): void {
    event.preventDefault();
  }
}
