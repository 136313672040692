import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth/auth.service";
import * as i2 from "@angular/router";
var GetInGuard = /** @class */ (function () {
    function GetInGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    GetInGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.authService.checkGeneralDomainPermissions()
            .pipe(map(function (hasPermissions) {
            if (hasPermissions) {
                _this.router.navigate(['bcn', ['landing']]);
            }
            return !hasPermissions;
        }));
    };
    GetInGuard.ngInjectableDef = i0.defineInjectable({ factory: function GetInGuard_Factory() { return new GetInGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: GetInGuard, providedIn: "root" });
    return GetInGuard;
}());
export { GetInGuard };
