<app-responsive-toolbar>
  <button mat-button
          class="button-with-close-badget"
          color="accent"
          [matMenuTriggerFor]="menu">
    {{ 'COMMON.FILTER' | translate}}
  </button>

  {{ 'PLAYER.INVENTORY' | translate }}

  <mat-paginator #paginator [hidePageSize]="paginatorParams.hidePageSize"
                 [length]="paginatorParams.items"
                 [pageSize]="paginatorParams.pageSize"
                 [pageSizeOptions]="paginatorParams.allowedPageSizes"
                 [pageIndex]="paginatorParams.page"
                 (page)="goToPage($event.pageIndex, $event.pageSize, {status: playerStatusId})"
                 class="toolbar-pagination">
  </mat-paginator>
</app-responsive-toolbar>

<app-page-error-viewer [errorComponent]="viewError"></app-page-error-viewer>

<app-content *ngIf="loaded && items.length > 0">

  <app-card-table>
    <app-card-table-header>
      <app-card-table-row>
        <app-card-table-cell>
        </app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.PREVIEW' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.NAME' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DETAILS' | translate }}</app-card-table-cell>
      </app-card-table-row>
    </app-card-table-header>

    <app-card-table-body>
      <ng-container *ngFor="let item of items">
        <app-card-table-row-inventory-item [playerInventory]="item"></app-card-table-row-inventory-item>
      </ng-container>
    </app-card-table-body>
  </app-card-table>

</app-content>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="toggleFilter()">
    <mat-icon>date_range</mat-icon>
    <ng-container *ngIf="validityFilter !== 'I'">{{ 'PLAYER.SHOWINVALID' | translate }}</ng-container>
    <ng-container *ngIf="validityFilter === 'I'">{{ 'PLAYER.SHOWVALID' | translate }}</ng-container>
  </button>
</mat-menu>
