<mat-toolbar #toolbar class="mat-elevation-z2" color="primary">
  <button
    *ngIf="!(displaySizeService.isDesktop | async)"
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="sidenav.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <span [ngClass]="['title', !(titleVisibility | async) ? 'hidden' : '']">{{title | async}}</span>

  <app-domain-selector></app-domain-selector>
</mat-toolbar>

<mat-sidenav-container class="main-container">

  <mat-sidenav #sidenav
               class="mat-elevation-z2"
               [autoFocus]="false"
               [disableClose]="(displaySizeService.isDesktop | async)"
               [mode]="(displaySizeService.isDesktop | async) ? 'side' : 'over'"
               [opened]="(displaySizeService.isDesktop | async)">
    <div class="nav-links">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['landing']"
           [ngClass]="{'active': isActiveRoute(['landing'])}"
           (click)="closeSidenavOnClick(sidenav)">
          <mat-icon matListIcon>place</mat-icon>
          <span>{{'COMMON.HOME' | translate }}</span>
        </a>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <a mat-list-item [routerLink]="[currentDomainId, 'medias']"
           [ngClass]="{'active': isActiveRoute([currentDomainId, 'medias'])}"
           (click)="closeSidenavOnClick(sidenav)">
          <mat-icon matListIcon>theaters</mat-icon>
          <span>{{'COMMON.MEDIA' | translate }}</span>
        </a>
        <a mat-list-item [routerLink]="[currentDomainId, 'mediaGroups']"
           [ngClass]="{'active': isActiveRoute([currentDomainId, 'mediaGroups'])}"
           (click)="closeSidenavOnClick(sidenav)">
          <mat-icon matListIcon>collections</mat-icon>
          <span>{{'COMMON.MEDIAGROUPS' | translate }}</span>
        </a>
        <a mat-list-item [routerLink]="[currentDomainId, 'players']"
           [ngClass]="{'active': isActiveRoute([currentDomainId, 'players'])}"
           (click)="closeSidenavOnClick(sidenav)">
          <mat-icon matListIcon>devices</mat-icon>
          <span>{{'COMMON.PLAYERS' | translate }}</span>
        </a>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <a mat-list-item [href]="authService.createAccountUrl()">
          <mat-icon matListIcon>person</mat-icon>
          <span>{{'COMMON.ACCOUNT' | translate }}</span>
        </a>
        <a mat-list-item [routerLink]="['help']"
           (click)="closeSidenavOnClick(sidenav)">
          <mat-icon matListIcon>help</mat-icon>
          <span>{{'COMMON.HELP' | translate }}</span>
        </a>
        <a mat-list-item (click)="logout()">
          <mat-icon matListIcon>exit_to_app</mat-icon>
          <span>{{'COMMON.LOGOUT' | translate }}</span>
        </a>
      </mat-nav-list>
    </div>

    <app-footer></app-footer>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-http-loader spinner="sk-wave"
                    [debounceDelay]="150"></ng-http-loader>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
