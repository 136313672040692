var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsNamedResource } from './xds-named-resource';
var Domain = /** @class */ (function (_super) {
    __extends(Domain, _super);
    function Domain(name, _description, _fullyQualifiedName, _childrenCount, links) {
        var _this = _super.call(this, name, links) || this;
        _this._description = _description;
        _this._fullyQualifiedName = _fullyQualifiedName;
        _this._childrenCount = _childrenCount;
        return _this;
    }
    Object.defineProperty(Domain.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Domain.prototype, "fullyQualifiedName", {
        get: function () {
            return this._fullyQualifiedName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Domain.prototype, "parentDomainName", {
        get: function () {
            try {
                var domains = this.fullyQualifiedName.split('/');
                return domains[domains.length - 2].trim();
            }
            catch (e) {
                console.warn(e);
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Domain.prototype.hasChildren = function () {
        return this._childrenCount > 0;
    };
    return Domain;
}(XdsNamedResource));
export { Domain };
