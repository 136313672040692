import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DisplaySizeService {

  // it is possible to use custom breakpoints:
  // private smallDevices = '(max-width: 480px)';
  // private mediumDevices = '(max-width: 960px)';
  // private largeDevices = '(min-width: 961px)';

  // https://material.io/design/layout/responsive-layout-grid.html#breakpoints
  // width: 0px - 600px
  private smallDevices: string[] = [Breakpoints.XSmall];
  // width: 601px - 1023x + 1024px - 1439px
  private mediumDevices: string[] = [Breakpoints.Small, Breakpoints.Medium];
  // width: >1439px
  private largeDevices: string[] = [Breakpoints.Large, Breakpoints.XLarge];

  // private isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  private isHandset$: Observable<boolean> = this.breakpointObserver.observe(this.smallDevices)
    .pipe(
      map(result => result.matches)
    );
  private isTablet$: Observable<boolean> = this.breakpointObserver.observe(this.mediumDevices)
    .pipe(
      map(result => result.matches)
    );
  private isDesktop$: Observable<boolean> = this.breakpointObserver.observe(this.largeDevices)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  public get isHandset(): Observable<boolean> {
    return this.isHandset$;
  }

  public get isTablet(): Observable<boolean> {
    return this.isTablet$;
  }

  public get isDesktop(): Observable<boolean> {
    return this.isDesktop$;
  }
}
