import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {XdsResourcePage} from '../../../classes/xds-resource-page';
import {Media} from '../../../classes/xds-models/media';
import {MediasSearch} from '../../../interfaces/search/medias.search';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {DomainService} from '../../../services/domain/domain.service';
import {InformationService} from '../../../services/information/information.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {MediaDeleteDialogComponent} from '../../dialogs/media-delete-dialog/media-delete-dialog.component';
import {AddMediaToMediaGroupsDialogComponent} from '../../dialogs/add-media-to-media-groups-dialog/add-media-to-media-groups-dialog.component';
import {RemoveMediaFromMediaGroupsDialogComponent} from '../../dialogs/remove-media-from-media-groups-dialog/remove-media-from-media-groups-dialog.component';
import {UploadMediaItemDialogComponent} from '../../dialogs/upload-media-item-dialog/upload-media-item-dialog.component';
import {Domain} from '../../../classes/xds-models/domain';
import {PAGINATOR_PARAMS} from '../../../const/mat-paginator.const';
import {MEDIA_TYPES} from '../../../const/media-types.enum';
import {BroadcastService} from '../../../services/broadcast/broadcast.service';
import {SearchParamsData} from '../../../interfaces/search-params-data';

@Component({
  selector: 'app-media-item-list',
  templateUrl: './media-item-list.component.html',
  styleUrls: ['./media-item-list.component.sass']
})
export class MediaItemListComponent extends XdsResourcePage<Media, MediasSearch> {
  private sort = 'modified,desc';

  constructor(
    displaySize: DisplaySizeService,
    api: XdsApiService,
    router: Router,
    route: ActivatedRoute,
    titleBarService: TitleBarService,
    domainService: DomainService,
    infoService: InformationService,
    permissionService: PermissionService,
    translate: TranslateService,
    broadcaster: BroadcastService,
    private dialogService: DialogService,
  ) {
    super(displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
      mainApi: api.Medias,
      title: translate.instant('COMMON.MEDIA')
    });
  }

  public openDeleteMediaDialog(): void {
    this.dialogService.openDialog(MediaDeleteDialogComponent, {items: this.selectionManager.getItems()})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            this.reloadPage(true);
          }
        }
      );
  }

  public openAddMediaDialog(): void {
    this.dialogService.openDialog(AddMediaToMediaGroupsDialogComponent, {items: this.selectionManager.getItems()})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            this.reloadPage(true);
          }
        }
      );
  }

  public openRemoveMediaDialog(): void {
    this.dialogService.openDialog(RemoveMediaFromMediaGroupsDialogComponent, {items: this.selectionManager.getItems()})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            this.reloadPage(true);
          }
        }
      );
  }

  public openUploadMediaDialog(): void {
    this.dialogService.openDialog(UploadMediaItemDialogComponent, {})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            // this.reloadPage(true);
          }
        }
      );
  }

  protected createSearchParams(data: SearchParamsData): MediasSearch {
    const currentDomain: Domain = data[0];
    const params: ParamMap = data[1];

    const page: number = +(params.get('page')) || PAGINATOR_PARAMS.page;
    const domainId: number = currentDomain.id;

    return {
      page: page,
      _domain: domainId,
      types: [MEDIA_TYPES[MEDIA_TYPES.Image], MEDIA_TYPES[MEDIA_TYPES.Video], MEDIA_TYPES[MEDIA_TYPES.Message]],
      sort: this.sort,
    };
  }
}
