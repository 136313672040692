import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';


@Component({
  selector: 'app-page-error-offline',
  templateUrl: './page-error-offline.component.html',
  styleUrls: ['./page-error-offline.component.sass']
})
export class PageErrorOfflineComponent implements OnInit {

  constructor(
    public authService: AuthService,
  ) { }

  public ngOnInit() {
  }

  public reload(): void {
    window.location.reload(true);
  }

}
