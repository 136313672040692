import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
export var BroadcastTypes;
(function (BroadcastTypes) {
    BroadcastTypes[BroadcastTypes["RELOAD"] = 0] = "RELOAD";
})(BroadcastTypes || (BroadcastTypes = {}));
var BroadcastService = /** @class */ (function () {
    function BroadcastService() {
        this.broadcast$ = new Subject();
    }
    BroadcastService.prototype.emitReload = function () {
        /* NOTE ABOUT RELOADS IN ANGULAR 6
         * As of Angular 5.1 there is a supported technique for route reloading.
         * This can be done by using the onSameUrlNavigation configuration option as part of the buil-on Angular router.
         * But Angular is no designed to reload routes, components should handle reloads
         */
        this.emit({
            type: BroadcastTypes.RELOAD,
            message: { force: true }
        });
    };
    BroadcastService.prototype.listen = function (type) {
        return this.broadcast$.asObservable()
            .pipe(filter(function (broadcast) { return type === broadcast.type; }))
            .pipe(map(function (broadcast) { return broadcast.message; }));
    };
    BroadcastService.prototype.emit = function (newBC) {
        this.broadcast$.next(newBC);
    };
    BroadcastService.ngInjectableDef = i0.defineInjectable({ factory: function BroadcastService_Factory() { return new BroadcastService(); }, token: BroadcastService, providedIn: "root" });
    return BroadcastService;
}());
export { BroadcastService };
