import {OnInit} from '@angular/core';
import {DateUtil} from '../../../classes/date-util';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {TranslateService} from '@ngx-translate/core';
import {InformationService} from '../../../services/information/information.service';
import {Subject} from 'rxjs';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {Media} from '../../../classes/xds-models/media';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {PermissionService} from '../../../services/permission/permission.service';
import {PlayerInventory} from "../../../classes/xds-models/player-inventory";

export abstract class CardTableRowItem implements OnInit {

  public dateUtil = DateUtil;
  public item: XdsResourceView<MediaGroup | ContainerItem | Media | PlayerInventory>;

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  protected constructor(
    public displaySize: DisplaySizeService,
    protected api: XdsApiService,
    protected infoService: InformationService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
  ) {
  }

  public ngOnInit(): void {
    this.loadData();
  }

  protected abstract loadData(): void;

}
