import {XdsResource} from './xds-resource';
import {ResourceLinks} from '../../interfaces/xds-models/resource-links';

export class MediaFile extends XdsResource {

  constructor(
    private _width: number,
    private _height: number,
    private _mimeType: string,
    private _hash: string,
    private _revision: number,
    links: ResourceLinks
  ) {
    super(links);
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  get mimeType(): string {
    return this._mimeType;
  }

  get hash(): string {
    return this._hash;
  }

  get revision(): number {
    return this._revision;
  }
}
