import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatSelectChange} from '@angular/material';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Domain} from '../../../classes/xds-models/domain';
import {DomainService} from '../../../services/domain/domain.service';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {InformationService} from '../../../services/information/information.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-domain-selector',
  templateUrl: './domain-selector.component.html',
  styleUrls: ['./domain-selector.component.sass']
})
export class DomainSelectorComponent implements OnInit, OnDestroy {

  public domains: Domain[] = [];
  public currentDomain: Domain;
  private showSearch: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public domainService: DomainService,
    public displaySizeService: DisplaySizeService,
    private titleBarService: TitleBarService,
    private infoService: InformationService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit() {
    const displaySizeSubscription = this.displaySizeService.isHandset
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      isHandset => this.showSearch = !isHandset,
      (err) => this.infoService.error(err, this.translateService.instant('ERROR.GETDISPLAYSIZE')),
      () => displaySizeSubscription.unsubscribe()
    );

    this.domainService.getAllDomains()
      .pipe(takeUntil(this.destroy$))
      .subscribe(domains => {
      this.domains = domains;
    });

    this.domainService.getCurrentDomain()
      .pipe(takeUntil(this.destroy$))
      .subscribe(domain => {
      this.currentDomain = domain;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  public changeDomain(event: MatSelectChange) {
    this.domainService.updateCurrentDomain(event.value);
    // this.router.navigate([ROUTING_CONST.pages.landing]);
  }

  public get showSearchBarSwitch(): boolean {
    if (this.domains && this.domains.length === 1) {
      return false;
    }
    return !this.showSearch;
  }

  public get showSearchBar(): boolean {
    if (this.domains && this.domains.length === 1) {
      return false;
    }
    return this.showSearch;
  }

  public toggle() {
    this.showSearch = !this.showSearch;
    if (this.showSearchBar) {
      this.titleBarService.hideTitle();
    }
    if (!this.showSearchBar) {
      this.titleBarService.restoreTitle();
    }
  }

}
