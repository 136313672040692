import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {XdsResourcePage} from '../../../classes/xds-resource-page';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {ContainerItemsSearch} from '../../../interfaces/search/container-items.search';
import {DisplaySizeService} from '../../../services/display-size/display-size.service';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {TitleBarService} from '../../../services/title-bar/title-bar.service';
import {DomainService} from '../../../services/domain/domain.service';
import {InformationService} from '../../../services/information/information.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {EditContainerItemDialogComponent} from '../../dialogs/edit-media-item-card-dialog/edit-container-item-dialog.component';
import {DeleteContainerItemFromMediaGroupComponent} from '../../dialogs/delete-container-item-from-media-group/delete-container-item-from-media-group.component';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {PAGINATOR_PARAMS} from '../../../const/mat-paginator.const';
import {BroadcastService} from '../../../services/broadcast/broadcast.service';
import {SearchParamsData} from '../../../interfaces/search-params-data';

@Component({
  selector: 'app-media-group',
  templateUrl: './media-group.component.html',
  styleUrls: ['./media-group.component.sass']
})
export class MediaGroupComponent extends XdsResourcePage<ContainerItem, ContainerItemsSearch> {
  public isRWMediaGroup$: Observable<boolean>;
  public loaded = false;
  public isRW = false;

  constructor(
    displaySize: DisplaySizeService,
    api: XdsApiService,
    router: Router,
    route: ActivatedRoute,
    titleBarService: TitleBarService,
    domainService: DomainService,
    infoService: InformationService,
    permissionService: PermissionService,
    broadcaster: BroadcastService,
    private dialogService: DialogService,
    translate: TranslateService,
  ) {
    super(displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
      mainApi: api.ContainerItems,
      title: translate.instant('COMMON.MEDIAGROUPS')
    });
  }

  public openEditContainerItemDialog(): void {
    this.dialogService.openDialog(EditContainerItemDialogComponent, {items: this.selectionManager.getItems()})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            this.reloadPage(true);
          }
        });

  }

  public openDeleteContainerItemDialog(): void {
    this.dialogService.openDialog(DeleteContainerItemFromMediaGroupComponent, {items: this.selectionManager.getItems()})
      .subscribe(
        res => {
          if (res && res.dataChanged) {
            this.reloadPage(true);
          }
        });
  }

  public toggleSelectionOf(ci: XdsResourceView<ContainerItem>): void {
    if (this.isRW && ci.xdsResource.itemType !== 'group') {
      this.selectionManager.change(ci);
    }
  }

  public toggleSelectionOfAllVisible(): void {
    const toggleableItems = this.allSelectableItems();

    this.selectionManager.selectUnselectAll(toggleableItems);
  }

  public allSelectableItems(): XdsResourceView<ContainerItem>[] {
    return this.items.filter(item => item.xdsResource.itemType !== 'group');
  }

  // override
  protected setTitle(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map(params => +params.get('id')),
        switchMap(id => this.api.MediaGroups.get(id))
      )
      .subscribe(
        mediaGroup => {
          this.titleBarService.setTitle(this.config.title + ': ' + mediaGroup.name);
          this.isRWMediaGroup$ = this.permissionService.isWritable(mediaGroup);
          this.isRWMediaGroup$.subscribe(rw => this.isRW = rw);
        }
      );
  }

  protected createSearchParams(data: SearchParamsData): ContainerItemsSearch {
    const currentDomain = data[0];
    const queryParams = data[1];
    const id = data[3].id;

    const page = +queryParams.get('page') || PAGINATOR_PARAMS.page;
    const pageSize = +queryParams.get('size') || PAGINATOR_PARAMS.pageSize;
    const domainId = currentDomain.id;

    return {
      _container: id,
      _domain: domainId,
      page: page,
      size: pageSize,
      sort: 'position'
    };
  }
}
