/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-info.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./notification-info.component";
import * as i5 from "@angular/material/snack-bar";
var styles_NotificationInfoComponent = [i0.styles];
var RenderType_NotificationInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationInfoComponent, data: {} });
export { RenderType_NotificationInfoComponent as RenderType_NotificationInfoComponent };
export function View_NotificationInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon-fix mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["info"])), (_l()(), i1.ɵted(3, null, ["\n", "\n"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 3, 0, currVal_1); }); }
export function View_NotificationInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-info", [], null, null, null, View_NotificationInfoComponent_0, RenderType_NotificationInfoComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotificationInfoComponent, [i5.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationInfoComponentNgFactory = i1.ɵccf("app-notification-info", i4.NotificationInfoComponent, View_NotificationInfoComponent_Host_0, {}, {}, []);
export { NotificationInfoComponentNgFactory as NotificationInfoComponentNgFactory };
