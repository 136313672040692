export const ROUTING_CONST = {
  query: {
    redirect: 'redirectUrl',
    search: {
      currentPage: 'p'
    }
  },
  pages: {
    landing: 'bcn/landing'
  },
};
