import { MediaGroup } from './media-group';
import { ContainerItem } from './container-item';
import { Media } from './media';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
var XdsResourceView = /** @class */ (function () {
    function XdsResourceView(_xdsResource, permissionService) {
        var _this = this;
        this._xdsResource = _xdsResource;
        this.permissionService = permissionService;
        this.writable$ = new BehaviorSubject(null);
        this.permissionService.isWritable(this.xdsResource)
            .subscribe(function (isWritable) { return _this.writable$.next(isWritable); });
    }
    Object.defineProperty(XdsResourceView.prototype, "xdsResource", {
        get: function () {
            return this._xdsResource;
        },
        enumerable: true,
        configurable: true
    });
    XdsResourceView.prototype.isWritable = function () {
        return this.writable$.asObservable()
            .pipe(filter(function (isWritable) {
            return typeof isWritable !== 'undefined' && isWritable !== null;
        }));
    };
    XdsResourceView.prototype.writableIcon = function () {
        return this.isWritable()
            .pipe(map(function (isWritable) {
            return isWritable ? 'lock_open' : 'lock';
        }));
    };
    XdsResourceView.prototype.writeable = function () {
        return this.writable$.getValue();
    };
    XdsResourceView.prototype.isMedia = function () {
        return this.xdsResource instanceof Media;
    };
    XdsResourceView.prototype.isMediaGroup = function () {
        return this.xdsResource instanceof MediaGroup;
    };
    XdsResourceView.prototype.isContainerItem = function () {
        return this.xdsResource instanceof ContainerItem;
    };
    XdsResourceView.prototype.thumbnailDownload = function () {
        if (this.isMedia()) {
            return this.xdsResource.thumbnailDownload().src;
        }
        else {
            // only media have a thumbnail
            return '';
        }
    };
    Object.defineProperty(XdsResourceView.prototype, "iconName", {
        get: function () {
            if (this.isMedia()) {
                var media = this.xdsResource;
                return media.iconName;
            }
            else if (this.isMediaGroup()) {
                return 'collections';
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    return XdsResourceView;
}());
export { XdsResourceView };
