<app-responsive-toolbar>
  <button mat-button
          class="button-with-close-badget"
          color="accent"
          [matMenuTriggerFor]="menu"
          [disabled]="!(isRWMediaGroup$ | async) || (selectionManager.count === 0 && !(displaySize.isHandset | async))"
          [matBadge]="selectionManager.count"
          [matBadgeHidden]="!selectionManager.count || !(displaySize.isHandset | async)"
          [matBadgePosition]="'after above'">
    {{ 'COMMON.ACTIONS' | translate }}
  </button>

  <span class="selection-counter mat-caption" *ngIf="!(displaySize.isHandset | async)">
    {{'COMMON.SELECTIONINFO' | translate: {
            selected: selectionManager.count,
            count: paginatorParams.items
          } }}
  </span>

  <span *ngIf="!(isRWMediaGroup$ | async)">
    <mat-icon [matTooltip]="('COMMON.WRITEABLE_IS_NOT_INFO') | translate">lock</mat-icon>
  </span>

  <mat-paginator #paginator
                 [hidePageSize]="paginatorParams.hidePageSize"
                 [length]="paginatorParams.items"
                 [pageSize]="paginatorParams.pageSize"
                 [pageSizeOptions]="paginatorParams.allowedPageSizes"
                 [pageIndex]="paginatorParams.page"
                 (page)="goToPage($event.pageIndex, $event.pageSize)"
                 class="toolbar-pagination">
  </mat-paginator>
</app-responsive-toolbar>
<app-page-error-viewer [errorComponent]="viewError"></app-page-error-viewer>

<app-content *ngIf="loaded && items.length > 0">
  <app-card-table>
    <app-card-table-header>
      <app-card-table-row>
        <app-card-table-cell>
          <mat-checkbox name="all_top"
                        (change)="toggleSelectionOfAllVisible()"
                        [checked]="selectionManager.isAllSelected(allSelectableItems())"
                        *ngIf="(isRWMediaGroup$ | async)">
          </mat-checkbox>
        </app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.PREVIEW' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.NAME' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DOMAIN' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DETAILS' | translate }}</app-card-table-cell>
      </app-card-table-row>
    </app-card-table-header>

    <app-card-table-body>
      <ng-container *ngFor="let ci of items">
        <app-card-table-row-ci-media *ngIf="ci.xdsResource.itemType === 'media'"
                                     [selectionManager]="selectionManager"
                                     [containerItem]="ci"
                                     [isRWMediaGroup]="(isRWMediaGroup$ | async)"
                                     (click)="toggleSelectionOf(ci)"
                                     class="pointer"
                                     [class.selected]="selectionManager.isSelected(ci)">
        </app-card-table-row-ci-media>
        <app-card-table-row-ci-group *ngIf="ci.xdsResource.itemType === 'group'"
                                     [selectionManager]="selectionManager"
                                     [containerItem]="ci"
                                     [isRWMediaGroup]="(isRWMediaGroup$ | async)">
        </app-card-table-row-ci-group>
      </ng-container>
    </app-card-table-body>
  </app-card-table>
</app-content>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="toggleSelectionOfAllVisible()"
          *ngIf="(displaySize.isHandset | async)">
    <mat-icon>select_all</mat-icon>
    {{ (selectionManager.isAllSelected(items) ? 'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate}}
  </button>

  <mat-divider *ngIf="(displaySize.isHandset | async)"></mat-divider>

  <button mat-menu-item
          [disabled]="selectionManager.isNoneSelected()"
          (click)="openDeleteContainerItemDialog()">
    <mat-icon>delete</mat-icon>
    {{ 'MEDIAGROUP.REMOVEFROMGROUP' | translate }}
  </button>


  <button mat-menu-item
          [disabled]="selectionManager.isNoneSelected()"
          (click)="openEditContainerItemDialog()">
    <mat-icon>edit</mat-icon>
    {{ 'MEDIAGROUP.EDITTIMESPAN' | translate }}
  </button>
</mat-menu>
