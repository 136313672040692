import { MatDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
        // This config stops Angular from setting an max-width=80vw inline style on the element.
        this.dialogConfig = {
            maxWidth: '',
            maxHeight: '',
        };
        this.dialogClass = 'dialog';
        this.previewClass = 'preview';
    }
    /**
     * Returns a display size adaptive MatDialogConfig for standard dialogs.
     * @returns {MatDialogConfig}
     */
    DialogService.prototype.getConfig = function () {
        return this.createConfig([this.dialogClass]);
    };
    /**
     * Returns a display size adaptive MatDialogConfig for preview dialogs.
     * @returns {MatDialogConfig}
     */
    DialogService.prototype.getPreviewConfig = function () {
        return this.createConfig([this.dialogClass, this.previewClass]);
    };
    /**
     * Opens an Angular Material Dialog an passes data to it.
     * @param {ComponentType<any>} component
     * @param data
     * @returns {Observable<any | undefined>}
     */
    DialogService.prototype.openDialog = function (component, data) {
        var config = this.getConfig();
        config.data = data;
        return this.openDialogWithConfig(component, config);
    };
    /**
     * Opens an Angular Material Dialog an passes a MatDialogConfig to it.
     * @param {ComponentType<any>} component
     * @param {MatDialogConfig} config
     * @returns {Observable<any | undefined>}
     */
    DialogService.prototype.openDialogWithConfig = function (component, config) {
        var dialogRef = this.dialog.open(component, config);
        return dialogRef.afterClosed();
    };
    DialogService.prototype.createConfig = function (panelClass) {
        var config = {};
        Object.assign(config, this.dialogConfig);
        config.panelClass = panelClass;
        return config;
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
