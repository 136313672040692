var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResource } from './xds-resource';
var MediaFile = /** @class */ (function (_super) {
    __extends(MediaFile, _super);
    function MediaFile(_width, _height, _mimeType, _hash, _revision, links) {
        var _this = _super.call(this, links) || this;
        _this._width = _width;
        _this._height = _height;
        _this._mimeType = _mimeType;
        _this._hash = _hash;
        _this._revision = _revision;
        return _this;
    }
    Object.defineProperty(MediaFile.prototype, "width", {
        get: function () {
            return this._width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaFile.prototype, "height", {
        get: function () {
            return this._height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaFile.prototype, "mimeType", {
        get: function () {
            return this._mimeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaFile.prototype, "hash", {
        get: function () {
            return this._hash;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaFile.prototype, "revision", {
        get: function () {
            return this._revision;
        },
        enumerable: true,
        configurable: true
    });
    return MediaFile;
}(XdsResource));
export { MediaFile };
