import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

@Component({
  selector: 'app-notification-success',
  templateUrl: './notification-success.component.html',
  styleUrls: ['./notification-success.component.sass']
})
export class NotificationSuccessComponent implements OnInit {
  public component: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  public ngOnInit() {
  }
}
