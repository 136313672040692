import {Injectable} from '@angular/core';
import {XdsResourceApiService} from '../resource/xds-resource-api.service';
import {Domain} from '../../../classes/xds-models/domain';
import {PagedSearchParams} from '../../../interfaces/search/paged-search-params';
import {XdsApiConfigOptions} from '../../../interfaces/xds-models/xds-api-config-options';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {concatAll, map, switchMap, toArray} from 'rxjs/operators';
import {XdsApiModelMapperService} from '../xds-api-model-mapper.service';
import {Player} from '../../../classes/xds-models/player';
import {XdsResource} from '../../../classes/xds-models/xds-resource';

@Injectable({
  providedIn: 'root'
})
export class XdsDomainsApiService extends XdsResourceApiService<Domain, PagedSearchParams> {

  private static configOptions: XdsApiConfigOptions = {
    endpoint: 'domains'
  };

  constructor(
    mapper: XdsApiModelMapperService,
    http: HttpClient
  ) {
    super(http, mapper, XdsDomainsApiService.configOptions);
  }

  public getChildren(domain: Domain): Observable<Domain[]> {
    return this.http.request<any>(
      'GET',
      domain.links.children.href
    ).pipe(map(res => this.mapResourceList<Domain>(res, x => this.mapResource(x))));
  }

  public getChildrenOnlyDomains(domain: Domain): Observable<Domain[]> {
    return this.http.request<any>(
      'GET',
      domain.links.children.href
    ).pipe(
      map(res => this.mapResourceList<Domain>(res, x => this.mapResource(x), (r, resKey) => {
        return resKey === 'domains';
      })),
    );
  }

  public getChildrenOnlyPlayers(domain: Domain): Observable<Player[]> {
    return this.http.request<any>(
      'GET',
      domain.links.children.href
    ).pipe(
      switchMap(res => of(this.mapResourceList<Domain>(res, x => this.mapResource(x), (r, resKey) => {
        return resKey === 'domains' || resKey === 'players';
      }))),
      switchMap(children => {
          let observables = [];
          children.forEach(child => {
            if (child.hasChildren()) {
              observables.push(this.getChildrenOnlyPlayers(child))
            } else if (child instanceof Player) {
              observables.push(of([child]));
            }
          });
          return observables;
        }
      ),
      concatAll(),
      switchMap((players: Player[]) => {
        return [].concat.apply([], players);  // flatten array of arrays
      }),
      toArray<Player>()
    );
  }

  public getDomainForItem(item: XdsResource) {
    return this.http.request(
      'GET',
      item.links.domain.href
    ).pipe(
      map(res => this.mapResource(res))
    );
  }



  public getDomainsForItem(item: XdsResource) {
    return this.http.request(
      'GET',
      item.links.domains.href
    ).pipe(
      map(res => this.mapResourceList<Domain>(res, x => this.mapResource(x)))
    );
  }

  protected mapResource(res: any): Domain {
    return this.mapper.mapDomain(res);
  }
}
