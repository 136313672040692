import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-timespan-picker',
  templateUrl: './timespan-picker.component.html',
  styleUrls: ['./timespan-picker.component.sass']
})
export class TimespanPickerComponent implements OnInit {
  @Input()
  public dateFrom: Date;
  @Input()
  public dateTo: Date;

  @Output()
  public dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output()
  public dateToChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output()
  public change: EventEmitter<any> = new EventEmitter<any>();

  public placeholderTo = '';
  public placeholderFrom = '';

  public static findSharedDate(dates: Moment[]): Date | null {
    // min length 1
    const refDate = dates[0];

    for (let i = 1; i < dates.length; i++) {
      const date = dates[i];

      if (date == null || !date.isSame(refDate)) {
        return null;
      }
    }

    if (refDate === null) {
      return null;
    } else {
      return refDate.toDate();
    }
  }

  constructor(private translateService: TranslateService) {
  }

  public ngOnInit() {
    this.update();
  }

  public update() {
    this.placeholderTo   = this.translateService.instant(this.dateTo == null ?   'TIMESPAN.UNTILANYTIME' : 'TIMESPAN.UNTIL');
    this.placeholderFrom = this.translateService.instant(this.dateFrom == null ? 'TIMESPAN.FROMANYTIME' : 'TIMESPAN.FROM');
    /*two way data binding*/
    this.dateToChange.emit(this.dateTo);
    this.dateFromChange.emit(this.dateFrom);
    /*general purpose info event*/
    this.change.emit(null);
  }

  public resetDateFrom() {
    this.dateFrom = null;
    this.update();
  }

  public resetDateTo() {
    this.dateTo = null;
    this.update();
  }
}
