export const MEDIA = {
  sizes: {
    thumbnail: 90,
    small_preview: 270,
    large_preview: 480,
  },
  mimeTypes: {
    iframe: 'text/html',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    mp4: 'video/mp4',
    webm: 'video/webm'
  },
  retryInterval: 5 * 1000
};
