import { DomainService } from '../domain/domain.service';
import { MediaGroup } from '../../classes/xds-models/media-group';
import { ContainerItem } from '../../classes/xds-models/container-item';
import { Media } from '../../classes/xds-models/media';
import { DOMAIN_PERMISSIONS } from '../../const/permissions.enum';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { XdsApiService } from '../xds-api/xds-api.service';
import 'rxjs-compat/add/observable/from';
import { PlayerInventory } from "../../classes/xds-models/player-inventory";
import * as i0 from "@angular/core";
import * as i1 from "../xds-api/xds-api.service";
import * as i2 from "../domain/domain.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(api, domainService) {
        this.api = api;
        this.domainService = domainService;
    }
    PermissionService.prototype.getRWMediaGroups = function () {
        var _this = this;
        return this.domainService.getCurrentDomain()
            .pipe(switchMap(function (currentDomain) { return _this.api.MediaGroups.getAllForDomain(currentDomain); }), switchMap(function (mediaGroups) { return _this.filterItemsByPermission(mediaGroups, [DOMAIN_PERMISSIONS.READ_WRITE]); }));
    };
    PermissionService.prototype.isWritable = function (item) {
        return this.getPermissionForItem(item)
            .pipe(map(function (permission) { return permission === DOMAIN_PERMISSIONS.READ_WRITE; }));
    };
    PermissionService.prototype.isDeletable = function (item) {
        return this.isWritable(item);
    };
    PermissionService.prototype.getPermissionForItem = function (item) {
        if (item instanceof Media) {
            return this.getPermissionForMedia(item);
        }
        else if (item instanceof MediaGroup) {
            return this.getPermissionForMediaGroup(item);
        }
        else if (item instanceof ContainerItem) {
            return this.getPermissionForContainerItem(item);
        }
        else if (item instanceof PlayerInventory) {
            return of(DOMAIN_PERMISSIONS.READ_WRITE);
        }
    };
    PermissionService.prototype.getPermissionForMedia = function (item) {
        var _this = this;
        return this.evalPermissionForItem(item, function (itemDomain, userDomain) {
            var itemDomainIsSameOrLower = _this.itemDomainIsSameOrLower(itemDomain, userDomain);
            var domainsAreInSameLine = _this.domainsAreInSameLine(itemDomain, userDomain);
            if (itemDomainIsSameOrLower) {
                return DOMAIN_PERMISSIONS.READ_WRITE;
            }
            else if (domainsAreInSameLine || !itemDomain) {
                return DOMAIN_PERMISSIONS.READ;
            }
            else {
                return DOMAIN_PERMISSIONS.NONE;
            }
        });
    };
    PermissionService.prototype.getPermissionForMediaGroup = function (item) {
        var _this = this;
        return this.evalPermissionForItem(item, function (itemDomain, userDomain) {
            var domainsAreInSameLine = _this.domainsAreInSameLine(itemDomain, userDomain);
            if (!itemDomain) {
                return DOMAIN_PERMISSIONS.READ;
            }
            else if (domainsAreInSameLine) {
                return DOMAIN_PERMISSIONS.READ_WRITE;
            }
            else {
                return DOMAIN_PERMISSIONS.NONE;
            }
        });
    };
    PermissionService.prototype.getPermissionForContainerItem = function (item) {
        var _this = this;
        return this.evalPermissionForItem(item, function (itemDomain, userDomain) {
            var itemDomainIsSameOrLower = _this.itemDomainIsSameOrLower(itemDomain, userDomain);
            var domainsAreInSameLine = _this.domainsAreInSameLine(itemDomain, userDomain);
            if (itemDomainIsSameOrLower) {
                return DOMAIN_PERMISSIONS.READ_WRITE;
            }
            else if (domainsAreInSameLine || !itemDomain) {
                return DOMAIN_PERMISSIONS.READ;
            }
            else {
                return DOMAIN_PERMISSIONS.NONE;
            }
        });
    };
    PermissionService.prototype.getDomainNamesForItemAndCurrentDomainName = function (item) {
        return combineLatest(this.getDomainNamesForItem(item), this.domainService.getCurrentDomainName());
    };
    PermissionService.prototype.evalPermissionForItem = function (item, evalPermissionFn) {
        return this.getDomainNamesForItemAndCurrentDomainName(item)
            .pipe(map(function (domains) {
            var itemDomains = domains[0];
            var userDomain = domains[1];
            var permissions = [];
            itemDomains.forEach(function (itemDomain) {
                var permission = evalPermissionFn(itemDomain, userDomain);
                permissions.push(permission);
            });
            if (permissions.indexOf(DOMAIN_PERMISSIONS.READ) > -1) {
                return DOMAIN_PERMISSIONS.READ;
            }
            else if (permissions.indexOf(DOMAIN_PERMISSIONS.READ_WRITE) > -1) {
                return DOMAIN_PERMISSIONS.READ_WRITE;
            }
            else {
                return DOMAIN_PERMISSIONS.NONE;
            }
        }));
    };
    // todo for many Ts this is a huge performance issue
    PermissionService.prototype.getPermissionForItems = function (items) {
        var _this = this;
        var collected = [];
        items.forEach(function (item) {
            var permission$ = _this.getPermissionForItem(item);
            var item$ = Observable.from([item]);
            var itemAndPermission$ = combineLatest(item$, permission$);
            collected.push(itemAndPermission$);
        });
        return combineLatest(collected);
    };
    PermissionService.prototype.getDomainNamesForItem = function (item) {
        if (item instanceof Media || item instanceof MediaGroup) {
            return this.api.Domains.getDomainsForItem(item)
                .pipe(map(function (domains) {
                var domainNames = [];
                domains.forEach(function (domain) { return domainNames.push(domain.fullyQualifiedName); });
                return domainNames;
            }));
        }
        else {
            // else: item instance of ContainerItem
            return Observable.from([[item.fullyQualifiedDomainName]]);
        }
    };
    PermissionService.prototype.filterItemsByPermission = function (items, targetPermissions) {
        return this.getPermissionForItems(items)
            .pipe(map(function (itemAndPermissionList) {
            var filteredList = [];
            itemAndPermissionList.forEach(function (itemAndPermission) {
                var item = itemAndPermission[0];
                var perm = itemAndPermission[1];
                if (targetPermissions.indexOf(perm) > -1) {
                    filteredList.push(item);
                }
            });
            return filteredList;
        }));
    };
    PermissionService.prototype.domainsAreInSameLine = function (fullItemDomainName, fullUserDomainName) {
        return fullItemDomainName.indexOf(fullUserDomainName) === 0 || fullUserDomainName.indexOf(fullItemDomainName) === 0;
    };
    PermissionService.prototype.itemDomainIsSameOrLower = function (fullItemDomainName, fullUserDomainName) {
        return fullItemDomainName.indexOf(fullUserDomainName) === 0;
    };
    PermissionService.prototype.getItemDomainName = function (itemDomain) {
        if (itemDomain instanceof ContainerItem) {
            return itemDomain.fullyQualifiedDomainName;
        }
        else {
            return itemDomain.fullyQualifiedName;
        }
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.XdsApiService), i0.inject(i2.DomainService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
