var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { PAGINATOR_PARAMS } from '../const/mat-paginator.const';
import { SelectionManager } from './selection-manager';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { XdsResourceView } from './xds-models/xds-resource-view';
import { PageErrorUtil } from './page-error/page-error-util';
import { PageErrorEmptyListComponent } from '../components/shared/errors/page-errors/page-error-empty-list/page-error-empty-list.component';
import { BroadcastTypes } from '../services/broadcast/broadcast.service';
var XdsResourcePage = /** @class */ (function () {
    function XdsResourcePage(displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translateService, broadcaster, config) {
        this.displaySize = displaySize;
        this.api = api;
        this.route = route;
        this.router = router;
        this.domainService = domainService;
        this.infoService = infoService;
        this.titleBarService = titleBarService;
        this.permissionService = permissionService;
        this.translateService = translateService;
        this.broadcaster = broadcaster;
        this.config = config;
        this.items = [];
        this.paginatorParams = Object.assign({}, PAGINATOR_PARAMS);
        this.selectionManager = new SelectionManager();
        this.loaded = false;
        this.viewError = null;
        this.destroy$ = new Subject();
        this.reloadPage$ = new Subject();
    }
    XdsResourcePage.prototype.ngOnInit = function () {
        var _this = this;
        this.setTitle();
        this.loadItems();
        this.reloadPage();
        // reload content on emit
        this.broadcaster
            .listen(BroadcastTypes.RELOAD)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (message) { return _this.reloadPage(); });
    };
    XdsResourcePage.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.reloadPage$.unsubscribe();
    };
    XdsResourcePage.prototype.reloadPage = function (flushSelection) {
        var _this = this;
        if (flushSelection === void 0) { flushSelection = false; }
        // XDS-1835: removing items with batch requests causes some issues - on reload some items are still in list, but not longer available
        setTimeout(function () {
            if (flushSelection) {
                _this.selectionManager.flush();
            }
            _this.reloadPage$.next(true);
        }, 400);
    };
    XdsResourcePage.prototype.goToPage = function (page, size, extraParams) {
        if (size === void 0) { size = 20; }
        var queryParams = { page: page, size: size };
        return this.router.navigate([], __assign({ queryParams: queryParams }, extraParams, { queryParamsHandling: 'merge' }));
    };
    XdsResourcePage.prototype.setTitle = function () {
        this.titleBarService.setTitle(this.config.title);
    };
    XdsResourcePage.prototype.loadItems = function () {
        var _this = this;
        combineLatest(this.domainService.getCurrentDomain(), this.route.queryParamMap, this.reloadPage$.asObservable(), this.route.params)
            .pipe(takeUntil(this.destroy$), map(function (data) { return _this.createSearchParams(data); }), switchMap(function (searchParams) { return _this.config.mainApi.search(searchParams); }))
            .subscribe(function (pagedItems) {
            var newItems = [];
            // XDS-1835 - selection only for current page
            _this.selectionManager.flush();
            _this.paginatorParams.page = pagedItems.page.number;
            _this.paginatorParams.pages = pagedItems.page.totalPages;
            _this.paginatorParams.items = pagedItems.page.totalElements;
            _this.paginatorParams.pageSize = pagedItems.page.size;
            _this.loaded = true;
            pagedItems.items.forEach(function (item) { return newItems.push(new XdsResourceView(item, _this.permissionService)); });
            _this.items = newItems;
            _this.selectionManager.updateItems(_this.items);
            _this.viewError = null;
            if (_this.items.length === 0) {
                _this.infoService.warn(_this.translateService.instant('ERROR.EMPTYLIST.TITLE'));
                _this.viewError = PageErrorEmptyListComponent;
            }
        }, function (err) {
            _this.loaded = false;
            _this.infoService.error(err, _this.translateService.instant('ERROR.SWW.TITLE'));
            _this.viewError = PageErrorUtil.parse(err);
        });
    };
    return XdsResourcePage;
}());
export { XdsResourcePage };
