<app-responsive-toolbar>
</app-responsive-toolbar>

<app-content>
  <app-page-error-viewer [errorComponent]="viewError"></app-page-error-viewer>

  <app-card-table *ngIf="loaded && players.length !== 0">
    <app-card-table-header>
      <app-card-table-row>
        <app-card-table-cell></app-card-table-cell> <!-- status -->
        <app-card-table-cell>{{ 'COMMON.NAME' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DESCRIPTION' | translate }}</app-card-table-cell>
        <app-card-table-cell>{{ 'COMMON.DOMAIN' | translate }}</app-card-table-cell>
      </app-card-table-row>
    </app-card-table-header>

    <app-card-table-body>
      <app-card-table-row class="pointer" *ngFor="let playerWithStatus of players" (click)="goTo(playerWithStatus)">
        <!--status-->
        <app-card-table-cell
          class="status-cell"
          [matTooltip]="playerWithStatus.status
            ? ('PLAYER.LASTHEARTBEAT' | translate: {time: (playerWithStatus.status.statusHeartbeat().key | translate: {x: playerWithStatus.status.statusHeartbeat().value})})
            : ('PLAYER.NOTCONNECTED' | translate)"
          matTooltipPosition="right"
          *ngIf="!(displaySize.isHandset | async)">
          <div class="status"
               [ngClass]="playerWithStatus.status?.statusState()">
          </div>
        </app-card-table-cell>

        <!--name-->
        <app-card-table-cell class="app-card-table-highlighted">
          <div class="status"
               [ngClass]="playerWithStatus.status?.statusState()"
               *ngIf="(displaySize.isHandset | async)">
          </div>
          <div class="name">
            {{playerWithStatus.player.name}}
          </div>
        </app-card-table-cell>

        <app-card-table-cell>
          <pre class="mat-body-1">{{playerWithStatus.player.description}}</pre>
        </app-card-table-cell>

        <app-card-table-cell>
          <pre class="mat-body-1">{{playerWithStatus.player.parentDomainName}}</pre>
        </app-card-table-cell>
      </app-card-table-row>
    </app-card-table-body>
  </app-card-table>
</app-content>
