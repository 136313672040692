<app-card-table-cell class="checkbox-cell" *ngIf="!(displaySize.isHandset | async)">
  <mat-checkbox [checked]="selectionManager.isSelected(media)"
                (change)="selectionManager.change(media)"
                (click)="$event.preventDefault();"></mat-checkbox>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-thumbnail">
  <img [alt]="media.xdsResource.name"
       appRetryImage [appRetryImageSrc]="media.thumbnailDownload()"
       appImagePreview [data]="media.xdsResource">
</app-card-table-cell>

<app-card-table-cell class="app-card-table-highlighted">
  <div class="name">
    <mat-icon>{{media.xdsResource.iconName}}</mat-icon>
    <mat-icon *ngIf="(displaySize.isHandset | async)"
              [matTooltip]="(((media.isWritable() | async) ? 'COMMON.WRITEABLE_IS_INFO' : 'COMMON.WRITEABLE_IS_NOT_INFO') | translate)">
      {{ media.writableIcon() | async }}
    </mat-icon>
    {{media.xdsResource.name}}
  </div>
</app-card-table-cell>

<app-card-table-cell class="app-card-table-highlighted" *ngIf="!(displaySize.isHandset | async)">
  <mat-icon
    [matTooltip]="(((media.isWritable() | async) ? 'COMMON.WRITEABLE_IS_INFO' : 'COMMON.WRITEABLE_IS_NOT_INFO') | translate)">
    {{ media.writableIcon() | async }}
  </mat-icon>
</app-card-table-cell>

<app-card-table-cell>
  <span *ngFor="let domain of (mediaDomains | async); let isLast = last;">
    {{domain.name}}{{isLast ? '' : ', '}}
  </span>
</app-card-table-cell>

<app-card-table-cell>
  <table>
    <tr>
      <td colspan="2">{{'MEDIA.USEDTIMES' | translate: { 'times': media.xdsResource.usedInContainerItemsCount } }}</td>
    </tr>
    <tr>
      <td>{{'TIMESPAN.FROM' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment( media.xdsResource.validFrom )}}
          <div class="validity-info" *ngIf="dateUtil.isInFuture(media.xdsResource.validFrom)">
            <mat-icon>warning</mat-icon>
            {{'COMMON.VALID_EXPIRED' | translate}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{'TIMESPAN.UNTIL' | translate}}:</td>
      <td>
        <div>
          {{dateUtil.formatMoment( media.xdsResource.validTo )}}
          <mat-icon matTooltip="{{'COMMON.VALID_EXPIRED' | translate}}"
                    *ngIf="dateUtil.isInPast(media.xdsResource.validTo)">warning
          </mat-icon>
        </div>
      </td>
    </tr>
  </table>
</app-card-table-cell>
