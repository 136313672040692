import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-image',
  templateUrl: './icon-image.component.html',
  styleUrls: ['./icon-image.component.sass']
})
export class IconImageComponent implements OnInit {

  @Input('icon')
  public icon: string;

  constructor() {
  }

  public ngOnInit() {
  }

}
