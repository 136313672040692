import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {Domain} from './domain';

export class Player extends Domain {

  constructor(
    name: string,
    description: string,
    fullyQualifiedName: string,
    links: ResourceLinks,
  ) {
    super(name, description, fullyQualifiedName, 0, links);
  }

}
