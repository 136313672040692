import {Component, OnInit} from '@angular/core';
import {XdsResourcePage} from "../../../classes/xds-resource-page";
import {SearchParamsData} from "../../../interfaces/search-params-data";
import {DisplaySizeService} from "../../../services/display-size/display-size.service";
import {XdsApiService} from "../../../services/xds-api/xds-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TitleBarService} from "../../../services/title-bar/title-bar.service";
import {DomainService} from "../../../services/domain/domain.service";
import {InformationService} from "../../../services/information/information.service";
import {PermissionService} from "../../../services/permission/permission.service";
import {BroadcastService} from "../../../services/broadcast/broadcast.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {PAGINATOR_PARAMS} from "../../../const/mat-paginator.const";
import {PlayerInventoriesSearch} from "../../../interfaces/search/player-inventories.search";
import {PlayerInventory} from "../../../classes/xds-models/player-inventory";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {DateUtil} from "../../../classes/date-util";
import {MOMENTJS} from "../../../const/momentjs.const";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.sass']
})
export class PlayerComponent extends XdsResourcePage<PlayerInventory, PlayerInventoriesSearch> implements OnInit {

  public validityFilter: string;
  public playerStatusId: string;

  constructor(displaySize: DisplaySizeService,
              api: XdsApiService,
              router: Router,
              route: ActivatedRoute,
              titleBarService: TitleBarService,
              domainService: DomainService,
              infoService: InformationService,
              permissionService: PermissionService,
              broadcaster: BroadcastService,
              dialogService: DialogService,
              translate: TranslateService,) {

    super(displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
      mainApi: api.PlayerInventories,
      title: translate.instant('COMMON.PLAYERS')
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe(params => {
      this.playerStatusId = params.get('status');
    })
  }

  protected setTitle(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map(params => +params.get('id')),
        switchMap(id => this.api.Players.get(id))
      )
      .subscribe(
        player => {
          this.titleBarService.setTitle(this.config.title + ': ' + player.name);
        }
      );
  }

  protected createSearchParams(data: SearchParamsData): PlayerInventoriesSearch {
    const queryParams = data[1];
    const page = +queryParams.get('page') || PAGINATOR_PARAMS.page;
    const pageSize = +queryParams.get('size') || PAGINATOR_PARAMS.pageSize;
    const statusId = +queryParams.get('status');
    const validity = queryParams.get('validity') ? queryParams.get('validity') : 'V';
    this.validityFilter = validity;

    const validFrom = new Date(); // XDS-2200: use current date as validFrom
    const validTo = new Date();

    return {
      playerStatus: statusId,
      page: page,
      size: pageSize,
      itemValidFrom: DateUtil.createValidDate(validFrom).format(MOMENTJS.validDate),
      itemValidTo: DateUtil.createValidDate(validTo).format(MOMENTJS.validDate),
      validity: validity
      // sort: 'position' // ATTENTION for some reason, adding a 'sort' param breaks the paging
    };
  }

  toggleFilter() {
    this.router.navigate([], {
      relativeTo: this.route, queryParamsHandling: 'merge', queryParams: {
        validity: this.validityFilter !== 'I' ? 'I' : 'V'
      }
    });
  }
}
