import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-message',
  templateUrl: './page-message.component.html',
  styleUrls: ['./page-message.component.sass']
})
export class PageMessageComponent implements OnInit {

  @Input() public icon: string;
  constructor() { }

  public ngOnInit() {
  }

}
