var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsResource } from './xds-resource';
var PlayerInventory = /** @class */ (function (_super) {
    __extends(PlayerInventory, _super);
    function PlayerInventory(_percentDownloaded, _missing, _inventoryItem, links) {
        var _this = _super.call(this, links) || this;
        _this._percentDownloaded = _percentDownloaded;
        _this._missing = _missing;
        _this._inventoryItem = _inventoryItem;
        return _this;
    }
    Object.defineProperty(PlayerInventory.prototype, "percentDownloaded", {
        get: function () {
            return this._percentDownloaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerInventory.prototype, "missing", {
        get: function () {
            return this._missing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerInventory.prototype, "inventoryItem", {
        get: function () {
            return this._inventoryItem;
        },
        enumerable: true,
        configurable: true
    });
    return PlayerInventory;
}(XdsResource));
export { PlayerInventory };
