import * as i0 from "@angular/core";
var RequestModifierService = /** @class */ (function () {
    function RequestModifierService() {
    }
    RequestModifierService.prototype.intercept = function (request, next) {
        var modifiedRequest = request.clone({
            withCredentials: true
        });
        modifiedRequest.headers.set('Content-Type', 'application/json; charset=utf-8');
        return next.handle(modifiedRequest);
    };
    RequestModifierService.ngInjectableDef = i0.defineInjectable({ factory: function RequestModifierService_Factory() { return new RequestModifierService(); }, token: RequestModifierService, providedIn: "root" });
    return RequestModifierService;
}());
export { RequestModifierService };
