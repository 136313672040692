import {PermissionService} from '../../services/permission/permission.service';
import {MediaGroup} from './media-group';
import {ContainerItem} from './container-item';
import {Media} from './media';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {PlayerInventory} from "./player-inventory";

export class XdsResourceView<T extends MediaGroup | ContainerItem | Media | PlayerInventory> {

  private writable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private _xdsResource: T,
    private permissionService: PermissionService
  ) {
    this.permissionService.isWritable(this.xdsResource)
      .subscribe(
        isWritable => this.writable$.next(isWritable)
      );
  }

  public get xdsResource(): T {
    return this._xdsResource;
  }

  public isWritable(): Observable<boolean> {
    return this.writable$.asObservable()
      .pipe(filter(isWritable => {
        return typeof isWritable !== 'undefined' && isWritable !== null;
      }));
  }

  public writableIcon(): Observable<string> {
    return this.isWritable()
      .pipe(map(isWritable => {
        return isWritable ? 'lock_open' : 'lock';
      }));
  }

  public writeable(): boolean {
    return this.writable$.getValue();
  }

  public isMedia(): boolean {
    return this.xdsResource instanceof Media;
  }

  public isMediaGroup(): boolean {
    return this.xdsResource instanceof MediaGroup;
  }

  public isContainerItem(): boolean {
    return this.xdsResource instanceof ContainerItem;
  }

  public thumbnailDownload(): string {
    if (this.isMedia()) {
      return (this.xdsResource as Media).thumbnailDownload().src;
    } else {
      // only media have a thumbnail
      return '';
    }
  }

  public get iconName(): string {
    if (this.isMedia()) {
      const media = this.xdsResource as Media;
      return media.iconName;
    } else if (this.isMediaGroup()) {
      return 'collections';
    } else {
      return '';
    }
  }
}
