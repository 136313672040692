import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
/**
 * This class provides utility functions for {Date} objects.
 */
export class DateService {
  private readonly shortDateFormat = 'yyyy-MM-dd';

  constructor(private datePipe: DatePipe,
  ) {
  }

  /**
   * Returns a 'yyyy-MM-dd representation of a given date.
   * @param {Date} date
   * @returns {string}
   */
  public shortDate(date: Date): string {
    return this.datePipe.transform(date, this.shortDateFormat);
  }
}
