var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { XdsResourcePage } from '../../../classes/xds-resource-page';
import { DisplaySizeService } from '../../../services/display-size/display-size.service';
import { XdsApiService } from '../../../services/xds-api/xds-api.service';
import { TitleBarService } from '../../../services/title-bar/title-bar.service';
import { DomainService } from '../../../services/domain/domain.service';
import { InformationService } from '../../../services/information/information.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { EditContainerItemDialogComponent } from '../../dialogs/edit-media-item-card-dialog/edit-container-item-dialog.component';
import { DeleteContainerItemFromMediaGroupComponent } from '../../dialogs/delete-container-item-from-media-group/delete-container-item-from-media-group.component';
import { PAGINATOR_PARAMS } from '../../../const/mat-paginator.const';
import { BroadcastService } from '../../../services/broadcast/broadcast.service';
var MediaGroupComponent = /** @class */ (function (_super) {
    __extends(MediaGroupComponent, _super);
    function MediaGroupComponent(displaySize, api, router, route, titleBarService, domainService, infoService, permissionService, broadcaster, dialogService, translate) {
        var _this = _super.call(this, displaySize, api, route, router, domainService, infoService, titleBarService, permissionService, translate, broadcaster, {
            mainApi: api.ContainerItems,
            title: translate.instant('COMMON.MEDIAGROUPS')
        }) || this;
        _this.dialogService = dialogService;
        _this.loaded = false;
        _this.isRW = false;
        return _this;
    }
    MediaGroupComponent.prototype.openEditContainerItemDialog = function () {
        var _this = this;
        this.dialogService.openDialog(EditContainerItemDialogComponent, { items: this.selectionManager.getItems() })
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                _this.reloadPage(true);
            }
        });
    };
    MediaGroupComponent.prototype.openDeleteContainerItemDialog = function () {
        var _this = this;
        this.dialogService.openDialog(DeleteContainerItemFromMediaGroupComponent, { items: this.selectionManager.getItems() })
            .subscribe(function (res) {
            if (res && res.dataChanged) {
                _this.reloadPage(true);
            }
        });
    };
    MediaGroupComponent.prototype.toggleSelectionOf = function (ci) {
        if (this.isRW && ci.xdsResource.itemType !== 'group') {
            this.selectionManager.change(ci);
        }
    };
    MediaGroupComponent.prototype.toggleSelectionOfAllVisible = function () {
        var toggleableItems = this.allSelectableItems();
        this.selectionManager.selectUnselectAll(toggleableItems);
    };
    MediaGroupComponent.prototype.allSelectableItems = function () {
        return this.items.filter(function (item) { return item.xdsResource.itemType !== 'group'; });
    };
    // override
    MediaGroupComponent.prototype.setTitle = function () {
        var _this = this;
        this.route.paramMap
            .pipe(takeUntil(this.destroy$), map(function (params) { return +params.get('id'); }), switchMap(function (id) { return _this.api.MediaGroups.get(id); }))
            .subscribe(function (mediaGroup) {
            _this.titleBarService.setTitle(_this.config.title + ': ' + mediaGroup.name);
            _this.isRWMediaGroup$ = _this.permissionService.isWritable(mediaGroup);
            _this.isRWMediaGroup$.subscribe(function (rw) { return _this.isRW = rw; });
        });
    };
    MediaGroupComponent.prototype.createSearchParams = function (data) {
        var currentDomain = data[0];
        var queryParams = data[1];
        var id = data[3].id;
        var page = +queryParams.get('page') || PAGINATOR_PARAMS.page;
        var pageSize = +queryParams.get('size') || PAGINATOR_PARAMS.pageSize;
        var domainId = currentDomain.id;
        return {
            _container: id,
            _domain: domainId,
            page: page,
            size: pageSize,
            sort: 'position'
        };
    };
    return MediaGroupComponent;
}(XdsResourcePage));
export { MediaGroupComponent };
