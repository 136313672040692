import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
var DisplaySizeService = /** @class */ (function () {
    function DisplaySizeService(breakpointObserver) {
        this.breakpointObserver = breakpointObserver;
        // it is possible to use custom breakpoints:
        // private smallDevices = '(max-width: 480px)';
        // private mediumDevices = '(max-width: 960px)';
        // private largeDevices = '(min-width: 961px)';
        // https://material.io/design/layout/responsive-layout-grid.html#breakpoints
        // width: 0px - 600px
        this.smallDevices = [Breakpoints.XSmall];
        // width: 601px - 1023x + 1024px - 1439px
        this.mediumDevices = [Breakpoints.Small, Breakpoints.Medium];
        // width: >1439px
        this.largeDevices = [Breakpoints.Large, Breakpoints.XLarge];
        // private isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        this.isHandset$ = this.breakpointObserver.observe(this.smallDevices)
            .pipe(map(function (result) { return result.matches; }));
        this.isTablet$ = this.breakpointObserver.observe(this.mediumDevices)
            .pipe(map(function (result) { return result.matches; }));
        this.isDesktop$ = this.breakpointObserver.observe(this.largeDevices)
            .pipe(map(function (result) { return result.matches; }));
    }
    Object.defineProperty(DisplaySizeService.prototype, "isHandset", {
        get: function () {
            return this.isHandset$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisplaySizeService.prototype, "isTablet", {
        get: function () {
            return this.isTablet$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisplaySizeService.prototype, "isDesktop", {
        get: function () {
            return this.isDesktop$;
        },
        enumerable: true,
        configurable: true
    });
    DisplaySizeService.ngInjectableDef = i0.defineInjectable({ factory: function DisplaySizeService_Factory() { return new DisplaySizeService(i0.inject(i1.BreakpointObserver)); }, token: DisplaySizeService, providedIn: "root" });
    return DisplaySizeService;
}());
export { DisplaySizeService };
