import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {
  MatAutocompleteModule, MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSpinner,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {NotificationInfoComponent} from './components/notifications/notification-info/notification-info.component';
import {NotificationErrorComponent} from './components/notifications/notification-error/notification-error.component';
import {NotificationWarnComponent} from './components/notifications/notification-warn/notification-warn.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {MediaDeleteDialogComponent} from './components/dialogs/media-delete-dialog/media-delete-dialog.component';
import {RemoveMediaFromMediaGroupsDialogComponent} from './components/dialogs/remove-media-from-media-groups-dialog/remove-media-from-media-groups-dialog.component';
import {AddMediaToMediaGroupsDialogComponent} from './components/dialogs/add-media-to-media-groups-dialog/add-media-to-media-groups-dialog.component';
import {DatePipe} from '@angular/common';
import {UploadMediaItemDialogComponent} from './components/dialogs/upload-media-item-dialog/upload-media-item-dialog.component';
import {EditContainerItemDialogComponent} from './components/dialogs/edit-media-item-card-dialog/edit-container-item-dialog.component';
import {CardTableComponent} from './components/card-table/card-table/card-table.component';
import {CardTableHeaderComponent} from './components/card-table/card-table-header/card-table-header.component';
import {CardTableFooterComponent} from './components/card-table/card-table-footer/card-table-footer.component';
import {CardTableBodyComponent} from './components/card-table/card-table-body/card-table-body.component';
import {CardTableRowComponent} from './components/card-table/card-table-row/card-table-row.component';
import {CardTableCellComponent} from './components/card-table/card-table-cell/card-table-cell.component';
import {CardTableRowMediaitemComponent} from './components/card-table/card-table-row-mediaitem/card-table-row-mediaitem.component';
import {NotificationSuccessComponent} from './components/notifications/notification-success/notification-success.component';
import {DeleteContainerItemFromMediaGroupComponent} from './components/dialogs/delete-container-item-from-media-group/delete-container-item-from-media-group.component';
import {FileUploadModule} from 'ng2-file-upload';
import {ThumbnailDirective} from './directives/thumbnail/thumbnail.directive';
import {NotificationUploadProgressComponent} from './components/notifications/notification-upload-progress/notification-upload-progress.component';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RequestModifierService} from './services/http-interceptors/request-modifier/request-modifier.service';
import {ErrorInterceptorService} from './services/http-interceptors/error-interceptor/error-interceptor.service';
import {PaginatorIntlService} from './services/paginator-intl/paginator-intl.service';
import {LandingComponent} from './components/routes/landing/landing.component';
import {PageNotFoundComponent} from './components/routes/page-not-found/page-not-found.component';
import {BcnComponent} from './components/routes/bcn/bcn.component';
import {MediaGroupListComponent} from './components/routes/media-group-list/media-group-list.component';
import {MediaGroupComponent} from './components/routes/media-group/media-group.component';
import {NavigationComponent} from './components/routes/navigation/navigation.component';
import {HttpLoadingSpinnerComponent} from './components/shared/http-loading-spinner/http-loading-spinner.component';
import {DomainSelectorComponent} from './components/shared/domain-selector/domain-selector.component';
import {PlayerListComponent} from './components/routes/player-list/player-list.component';
import {MediaItemListComponent} from './components/routes/media-item-list/media-item-list.component';
import {TimespanPickerComponent} from './components/shared/timespan-picker/timespan-picker.component';
import {NoPermissionsComponent} from './components/routes/no-permissions/no-permissions.component';
import {PageMessageComponent} from './components/shared/page-message/page-message.component';
import {PageErrorViewerComponent} from './components/shared/errors/page-error-viewer/page-error-viewer.component';
import {PageErrorServerComponent} from './components/shared/errors/page-errors/page-error-server/page-error-server.component';
import {PageErrorOfflineComponent} from './components/shared/errors/page-errors/page-error-offline/page-error-offline.component';
import {PageErrorEmptyListComponent} from './components/shared/errors/page-errors/page-error-empty-list/page-error-empty-list.component';
import {PageErrorSwwComponent} from './components/shared/errors/page-errors/page-error-sww/page-error-sww.component';
import {FooterComponent} from './components/shared/footer/footer.component';
import {ContentComponent} from './components/shared/content/content.component';
import {NotificationUploadCompleteComponent} from './components/notifications/notification-upload-complete/notification-upload-complete.component';
import {IconImageComponent} from './components/shared/icon-image/icon-image.component';
import {CardTableRowCiMediaComponent} from './components/card-table/rows/card-table-row-ci-media/card-table-row-ci-media.component';
import {CardTableRowCiGroupComponent} from './components/card-table/rows/card-table-row-ci-group/card-table-row-ci-group.component';
import {ImagePreviewDialogComponent} from './components/dialogs/preview-dialogs/image-preview-dialog/image-preview-dialog.component';
import {ImagePreviewDirective} from './directives/image-preview/image-preview.directive';
import {VideoPreviewDialogComponent} from './components/dialogs/preview-dialogs/video-preview-dialog/video-preview-dialog.component';
import {ResponsiveToolbarComponent} from './components/shared/responsive-toolbar/responsive-toolbar.component';
import {NotificationUploadCompleteOpenComponent} from './components/notifications/notification-upload-complete-open/notification-upload-complete-open.component';
import {RetryVideoDirective} from './directives/retry-video/retry-video.directive';
import {RetryImageDirective} from './directives/retry-image/retry-image.directive';
import {HelpComponent} from './components/routes/help/help.component';
import {PlayerComponent} from './components/routes/player/player.component';
import { CardTableRowInventoryItemComponent } from './components/card-table/rows/card-table-row-inventory-item/card-table-row-inventory-item.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,
    '/assets/i18n/',
    `.json?${new Date().getTime()}`);  // cache bust
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PageNotFoundComponent,
    BcnComponent,
    MediaGroupListComponent,
    MediaGroupComponent,
    NavigationComponent,
    HttpLoadingSpinnerComponent,
    DomainSelectorComponent,
    NotificationInfoComponent,
    NotificationErrorComponent,
    NotificationSuccessComponent,
    PlayerListComponent,
    MediaItemListComponent,
    MediaDeleteDialogComponent,
    RemoveMediaFromMediaGroupsDialogComponent,
    AddMediaToMediaGroupsDialogComponent,
    UploadMediaItemDialogComponent,
    EditContainerItemDialogComponent,
    CardTableComponent,
    CardTableHeaderComponent,
    CardTableFooterComponent,
    CardTableBodyComponent,
    CardTableRowComponent,
    CardTableCellComponent,
    CardTableRowMediaitemComponent,
    TimespanPickerComponent,
    DeleteContainerItemFromMediaGroupComponent,
    ThumbnailDirective,
    NotificationUploadProgressComponent,
    NotificationWarnComponent,
    NoPermissionsComponent,
    PageMessageComponent,
    PageErrorViewerComponent,
    PageErrorServerComponent,
    PageErrorOfflineComponent,
    PageErrorEmptyListComponent,
    PageErrorSwwComponent,
    FooterComponent,
    ContentComponent,
    NotificationUploadCompleteComponent,
    IconImageComponent,
    CardTableRowCiMediaComponent,
    CardTableRowCiGroupComponent,
    ImagePreviewDialogComponent,
    ImagePreviewDirective,
    VideoPreviewDialogComponent,
    ResponsiveToolbarComponent,
    NotificationUploadCompleteOpenComponent,
    RetryVideoDirective,
    RetryImageDirective,
    HelpComponent,
    PlayerComponent,
    CardTableRowInventoryItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTableModule,
    MatProgressBarModule,
    MatBadgeModule,
    FileUploadModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
    PageErrorEmptyListComponent,
    PageErrorOfflineComponent,
    PageErrorSwwComponent,
    PageErrorServerComponent,
    MatSpinner,
    NotificationErrorComponent,
    NotificationInfoComponent,
    NotificationSuccessComponent,
    NotificationWarnComponent,
    NotificationUploadProgressComponent,
    NotificationUploadCompleteComponent,
    NotificationUploadCompleteOpenComponent,
    MediaDeleteDialogComponent,
    AddMediaToMediaGroupsDialogComponent,
    RemoveMediaFromMediaGroupsDialogComponent,
    UploadMediaItemDialogComponent,
    EditContainerItemDialogComponent,
    DeleteContainerItemFromMediaGroupComponent,
    ImagePreviewDialogComponent,
    VideoPreviewDialogComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: function initializer(keycloak: KeycloakService): () => Promise<any> {
        return (): Promise<any> => {
          return new Promise(async (resolve, reject) => {
            try {
              await keycloak.init({
                config: environment.keycloak,
                initOptions: {
                  checkLoginIframe: false
                }
              });
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        };
      },
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestModifierService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
