/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-preview-dialog.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../directives/retry-video/retry-video.directive";
import * as i5 from "@angular/common/http";
import * as i6 from "keycloak-angular";
import * as i7 from "@angular/common";
import * as i8 from "./video-preview-dialog.component";
import * as i9 from "@angular/material/dialog";
var styles_VideoPreviewDialogComponent = [i0.styles];
var RenderType_VideoPreviewDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoPreviewDialogComponent, data: {} });
export { RenderType_VideoPreviewDialogComponent as RenderType_VideoPreviewDialogComponent };
export function View_VideoPreviewDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { videoElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "close-icon-wrapper pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["video", 1]], null, 2, "video", [["appRetryVideo", ""], ["controls", ""]], [[1, "src", 4], [1, "poster", 4]], null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RetryVideoDirective, [i5.HttpClient, i1.ElementRef, i1.Renderer2, i6.KeycloakService, i7.DOCUMENT], { appRetryVideoSrc: [0, "appRetryVideoSrc"], appRetryPosterSrc: [1, "appRetryPosterSrc"] }, null), i1.ɵpad(7, 2)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_3 = _ck(_v, 7, 0, _co.item.previewDownload()[1], _co.item.previewDownload()[2]); var currVal_4 = _co.item.previewDownload()[0]; _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).srcAttr; var currVal_2 = i1.ɵnov(_v, 6).posterAttr; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_VideoPreviewDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-preview-dialog", [], null, null, null, View_VideoPreviewDialogComponent_0, RenderType_VideoPreviewDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.VideoPreviewDialogComponent, [i9.MAT_DIALOG_DATA, i9.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPreviewDialogComponentNgFactory = i1.ɵccf("app-video-preview-dialog", i8.VideoPreviewDialogComponent, View_VideoPreviewDialogComponent_Host_0, {}, {}, []);
export { VideoPreviewDialogComponentNgFactory as VideoPreviewDialogComponentNgFactory };
