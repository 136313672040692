/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./responsive-toolbar.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "./responsive-toolbar.component";
import * as i7 from "../../../services/display-size/display-size.service";
var styles_ResponsiveToolbarComponent = [i0.styles];
var RenderType_ResponsiveToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResponsiveToolbarComponent, data: {} });
export { RenderType_ResponsiveToolbarComponent as RenderType_ResponsiveToolbarComponent };
export function View_ResponsiveToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-toolbar", [["class", "mat-elevation-z2 mat-toolbar"]], [[2, "is-desktop", null], [2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵncd(0, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform(_co.displaySize.isDesktop)); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_2 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ResponsiveToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-responsive-toolbar", [], null, null, null, View_ResponsiveToolbarComponent_0, RenderType_ResponsiveToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i6.ResponsiveToolbarComponent, [i7.DisplaySizeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResponsiveToolbarComponentNgFactory = i1.ɵccf("app-responsive-toolbar", i6.ResponsiveToolbarComponent, View_ResponsiveToolbarComponent_Host_0, {}, {}, ["*"]);
export { ResponsiveToolbarComponentNgFactory as ResponsiveToolbarComponentNgFactory };
