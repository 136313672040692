import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
var SelectionManager = /** @class */ (function () {
    function SelectionManager() {
        this._selectedItems = [];
        this._selectedItems$ = new BehaviorSubject([]);
    }
    SelectionManager.prototype.change = function (value) {
        if (this.isSelected(value)) {
            this.unselect(value);
        }
        else {
            this.select(value);
        }
        this._selectedItems$.next(this.items);
    };
    Object.defineProperty(SelectionManager.prototype, "count", {
        get: function () {
            return this._selectedItems.length;
        },
        enumerable: true,
        configurable: true
    });
    SelectionManager.prototype.isAllSelected = function (values) {
        var _this = this;
        if (values instanceof Observable) {
            values
                .pipe(take(1))
                .subscribe(function (vs) { return _this.isAllSelected(vs); });
            return;
        }
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            if (!this.isSelected(value)) {
                return false;
            }
        }
        return true;
    };
    SelectionManager.prototype.isAnySelected = function () {
        return this._selectedItems.length > 0;
    };
    SelectionManager.prototype.isNoneSelected = function () {
        return this._selectedItems.length === 0;
    };
    SelectionManager.prototype.isSelected = function (value) {
        return this._selectedItems.indexOf(value) > -1;
    };
    SelectionManager.prototype.getItems = function () {
        return this._selectedItems$.asObservable();
    };
    SelectionManager.prototype.removeAllBut = function (values) {
        var elements = Array.isArray(values) ? values : [values];
        var missing = this._selectedItems.filter(function (item) { return elements.indexOf(item) < 0; });
        this.unselect(missing);
    };
    SelectionManager.prototype.updateItems = function (values) {
        var _this = this;
        var _loop_1 = function (i) {
            var newValue = values.filter(function (item) { return item.xdsResource.id === _this._selectedItems[i].xdsResource.id; })[0];
            if (newValue) {
                this_1._selectedItems[i] = newValue;
            }
        };
        var this_1 = this;
        for (var i = 0; i < this._selectedItems.length; i++) {
            _loop_1(i);
        }
        this._selectedItems$.next(this.items);
    };
    SelectionManager.prototype.flush = function () {
        this.unselect(this.items);
    };
    SelectionManager.prototype.selectUnselectAll = function (values) {
        var _this = this;
        if (values instanceof Observable) {
            values
                .pipe(take(1))
                .subscribe(function (vs) { return _this.selectUnselectAll(vs); });
            return;
        }
        if (this.isAllSelected(values)) {
            this.unselect(values);
        }
        else {
            this.select(values);
        }
    };
    SelectionManager.prototype.select = function (value) {
        this.changeSelection(value, true);
    };
    SelectionManager.prototype.unselect = function (value) {
        this.changeSelection(value, false);
    };
    SelectionManager.prototype.changeSelection = function (value, select) {
        var _this = this;
        if (value instanceof Observable) {
            value
                .pipe(take(1))
                .subscribe(function (vs) { return _this.changeSelection(vs, select); });
            return;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        if (select) {
            this.selectValues(value);
        }
        else {
            this.unselectValues(value);
        }
        this._selectedItems$.next(this.items);
    };
    SelectionManager.prototype.selectValues = function (values) {
        var _this = this;
        values.forEach(function (item) {
            if (_this._selectedItems.indexOf(item) === -1) {
                _this._selectedItems.push(item);
            }
        });
    };
    SelectionManager.prototype.unselectValues = function (values) {
        var _this = this;
        values.map(function (item) { return item.xdsResource.id; })
            .forEach(function (deselectId) {
            var pos = _this._selectedItems.map(function (item) { return item.xdsResource.id; }).indexOf(deselectId);
            if (pos > -1) {
                _this._selectedItems.splice(pos, 1);
            }
        });
    };
    Object.defineProperty(SelectionManager.prototype, "items", {
        get: function () {
            return this._selectedItems;
        },
        enumerable: true,
        configurable: true
    });
    return SelectionManager;
}());
export { SelectionManager };
