var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { XdsResourceApiService } from '../resource/xds-resource-api.service';
import { XdsApiModelMapperService } from '../xds-api-model-mapper.service';
import { XdsApiUtilService } from '../xds-api-util.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../xds-api-model-mapper.service";
import * as i2 from "@angular/common/http";
var XdsContainerItemsApiService = /** @class */ (function (_super) {
    __extends(XdsContainerItemsApiService, _super);
    function XdsContainerItemsApiService(mapper, http) {
        return _super.call(this, http, mapper, XdsContainerItemsApiService.configOptions) || this;
    }
    XdsContainerItemsApiService.prototype.getForItemsAndContainersAndDomain = function (items, containers, domain) {
        var _this = this;
        var itemIds = [];
        var containerIds = [];
        items.forEach(function (item) { return itemIds.push(item.id); });
        containers.forEach(function (container) { return containerIds.push(container.id); });
        var itemIdsStr = itemIds.join(',');
        var containerIdsStr = containerIds.join(',');
        var searchParams = {
            itemIds: itemIdsStr,
            containerIds: containerIdsStr,
            domainId: domain.id
        };
        var endpoint = 'containerItems/search/findByItemIdInAndContainerIdInAndDomainId';
        var queryString = XdsApiUtilService.buildQueryString(searchParams);
        return this.http.request('GET', XdsApiUtilService.createApiUrl(endpoint + queryString)).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapResource(x); }); }));
    };
    XdsContainerItemsApiService.prototype.getDomainsForContainerItem = function (containerItem) {
        var _this = this;
        return this.http.request('GET', containerItem.links.domains.href).pipe(map(function (res) { return _this.mapResourceList(res, function (x) { return _this.mapper.mapDomain(x); }); }));
    };
    XdsContainerItemsApiService.prototype.getItem = function (containerItem) {
        var _this = this;
        return this.http.request('GET', containerItem.links.item.href)
            .pipe(map(function (res) { return _this.mapper.mapContainerItemItem(res); }));
    };
    XdsContainerItemsApiService.prototype.deleteMany = function (containerItems) {
        var payload = {
            type: 'delete',
            containerItems: XdsApiUtilService.resourceListToStrIdList(containerItems)
        };
        return this.batch('delete', payload);
    };
    XdsContainerItemsApiService.prototype.mapResource = function (res) {
        return this.mapper.mapContainerItem(res);
    };
    XdsContainerItemsApiService.configOptions = {
        endpoint: 'containerItems',
        search: {
            endpoint: 'containerItems/search/findByContainerAndItemNameForDomain'
        }
    };
    XdsContainerItemsApiService.ngInjectableDef = i0.defineInjectable({ factory: function XdsContainerItemsApiService_Factory() { return new XdsContainerItemsApiService(i0.inject(i1.XdsApiModelMapperService), i0.inject(i2.HttpClient)); }, token: XdsContainerItemsApiService, providedIn: "root" });
    return XdsContainerItemsApiService;
}(XdsResourceApiService));
export { XdsContainerItemsApiService };
