import {Component, Input} from '@angular/core';
import {CardTableRowItem} from '../card-table-row-item';
import {DisplaySizeService} from '../../../../services/display-size/display-size.service';
import {XdsApiService} from '../../../../services/xds-api/xds-api.service';
import {InformationService} from '../../../../services/information/information.service';
import {TranslateService} from '@ngx-translate/core';
import {XdsResourceView} from '../../../../classes/xds-models/xds-resource-view';
import {ContainerItem} from '../../../../classes/xds-models/container-item';
import {SelectionManager} from '../../../../classes/selection-manager';
import {takeUntil} from 'rxjs/operators';
import {PermissionService} from '../../../../services/permission/permission.service';
import {Media} from '../../../../classes/xds-models/media';

@Component({
  selector: 'app-card-table-row-ci-media',
  templateUrl: './card-table-row-ci-media.component.html',
  styleUrls: ['./card-table-row-ci-media.component.sass']
})
export class CardTableRowCiMediaComponent extends CardTableRowItem {
  @Input()
  public containerItem: XdsResourceView<ContainerItem>;
  @Input()
  public selectionManager: SelectionManager<XdsResourceView<ContainerItem>>;
  @Input()
  public isRWMediaGroup: boolean;

  constructor(
    displaySize: DisplaySizeService,
    api: XdsApiService,
    infoService: InformationService,
    translateService: TranslateService,
    protected permissionService: PermissionService,
  ) {
    super(displaySize, api, infoService, translateService, permissionService);
  }

  protected loadData(): void {
    this.api.ContainerItems.getItem<Media>(this.containerItem.xdsResource)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        item => {
          this.item = new XdsResourceView(item, this.permissionService);
        },
        err => {
          console.log('Unable to load item for ContainerItem: ' + this.containerItem.xdsResource.name);
          this.infoService.error(err, this.translateService.instant('ERROR.LOADINGITEMS'));
        }
      );
  }

}
