<h1 mat-dialog-title>{{'DIALOG.REMOVEFROMGROUP.TITLE' | translate}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1">{{'DIALOG.REMOVEFROMGROUP.HEADER' | translate}}</p>

  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mat-body-2">{{'COMMON.MEDIA' | translate}}</p>
        </mat-panel-title>
        <mat-panel-description>
          <p class="mat-body-1">{{ 'COMMON.SELECTIONINFO' | translate: {
            selected: (containerItemsSelectionManager.getItems() | async).length,
            count: (containerItemsToDelete | async).length
            } }}
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p class="mat-caption" *ngIf="showBlockedInfo">
        <mat-icon #ic class="mat-warn icon-fix" >block</mat-icon>
        {{'COMMON.PERMISSIONINFO' | translate }}
      </p>
      <mat-chip-list #chipList [multiple]="true">
        <mat-chip *ngFor="let ci of (containerItemsToDelete | async)"
                  [selected]="containerItemsSelectionManager.isSelected(ci)"
                  (click)="containerItemsSelectionManager.change(ci)"
                  class="pointer">
          <!--if you have no rw permissions, show error-->
          <mat-icon *ngIf="!(ci.isWritable() | async)"
                    class="mat-warn"
                    [matTooltip]="'Cannot remove because of insufficient permissions'"
                    matTooltipPosition="right">block
          </mat-icon>
          {{ci.xdsResource.name}}
        </mat-chip>
      </mat-chip-list>
      <button mat-button (click)="containerItemsSelectionManager.selectUnselectAll(containerItemsToDelete)">
        {{(containerItemsSelectionManager.isAllSelected(containerItemsToDelete | async) ?
        'COMMON.DESELECTALL' : 'COMMON.SELECTALL') | translate }}
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="ConfirmClick()"
          [disabled]="!isAlldeletable()">
    {{'COMMON.OK' | translate}}
  </button>
  <button mat-button (click)="AbortClick()" cdkFocusInitial>
    {{'COMMON.CANCEL' | translate}}
  </button>
</div>
