import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
import * as i2 from "@angular/platform-browser";
var TitleBarService = /** @class */ (function () {
    function TitleBarService(breakpointObserver, titleService) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.titleService = titleService;
        this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
            .pipe(map(function (result) { return result.matches; }));
        this.title$ = new BehaviorSubject('');
        this.titleIsVisible$ = new BehaviorSubject(true);
        this.defaultTitle = 'BEECEEN - Digital signage made easy';
        this.setTitle(this.defaultTitle);
        this.isHandset$.subscribe(function (x) { return _this.isHandset = x; });
    }
    Object.defineProperty(TitleBarService.prototype, "Title", {
        /**
         * Gets the current title.
         * @returns {Observable<string>}
         * @constructor
         */
        get: function () {
            return this.title$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sets the current title. If no input is provided only the default title is shown.
     * @param {string} title
     */
    TitleBarService.prototype.setTitle = function (title) {
        var t;
        if (title) {
            t = this.defaultTitle + ' - ' + title;
            this.title$.next(title);
            this.titleService.setTitle(t);
        }
        else {
            t = this.defaultTitle;
            this.title$.next(t);
            this.titleService.setTitle(t);
        }
    };
    Object.defineProperty(TitleBarService.prototype, "TitleVisibility", {
        /**
         * Allows tracking of the current title visiblity, which may be hidden on handset devices.
         * Use hideTitle() or restoreTitle() to change title visiblity.
         * @returns {BehaviorSubject<boolean>}
         * @constructor
         */
        get: function () {
            return this.titleIsVisible$;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Hides the title for handset size devices.
     */
    TitleBarService.prototype.hideTitle = function () {
        if (this.isHandset) {
            this.titleIsVisible$.next(false);
        }
    };
    /**
     * Makes the title visible for handset size devices.
     */
    TitleBarService.prototype.restoreTitle = function () {
        if (this.isHandset) {
            this.titleIsVisible$.next(true);
        }
    };
    TitleBarService.ngInjectableDef = i0.defineInjectable({ factory: function TitleBarService_Factory() { return new TitleBarService(i0.inject(i1.BreakpointObserver), i0.inject(i2.Title)); }, token: TitleBarService, providedIn: "root" });
    return TitleBarService;
}());
export { TitleBarService };
