import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { XdsApiUtilService } from '../xds-api/xds-api-util.service';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "keycloak-angular";
import * as i2 from "@angular/common/http";
// keycloak wrapper to provide a easy access for some values
var AuthService = /** @class */ (function () {
    function AuthService(keycloak, http) {
        var _this = this;
        this.keycloak = keycloak;
        this.http = http;
        this.network$ = new BehaviorSubject(null);
        this.domainId$ = new BehaviorSubject(null);
        this.locale$ = new BehaviorSubject(null);
        combineLatest(this.downloadCurrentUser(), this.keycloak.getToken()).subscribe(function (result) {
            var currentUser = result[0];
            var parsedToken = _this.keycloak.getKeycloakInstance().tokenParsed;
            var accessAllowedList = _this.getAccessAllowedList(parsedToken);
            var domainId = _this.findDomainId(accessAllowedList, currentUser.networkKey);
            var currentNetwork = _this.findCurrentNetwork(currentUser.user.networks, currentUser.networkKey);
            _this.network$.next(currentNetwork);
            _this.domainId$.next(domainId);
            _this.locale$.next(parsedToken.locale);
            moment.locale(parsedToken.locale);
        }, function (err) {
            _this.network$.next({
                key: '',
                domainRestrictions: false,
                nonBeeceenPlayers: false,
                limitedContentView: false,
                color: '',
                icon: '',
                name: '',
            });
            _this.domainId$.next(-1);
            console.error(err);
        });
    }
    Object.defineProperty(AuthService.prototype, "language", {
        get: function () {
            return this.locale$
                .pipe(filter(function (val) { return typeof val !== 'undefined' && val !== null; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "network", {
        get: function () {
            return this.network$.asObservable()
                .pipe(filter(function (val) { return typeof val !== 'undefined' && val !== null; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "networkKey", {
        get: function () {
            return this.network$.asObservable()
                .pipe(filter(function (val) { return typeof val !== 'undefined' && val !== null; }))
                .pipe(map(function (network) { return network.key; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "domainId", {
        get: function () {
            return this.domainId$.asObservable()
                .pipe(map(function (val) {
                return val;
            }))
                .pipe(filter(function (val) { return typeof val !== 'undefined' && val !== null; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "currentNetworkKey", {
        get: function () {
            var currentNetwork = this.network$.getValue();
            if (typeof currentNetwork !== 'undefined' && currentNetwork !== null) {
                return currentNetwork.key;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.checkGeneralDomainPermissions = function () {
        return combineLatest(this.network, this.domainId)
            .pipe(map(function (val) {
            var network = val[0];
            var domainId = val[1];
            return domainId >= 0 && network.domainRestrictions;
        }));
    };
    AuthService.prototype.createAccountUrl = function () {
        return this.keycloak.getKeycloakInstance().createAccountUrl();
    };
    // cannot move to xds-api, caused by circular dependency (auth > api > ... > mapper > auth)
    AuthService.prototype.logout = function () {
        var _this = this;
        this.http.request('POST', XdsApiUtilService.createApiUrl('userLogout'), {}).subscribe(function () {
            document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            _this.keycloak.logout();
            // this.keycloak.logout('http://localhost:81/bcn/landing');
        }, function () {
            document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            _this.keycloak.logout();
            // this.keycloak.logout('http://localhost:81/bcn/landing');
        });
    };
    // cannot move to xds-api, caused by circular dependency (auth > api > ... > mapper > auth)
    AuthService.prototype.downloadCurrentUser = function () {
        return this.http.request('GET', XdsApiUtilService.createApiUrl('currentUser'));
    };
    AuthService.prototype.splitAllowedAccess = function (rawAcces) {
        // /{network}/{domaintree as ids, separated by /}
        var access = rawAcces.split('/');
        var network = access[1]; // access[0] is empty cause of leading '/'
        var domainId = -1;
        if (access.length > 2) {
            domainId = +(access[access.length - 1]);
        }
        return {
            network: network,
            domainId: domainId
        };
    };
    AuthService.prototype.findDomainId = function (accessAllowedList, currentNetwork) {
        for (var i = 0; i < accessAllowedList.length; i++) {
            var access = this.splitAllowedAccess(accessAllowedList[i]);
            if (currentNetwork === access.network) {
                return access.domainId;
            }
        }
        return -1;
    };
    AuthService.prototype.findCurrentNetwork = function (networkList, networkKey) {
        for (var i = 0; i < networkList.length; i++) {
            if (networkList[i].key === networkKey) {
                return networkList[i];
            }
        }
        return {
            key: '',
            domainRestrictions: false,
            nonBeeceenPlayers: false,
            limitedContentView: false,
            color: '',
            icon: '',
            name: '',
        };
    };
    AuthService.prototype.getAccessAllowedList = function (token) {
        if (token.XdsTree && token.XdsTree.accessAllowedList) {
            return token.XdsTree.accessAllowedList;
        }
        else {
            return [];
        }
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.KeycloakService), i0.inject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
