var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XdsNamedResource } from './xds-named-resource';
import { MOMENTJS } from '../../const/momentjs.const';
import { DateUtil } from '../date-util';
var ContainerItem = /** @class */ (function (_super) {
    __extends(ContainerItem, _super);
    function ContainerItem(name, validFrom, validTo, _fullyQualifiedDomainName, _domainName, _containerName, _itemType, links) {
        var _this = _super.call(this, name, links) || this;
        _this._fullyQualifiedDomainName = _fullyQualifiedDomainName;
        _this._domainName = _domainName;
        _this._containerName = _containerName;
        _this._itemType = _itemType;
        _this._isWritable = false;
        _this._validFrom = validFrom ? DateUtil.createValidDate(validFrom) : null;
        _this._validTo = validTo ? DateUtil.createValidDate(validTo) : null;
        return _this;
    }
    Object.defineProperty(ContainerItem.prototype, "validFrom", {
        get: function () {
            return this._validFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContainerItem.prototype, "validTo", {
        get: function () {
            return this._validTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContainerItem.prototype, "fullyQualifiedDomainName", {
        get: function () {
            return this._fullyQualifiedDomainName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContainerItem.prototype, "domainName", {
        get: function () {
            return this._domainName;
        },
        enumerable: true,
        configurable: true
    });
    ContainerItem.prototype.updateValidDates = function (validFrom, validTo) {
        return new ContainerItem(this.name, validFrom, validTo, this.fullyQualifiedDomainName, this.domainName, this.containerName, this.itemType, this.links);
    };
    ContainerItem.prototype.getPatchObj = function () {
        return {
            id: this.id,
            validFrom: this.validFrom ? this.validFrom.format(MOMENTJS.validDate) : null,
            validTo: this.validTo ? this.validTo.format(MOMENTJS.validDate) : null,
        };
    };
    Object.defineProperty(ContainerItem.prototype, "containerName", {
        get: function () {
            return this._containerName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContainerItem.prototype, "itemType", {
        get: function () {
            return this._itemType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContainerItem.prototype, "isWritable", {
        get: function () {
            return this._isWritable;
        },
        set: function (value) {
            this._isWritable = value;
        },
        enumerable: true,
        configurable: true
    });
    return ContainerItem;
}(XdsNamedResource));
export { ContainerItem };
