/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-message.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./page-message.component";
var styles_PageMessageComponent = [i0.styles];
var RenderType_PageMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageMessageComponent, data: {} });
export { RenderType_PageMessageComponent as RenderType_PageMessageComponent };
export function View_PageMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.icon; _ck(_v, 2, 0, currVal_1); }); }
export function View_PageMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-message", [], null, null, null, View_PageMessageComponent_0, RenderType_PageMessageComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageMessageComponentNgFactory = i1.ɵccf("app-page-message", i4.PageMessageComponent, View_PageMessageComponent_Host_0, { icon: "icon" }, {}, ["*"]);
export { PageMessageComponentNgFactory as PageMessageComponentNgFactory };
