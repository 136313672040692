import {Component, OnInit, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-bcn',
  templateUrl: './bcn.component.html',
  styleUrls: ['./bcn.component.sass'],
})
export class BcnComponent implements OnInit {

  public latestNetworkKey = '';

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public ngOnInit() {
    this.auth.networkKey.subscribe(
      networkName => this.setNetworkNameAsClassOnBody(networkName)
    );
  }

  private setNetworkNameAsClassOnBody(newName: string): void {
    if (this.latestNetworkKey) {
      this.document.body.classList.remove(this.latestNetworkKey);
    }

    this.latestNetworkKey = newName;
    this.document.body.classList.add(newName);
  }

}
