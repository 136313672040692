import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {NotificationErrorComponent} from '../../components/notifications/notification-error/notification-error.component';
import {NotificationInfoComponent} from '../../components/notifications/notification-info/notification-info.component';
import {NotificationSuccessComponent} from '../../components/notifications/notification-success/notification-success.component';
import {NotificationWarnComponent} from '../../components/notifications/notification-warn/notification-warn.component';
import {NotificationUploadCompleteComponent} from '../../components/notifications/notification-upload-complete/notification-upload-complete.component';
import {NotificationUploadCompleteOpenComponent} from '../../components/notifications/notification-upload-complete-open/notification-upload-complete-open.component';


export enum InformationType {
  SUCCESS,
  INFO,
  WARN,
  ERROR,
  UPLOAD_COMPLETE,
  UPLOAD_COMPLETE_CHANGE_PAGE,
}

export interface Information {
  type: InformationType;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * This class is used to show MatSnackBar messages to the user and simultaneously log them to the browser console.
 * Only a single instance of MatSnackbar can be used at once, therefore mangagement and queue capability is added.
 */
export class InformationService {

  private _information: Information[] = [];
  private snackBarRef: any;
  private locked = false;

  constructor(public snackBar: MatSnackBar) {
  }

  /**
   * Displays and logs an error message. User confirmation is enforced.
   * @param {Error} error An error or null, which will be only loged to the console.
   * @param args
   */
  public error(error: Error | null, ...args: any[]): void {
    /*write full error messages only to console*/
    const message = args.join(' ');
    console.error(error, message);
    this.push({type: InformationType.ERROR, message: message});
  }

  /**
   * Displays and logs a warning message. Disappers after a cosntant duration without user confiramtion.
   * @param args
   */
  public warn(...args: any[]): void {
    const message = args.join(' ');
    console.warn(message);
    this.push({type: InformationType.WARN, message: message});
  }

  /**
   * Displays and logs an info message. Disappers after a cosntant duration without user confiramtion.
   * @param args
   */
  public info(...args: any[]): void {
    const message = args.join(' ');
    console.log(message);
    this.push({type: InformationType.INFO, message: message});
  }

  /**
   * Displays and logs a success message. Disappers after a cosntant duration without user confiramtion.
   * @param args
   */
  public success(...args: any[]): void {
    const message = args.join(' ');
    console.log(message);
    this.push({type: InformationType.SUCCESS, message: message});
  }

  /**
   * Displays an upload completed message and enforces the user to reload the page.
   * @param args
   */
  public reloadPage(...args: any[]): void {
    const message = args.join(' ');
    this.push({type: InformationType.UPLOAD_COMPLETE, message: message});
  }

  public goToMediaAfterUpload(...args: any[]): void {
    const message = args.join(' ');
    this.push({type: InformationType.UPLOAD_COMPLETE_CHANGE_PAGE, message: message});
  }

  /**
   * Pauses processing of queued messages.
   */
  public lock() {
    this.locked = true;
  }

  /**
   * Resumses processing of queued messages.
   */
  public unlock(): void {
    this.locked = false;
    this.emit();
  }

  /**
   * Checks for identical messages in cache, so that only unique messages get displayed.
   * @param {Information} info
   * @returns {boolean}
   */
  private isInCache(info: Information): boolean {
    return this._information.find(
      (i) => i.type === info.type && i.message === info.message) !== undefined;
  }

  /**
   * Emits a message or queues it, when a message is already shown.
   * @param {Information} info
   */
  private push(info: Information) {
    /*avoid multiple identical messages*/
    if (this.isInCache(info)) {
      return;
    }

    this._information.push(info);
    if (this._information.length === 1) {
      this.emit();
    }
  }

  /**
   * Removes the current message and shows the next queued.
   * This behaviour can be controlled via lock() and unlock().
   */
  private dismiss(): void {
    this._information.shift();
    if (!this.locked) {
      this.emit();
    }
  }

  /**
   * Shows the first message in the queue.
   */
  private emit(): void {
    if (this._information.length > 0) {
      const info = this._information[0];

      if (info) {
        this.openSnackBar(info);
      }
    }
  }

  /**
   * Opens the MatSnackBar for the provided information.
   * The actual MatSnackBar behaviour depends on the InformationType.
   * @param {Information} info The information to display.
   */
  private openSnackBar(info: Information) {
    let component;
    let duration;

    switch (info.type) {
      case InformationType.UPLOAD_COMPLETE: {
        component = NotificationUploadCompleteComponent;
        break;
      }
      case InformationType.UPLOAD_COMPLETE_CHANGE_PAGE: {
        component = NotificationUploadCompleteOpenComponent;
        break;
      }
      case InformationType.ERROR: {
        component = NotificationErrorComponent;
        break;
      }
      case InformationType.SUCCESS: {
        component = NotificationSuccessComponent;
        duration = 3000;
        break;
      }
      case InformationType.WARN: {
        component = NotificationWarnComponent;
        duration = 5000;
        break;
      }
      case InformationType.INFO:
      default: {
        component = NotificationInfoComponent;
        duration = 3000;
        break;
      }
    }

    this.snackBarRef = this.snackBar.openFromComponent(component, {
      data: info.message,
      duration: duration
    });
    this.snackBarRef.instance.component = this.snackBarRef;
    const dismissedSubscription = this.snackBarRef.afterDismissed().subscribe(
      dismissed => this.dismiss(),
      null,
      () => dismissedSubscription.unsubscribe()
    );
  }
}
