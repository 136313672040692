import {XdsResource} from './xds-resource';
import {ResourceLinks} from '../../interfaces/xds-models/resource-links';

export class XdsNamedResource extends XdsResource {
  constructor(
    private _name: string,
    links: ResourceLinks,
  ) {
    super(links);
  }

  public get name(): string {
    return this._name;
  }
}
