import {ResourceLinks} from '../../interfaces/xds-models/resource-links';
import {XdsApiUtilService} from '../../services/xds-api/xds-api-util.service';

export class XdsResource {
  private readonly _id: number | undefined;

  public static new<N extends XdsResource>(): N {
    throw new Error('Not implemented yet!');
  }

  constructor(
    private _links: ResourceLinks
  ) {
    this._id = XdsApiUtilService.getIdFromSelfLink(this._links.self.href);
  }

  public get id(): number | undefined {
    return this._id;
  }

  public get links(): ResourceLinks {
    return this._links;
  }
}
