import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Media} from '../../../classes/xds-models/media';
import {XdsApiService} from '../../../services/xds-api/xds-api.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {DomainService} from '../../../services/domain/domain.service';
import {MediaGroup} from '../../../classes/xds-models/media-group';
import {SelectionManager} from '../../../classes/selection-manager';
import {PermissionService} from '../../../services/permission/permission.service';
import {InformationService} from '../../../services/information/information.service';
import {ContainerItem} from '../../../classes/xds-models/container-item';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Container} from '../../../classes/xds-models/container';
import {Domain} from '../../../classes/xds-models/domain';
import {XdsResourceView} from '../../../classes/xds-models/xds-resource-view';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-remove-media-from-media-groups-dialog',
  templateUrl: './remove-media-from-media-groups-dialog.component.html',
  styleUrls: ['./remove-media-from-media-groups-dialog.component.sass']
})
export class RemoveMediaFromMediaGroupsDialogComponent implements OnInit, OnDestroy {
  public mediasToRemove: Observable<XdsResourceView<Media>[]>;
  public mediaGroups:  XdsResourceView<MediaGroup>[] = [];
  public containerItemsToRemove: XdsResourceView<ContainerItem>[] = [];

  public mediaGroupSelectionManager = new SelectionManager<XdsResourceView<MediaGroup>>();
  public mediaItemSelectionManager = new SelectionManager<XdsResourceView<Media>>();
  public containerItemSelectionManager = new SelectionManager<XdsResourceView<ContainerItem>>();

  private nonRWContainerItemCount = -1;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private api: XdsApiService,
    private domainService: DomainService,
    private permissionService: PermissionService,
    private infoService: InformationService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<RemoveMediaFromMediaGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mediasToRemove = data.items;
  }

  public ngOnInit() {
    // ToDo: use normal ngOnInit. This is fix is used to avoid flickering while dialog is opening. See permissions.service.ts (L:64)
    setTimeout(this.delayedNgOnInit.bind(this), 200);
  }

  public delayedNgOnInit() {
    this.mediaItemSelectionManager.select(this.mediasToRemove);

    /*this.mediaGroupSelectionManager.select(this.permissionService.getRWMediaGroups());*/
    this.permissionService.getRWMediaGroups()
      .pipe(
        takeUntil(this.destroy$),
        map(o => {
          return o.map( item => new XdsResourceView(item, this.permissionService));
        })
      )
      .subscribe(rwMediaGroups => {
        this.mediaGroups = rwMediaGroups;
        this.mediaGroupSelectionManager.select(this.mediaGroups);
      },
      err => {
        this.infoService.error(err, this.translateService.instant('ERROR.LOADINGITEMS'));
      });


    combineLatest(
      this.mediaItemSelectionManager.getItems(),
      this.mediaGroupSelectionManager.getItems(),
      this.domainService.getCurrentDomain()
    ).pipe(
      takeUntil(this.destroy$),
      map(data => {
        return {
          selectedItems: data[0].map(o => o.xdsResource) as Media[],
          selectedMediaGroups: data[1].map(o => o.xdsResource) as MediaGroup[],
          domain: data[2] as Domain
        };
      })
    ).subscribe(data => {
      this.fetchContainerItems(data.selectedItems, data.selectedMediaGroups, data.domain);
    });

    this.containerItemSelectionManager.getItems()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        selectedContainerItems => this.nonRWContainerItemCount = selectedContainerItems.filter(cI => !cI.writeable).length
      );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public AbortClick(): void {
    this.dialogRef.close({dataChanged: false});
  }

  public ConfirmClick(): void {
    this.containerItemSelectionManager.getItems()
      .pipe(
        take(1)
      )
      .subscribe(selectedContainerItems => {

        this.api.ContainerItems.deleteMany(selectedContainerItems.map(o => o.xdsResource))
          .subscribe(
            () => {
              this.infoService.info(this.translateService.instant('DIALOG.REMOVEFROMGROUPS.SUCCESS'));
            },
            err => {
              this.infoService.error(err, this.translateService.instant('ERROR.REMOVINGITEMS'));
            }
          );

        this.dialogRef.close({dataChanged: true});
      });
  }


  public getContainerItemsForGroup(container: Container): XdsResourceView<ContainerItem>[] {
    return this.containerItemsToRemove.filter(cI => cI.xdsResource.containerName === container.name);
  }

  public selectionHasOnlyRWContainers(): boolean {
    return this.nonRWContainerItemCount === 0;
  }

  private fetchContainerItems(selectedItems: Media[], selectedMediaGroups: Container[], domain: Domain): void {
    if (!selectedItems || !selectedMediaGroups || !domain) {
      // if no media items,  media groups and/or domain are selected, there can't possibly be
      // any container items for the user to select
      this.containerItemsToRemove = [];
      this.containerItemSelectionManager.flush();
      return;
    }

    this.api.ContainerItems.getForItemsAndContainersAndDomain(selectedItems, selectedMediaGroups, domain)
      .pipe( map(o => {
        return o.map( item => new XdsResourceView(item, this.permissionService));
      }))
      .subscribe(containerItems => {
        // refresh the container items stored in their selection manager
        this.containerItemSelectionManager.updateItems(containerItems);

        // as either the selection of media items or groups has changed, we have to assume that the
        // resulting list of container items is smaller than before. Therefore, we might have stored
        // some container items that cannot possibly be selected at this time and we need to get rid of them
        this.containerItemSelectionManager.removeAllBut(containerItems);

        this.containerItemsToRemove = containerItems;
        // this.containerItemSelectionManager.select(containerItems);
        this.containerItemSelectionManager.select(this.containerItemsToRemove);
        });
  }
}
